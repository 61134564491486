import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import { EmissionPrimaireService } from "../../../../../core/services/emission.primaire.service";
import { ToastService } from "../../../../../core/services/toast.service";
import { TitresDTO } from "../../../../../core/models/titres.model";
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {CommonModule} from "@angular/common";
import {DropdownModule} from "primeng/dropdown";
import {CalendarModule} from "primeng/calendar";
import {Ripple} from "primeng/ripple";
import {ChipsModule} from "primeng/chips";
import {finalize} from "rxjs";

@Component({
  selector: 'app-add-titre',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, DropdownModule, CalendarModule, Ripple, ChipsModule],
  templateUrl: './add-titre.component.html',
  styleUrl: './add-titre.component.css'

})
export class AddTitreComponent implements OnInit {
  titreForm!: FormGroup;
  emissionId!: string;
  isLoading: boolean = false;

  typeInstrumentOptions = [
    { code: 'OAT', name: 'OAT' },
    { code: 'BAT', name: 'BAT' }
  ];

  constructor(
    private fb: FormBuilder,
    private emissionService: EmissionPrimaireService,
    private toastService: ToastService,
    private config: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef

  ) {}

  ngOnInit() {
    this.titreForm = this.fb.group({
      typeInstrument: ['', Validators.required],
      dueDate: ['', Validators.required],
      rate: [null, [Validators.required, Validators.min(0)]],
      unitNominalValue: [null, [Validators.required, Validators.min(0)]],
      isin: ['']
    });
    this.emissionId = this.config.data.emissionId;
  }

  addTitre() {
    if (this.titreForm.valid) {
      const titreDto: TitresDTO = this.titreForm.value;
      this.isLoading = true;
      this.emissionService.addTitreToEmission(this.emissionId, titreDto)
        .pipe(finalize(() => this.isLoading = false))
        .subscribe({
        next: (response) => {
          this.toastService.showToast('Succès', 'Le titre a été ajouté à l\'émission avec succès.','success',response);
          this.dialogRef.close();
        },
        error: (error) => {
          this.toastService.showToast('Erreur', 'Impossible d\'ajouter le titre à l\'émission.', 'error', error);
        }
      });
    }
  }
}
