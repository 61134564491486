// balance-sheet-item.service.ts
import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import { getDefaultPageable, getPageableAsParams, Page, Pageable } from '../models/page.model';
import {
  IBalanceSheetItem,
  IItemMaturity,
  IItemStatistics,
  ItemSearchCriteria,
  IUploadBalanceSheetItem
} from '../models/balance-sheet-item.model';

@Injectable({
  providedIn: 'root'
})
export class BalanceSheetItemService {
  private readonly apiUrl = `risque/v1/balance-sheet-items`;

  constructor(private readonly http: HttpClient) {}

  createItems(items: IBalanceSheetItem[]): Observable<IBalanceSheetItem[]> {
    return this.http.post<IBalanceSheetItem[]>(`${this.apiUrl}/batch`, items);
  }

  parseExcelFile(file: File): Observable<IUploadBalanceSheetItem[]> {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<IUploadBalanceSheetItem[]>(`${this.apiUrl}/upload/parse`, formData);
  }

  uploadItems(items: IUploadBalanceSheetItem[]): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/upload/save`, items);
  }

  getActiveItems(date?: string, institutionId?: string): Observable<IBalanceSheetItem[]> {
    let params = new HttpParams();
    if (date) params = params.append('date', date);
    if (institutionId) params = params.append('institutionId', institutionId);
    return this.http.get<IBalanceSheetItem[]>(`${this.apiUrl}/active`, { params });
  }

  searchItems(criteria: ItemSearchCriteria, pageable: Pageable = getDefaultPageable()): Observable<Page<IBalanceSheetItem>> {
    let params = getPageableAsParams(pageable);

    if(criteria.query) params = params.append('query', criteria.query);
    if (criteria.startDate) params = params.append('startDate', criteria.startDate);
    if (criteria.endDate) params = params.append('endDate', criteria.endDate);
    if (criteria.currency) params = params.append('currency', criteria.currency);
    if (criteria.uploaded !== undefined) params = params.append('uploaded', criteria.uploaded.toString());
    if (criteria.categoryId) params = params.append('categoryId', criteria.categoryId);
    if (criteria.itemType) params = params.append('itemType', criteria.itemType);
    if (criteria.balanceSheetType) params = params.append('balanceSheetType', criteria.balanceSheetType);
    if (criteria.minAmount) params = params.append('minAmount', criteria.minAmount.toString());
    if (criteria.maxAmount) params = params.append('maxAmount', criteria.maxAmount.toString());

    return this.http.get<Page<IBalanceSheetItem>>(`${this.apiUrl}/search`, { params });
  }

  getUploadedItems(
    pageable: Pageable = getDefaultPageable(),
    startDate?: string,
    endDate?: string,
    currency: string = 'XOF',
  ): Observable<Page<IBalanceSheetItem>> {
    const params = getPageableAsParams(pageable)
      .append('currency', currency);

    if (startDate) params.append('startDate', startDate);
    if (endDate) params.append('endDate', endDate);

    return this.http.get<Page<IBalanceSheetItem>>(`${this.apiUrl}/uploaded`, { params });
  }

  getBalancesByType(date: string, currency: string = 'XOF'): Observable<Record<string, number>> {
    const params = new HttpParams()
      .append('date', date)
      .append('currency', currency);
    return this.http.get<Record<string, number>>(`${this.apiUrl}/balances`, { params });
  }

  getItemStatistics(
    startDate: string,
    endDate: string,
    currency: string = 'XOF'
  ): Observable<IItemStatistics[]> {
    const params = new HttpParams()
      .append('startDate', startDate)
      .append('endDate', endDate)
      .append('currency', currency);
    return this.http.get<IItemStatistics[]>(`${this.apiUrl}/statistics`, { params });
  }

  getItemMaturities(date: string, currency: string = 'XOF'): Observable<IItemMaturity[]> {
    const params = new HttpParams()
      .append('date', date)
      .append('currency', currency);
    return this.http.get<IItemMaturity[]>(`${this.apiUrl}/maturities`, { params });
  }

  deleteItem(id: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${id}`);
  }
}
