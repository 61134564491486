import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ChartModule} from 'primeng/chart';
import {FormsModule} from '@angular/forms';
import {Frequency, ICategoryBalance, IDailyBalanceReport} from '../../../core/models/daily-balance.model';

@Component({
  selector: 'report-balance-chart',
  standalone: true,
  imports: [
    ChartModule,
    FormsModule,
  ],
  template: `
    <div class="p-4 border-1 border-round border-black-alpha-10 flex flex-column gap-4 w-full">
      <p-chart type="bar" [data]="chartData" [options]="chartOptions" height="400px" />
    </div>
  `
})
export class ReportBalanceChartComponent implements OnChanges {

  @Input() reports: IDailyBalanceReport[] = [];
  @Input() assets: ICategoryBalance[] = [];
  @Input() liabilities: ICategoryBalance[] = [];
  @Input() frequency: Frequency = Frequency.DAILY;
  @Input() showGap: boolean = true;
  @Input() legendPos: 'top' | 'right' = 'right';
  @Input() barThickness: number = 10;
  @Input() currency: string = 'XOF';

  chartData: any;
  chartOptions: any;

  constructor() {
    this.initChartOptions();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['reports'] || changes['assets'] || changes['liabilities'] || changes['showGap'] || changes['barThickness']) {
      this.updateChartData();
    }
    if (changes['legendPos'] || changes['currency']) this.initChartOptions();
  }

  private initChartOptions() {
    this.chartOptions = {
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: this.legendPos,
          align: 'center',
          labels: {
            usePointStyle: true,
            padding: 20,
            font: {
              size: 11
            }
          }
        },
        tooltip: {
          mode: 'nearest',
          intersect: true,
          callbacks: {
            label: (context: any) => {
              const label = context.dataset.label || '';
              const value = Math.abs(context.raw) || 0;
              return `${label}: ${this.formatCurrency(value)}`;
            }
          }
        }
      },
      scales: {
        x: {
          stacked: true
        },
        y: {
          stacked: true,
          title: {
            display: true,
            text: this.currency == 'XOF' ? 'Francs CFA - XOF' : 'Euros - EUR'
          },
          ticks: {
            callback: (value: number) => {
              return new Intl.NumberFormat('fr-FR', {
                style: 'decimal',
                notation: 'compact'
              }).format(value);
            }
          }
        }
      },
      interaction: {
        mode: 'nearest',
        intersect: true
      },
      elements: {
        bar: {
          borderSkipped: false
        }
      }
    };
  }

  private updateChartData() {
    const dates = this.reports.map(report => new Date(report.reportDate).toLocaleDateString('fr-FR'));

    const assetColors = this.generateDistinctColors(
      this.assets.length,
      '#176a00',
      '#a1ff93'
    );

    const liabilityColors = this.generateDistinctColors(
      this.liabilities.length,
      '#840000',
      '#f36262'
    );

    const assetDatasets = this.assets.map((asset, index) => {
      // Determine if this is the last asset (will be on top of the stack)
      const isLastAsset = index === this.assets.length - 1;

      return {
        label: asset.category.name,
        data: this.reports.map(report => {
          const categoryBalance = report.rootCategoryBalances.find(balance => balance.category.id === asset.category.id);
          return categoryBalance?.balance || 0;
        }),
        backgroundColor: assetColors[index],
        borderColor: assetColors[index],
        stack: 'stack',
        barThickness: this.barThickness,
        borderRadius: isLastAsset ? {
          topLeft: 4,
          topRight: 4,
          bottomLeft: 0,
          bottomRight: 0
        } : 0,
        borderSkipped: false
      };
    });

    const liabilityDatasets = this.liabilities.map((liability, index) => {
      // For liabilities, we want rounded corners on the first item (bottom of the stack)
      const isFirstLiability = index === this.liabilities.length - 1;

      return {
        label: liability.category.name,
        data: this.reports.map(report => {
          const categoryBalance = report.rootCategoryBalances.find(
              balance => balance.category.id === liability.category.id
          );
          return categoryBalance ? -categoryBalance.balance : 0;
        }),
        backgroundColor: liabilityColors[index],
        borderColor: liabilityColors[index],
        stack: 'stack',
        barThickness: this.barThickness,
        borderRadius: isFirstLiability ? {
          topLeft: 0,
          topRight: 0,
          bottomLeft: 4,
          bottomRight: 4
        } : 0,
        borderSkipped: false
      };
    });

    if (this.showGap) {
      const netPositionDataset = {
        type: 'line' as const,
        label: 'Gap de liquidité',
        data: this.reports.map(report => report.assetBalance - report.liabilityBalance),
        borderColor: '#f89d3f',
        borderWidth: 2.5,
        tension: 0.8,
        fill: false,
        borderDash: [2.5, 1],
        order: 1
      };

      this.chartData = {
        labels: dates,
        datasets: [...assetDatasets, ...liabilityDatasets, netPositionDataset]
      };
    } else {
        this.chartData = {
            labels: dates,
            datasets: [...assetDatasets, ...liabilityDatasets]
        };
    }
  }

  private generateDistinctColors(count: number, startColor: string, endColor: string): string[] {
    // Convert hex to RGB
    const hexToRgb = (hex: string): number[] => {
      hex = hex.replace(/^#/, '');
      return [
        parseInt(hex.substring(0, 2), 16),
        parseInt(hex.substring(2, 4), 16),
        parseInt(hex.substring(4, 6), 16)
      ];
    };

    // Convert RGB to HSL
    const rgbToHsl = (rgb: number[]): [number, number, number] => {
      const [r, g, b] = rgb.map(x => x / 255);
      const max = Math.max(r, g, b);
      const min = Math.min(r, g, b);
      let h = 0, s = 0, l = (max + min) / 2;

      if (max !== min) {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        if (max === r) h = (g - b) / d + (g < b ? 6 : 0);
        else if (max === g) h = (b - r) / d + 2;
        else h = (r - g) / d + 4;
        h *= 60;
        if (h < 0) h += 360;
      }
      return [h, s * 100, l * 100];
    };

    // Convert HSL to RGB
    const hslToRgb = (hsl: [number, number, number]): number[] => {
      const [h, s, l] = [hsl[0], hsl[1] / 100, hsl[2] / 100];
      const c = (1 - Math.abs(2 * l - 1)) * s;
      const x = c * (1 - Math.abs((h / 60) % 2 - 1));
      const m = l - c / 2;
      let r = 0, g = 0, b = 0;

      if (h < 60) { r = c; g = x; }
      else if (h < 120) { r = x; g = c; }
      else if (h < 180) { g = c; b = x; }
      else if (h < 240) { g = x; b = c; }
      else if (h < 300) { r = x; b = c; }
      else { r = c; b = x; }

      return [
        Math.round((r + m) * 255),
        Math.round((g + m) * 255),
        Math.round((b + m) * 255)
      ];
    };

    // Convert RGB to Hex
    const rgbToHex = (rgb: number[]): string => `#${rgb.map(x => x.toString(16).padStart(2, '0')).join('')}`;

    // Start and end colors in HSL
    const startRgb = hexToRgb(startColor);
    const endRgb = hexToRgb(endColor);
    const startHsl = rgbToHsl(startRgb);
    const endHsl = rgbToHsl(endRgb);

    // Generate distinct colors
    const colors: string[] = [];
    for (let i = 0; i < count; i++) {
      // Interpolate HSL values
      const t = count > 1 ? i / (count - 1) : 0;

      // Interpolate hue, but distribute it more evenly
      const h = startHsl[0] + (endHsl[0] - startHsl[0]) * t;

      // Vary saturation and lightness to create distinction
      const s = startHsl[1] + (endHsl[1] - startHsl[1]) * t + (Math.sin(i * Math.PI / 2) * 20 - 10);
      const l = startHsl[2] + (endHsl[2] - startHsl[2]) * t + (Math.cos(i * Math.PI / 2) * 20 - 10);

      // Ensure saturation and lightness are within bounds
      const clampedS = Math.max(0, Math.min(100, s));
      const clampedL = Math.max(0, Math.min(100, l));

      // Convert back to RGB and then to hex
      const rgb = hslToRgb([h, clampedS, clampedL]);
      const hexColor = rgbToHex(rgb);

      // Add some opacity for chart readability
      colors.push(`${hexColor}B3`); // 70% opacity
    }

    return colors;
  }

  private formatCurrency(value: number): string {
    return new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: 'XOF',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(value);
  }
}
