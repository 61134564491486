// services/user.service.ts
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {getPageableAsParams, Page, Pageable} from '../models/page.model';
import {IUser} from "../models/user.model";
import {PasswordDTO} from "../models/password.model";

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private baseUrl = 'core/v1/users';

  constructor(private http: HttpClient) {}

  createUser(userDto: IUser): Observable<IUser> {
    return this.http.post<IUser>(this.baseUrl, userDto).pipe(take(1));
  }

  updateUser(id: string, userDto: IUser): Observable<IUser> {
    return this.http.put<IUser>(`${this.baseUrl}/${id}`, userDto).pipe(take(1));
  }

  toggleTwoFactor(): Observable<void> {
    return this.http.patch<void>(`${this.baseUrl}/two-factor`, {}).pipe(take(1));
  }

  getSelf(): Observable<IUser> {
    return this.http.get<IUser>(`${this.baseUrl}/self`).pipe(take(1));
  }

  updateSelf(userDto: IUser): Observable<IUser> {
    return this.http.put<IUser>(this.baseUrl, userDto).pipe(take(1));
  }

  deleteUser(id: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${id}`).pipe(take(1));
  }

  toggleUser(id: string): Observable<void> {
    return this.http.patch<void>(`${this.baseUrl}/toggle/${id}`, {}).pipe(take(1));
  }

  getUserById(id: string): Observable<IUser> {
    return this.http.get<IUser>(`${this.baseUrl}/${id}`).pipe(take(1));
  }

  getUserByUsername(username: string): Observable<IUser> {
    const params = new HttpParams().set('username', username)
    return this.http.get<IUser>(`${this.baseUrl}/username`, { params }).pipe(take(1));
  }

  getUserByEmail(email: string): Observable<IUser> {
    const params = new HttpParams().set('email', email)
    return this.http.get<IUser>(`${this.baseUrl}/email`, { params }).pipe(take(1));
  }

  getAllUsers(pageable: Pageable): Observable<Page<IUser>> {
    const params = getPageableAsParams(pageable);
    return this.http.get<Page<IUser>>(this.baseUrl, { params }).pipe(take(1));
  }

  getUsersByInstitution(institutionId: string, pageable: Pageable): Observable<Page<IUser>> {
    console.log(institutionId)
    const params = getPageableAsParams(pageable);
    return this.http.get<Page<IUser>>(`${this.baseUrl}/institution/${institutionId}`, { params }).pipe(take(1));
  }

  getUsersByGroup(groupIds: string[], pageable: Pageable): Observable<Page<IUser>> {
    let params = getPageableAsParams(pageable)
      .set('groupIds', groupIds.join(','));
    return this.http.get<Page<IUser>>(`${this.baseUrl}/groups`, { params }).pipe(take(1));
  }

  getUserByCredentials(username: string): Observable<IUser> {
    return this.http.get<IUser>(`${this.baseUrl}/credentials`, { params: new HttpParams().set('username', username) }).pipe(take(1));
  }

  updatePassword(passwordDto: PasswordDTO): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}/password`, passwordDto).pipe(take(1));
  }
}
