import { Component, Output, EventEmitter, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';

@Component({
  selector: 'date-range',
  standalone: true,
  imports: [FormsModule, CalendarModule],
  template: `
    <p-calendar dateFormat="dd/mm/yy" [(ngModel)]="rangeDates" selectionMode="range" [readonlyInput]="true" [autofocus]="false"
      (onSelect)="onDateSelection()" [minDate]="minDate" [maxDate]="maxDate" [placeholder]="placeholder" />
  `
})
export class DateRangeComponent {

  @Input() minDate: Date | null = null;
  @Input() maxDate: Date | null = null;
  @Input() placeholder: string = 'Sélectionner une période';
  @Output() dateRangeSelected = new EventEmitter<Date[]>();
  @Output() invalidRange = new EventEmitter<string>();

  @Input() rangeDates: Date[] | null = [];

  onDateSelection() {
    if (this.rangeDates && this.rangeDates.length === 2) {
      const [startDate, endDate] = this.rangeDates;
      const oneYearFromStart = new Date(startDate);
      oneYearFromStart.setFullYear(oneYearFromStart.getFullYear() + 1);

      if (endDate > oneYearFromStart) {
        this.invalidRange.emit('La période sélectionnée ne doit pas dépasser 1 an.');
        this.rangeDates = [startDate, oneYearFromStart];
      }

      this.dateRangeSelected.emit(this.rangeDates);
    }
  }
}
