import {Component, OnInit} from '@angular/core';
import {RouterOutlet} from "@angular/router";
import {TabMenuModule} from "primeng/tabmenu";
import {BalanceTreeTableComponent} from "../overview/report-tree-table.component";
import {CurrencyPipe, NgForOf, NgIf, NgTemplateOutlet} from "@angular/common";
import {DateRangeComponent} from "../../../shared/date-range/date-range.component";
import {ReportBalanceChartComponent} from "../overview/report-chart.component";
import {SelectButtonModule} from "primeng/selectbutton";
import {TabViewModule} from "primeng/tabview";
import {Frequency, FrequencyOption, IDailyBalanceReport, ReportType} from "../../../core/models/daily-balance.model";
import {DailyBalanceReportService} from "../../../core/services/daily-balance-report.service";
import {ItemValueHistoryService} from "../../../core/services/item-value-history.service";
import {BalanceProjection, IItemValueHistory} from "../../../core/models/item-value.model";
import {ToastService} from "../../../core/services/toast.service";
import {FormsModule} from "@angular/forms";
import {ValueHistoryChartComponent} from "./value-history.chart";

@Component({
  selector: 'app-liquidity-deadlock',
  standalone: true,
  imports: [
    // Modules
    TabMenuModule, SelectButtonModule, TabViewModule,
    // Pipes & Directives
    RouterOutlet, CurrencyPipe, NgForOf, NgTemplateOutlet,
    // Components
    BalanceTreeTableComponent, DateRangeComponent, ReportBalanceChartComponent, FormsModule, ValueHistoryChartComponent, NgIf
  ],
  templateUrl: './liquidity-deadlock.component.html',
  styleUrl: './liquidity-deadlock.component.scss'
})
export class LiquidityDeadlockComponent implements OnInit {

  activeTabIndex: number = 0;
  rangeDates: Date[] | null = null;
  lastReport: IDailyBalanceReport | null = null;

  frequencyOptions: FrequencyOption[] = [
    { label: '1D', value: Frequency.DAILY },
    { label: '1W', value: Frequency.WEEKLY },
    { label: '1M', value: Frequency.MONTHLY },
    { label: '3M', value: Frequency.QUARTERLY },
    { label: '1Y', value: Frequency.ANNUALLY }
  ];

  selectedFrequency: Frequency = Frequency.QUARTERLY;
  selectedCurrency: string = 'XOF';
  historicalData: BalanceProjection[] = [];

  private readonly tabConfigs = [
    { type: ReportType.GLOBAL, currency: 'XOF' },
    { type: ReportType.CURRENCY, currency: 'XOF' },
    { type: ReportType.CURRENCY, currency: 'EUR' }
  ];

  constructor(
    private readonly historyService: ItemValueHistoryService,
    private readonly reportService: DailyBalanceReportService,
    private readonly toastService: ToastService
  ) {}

  ngOnInit() {
    const today = new Date();
    const oneYearAgo = new Date();
    oneYearAgo.setMonth(today.getMonth() - 12);
    this.rangeDates = [oneYearAgo, today];

    this.loadLatestItemState();
    this.loadHistoricalData();
  }

  onTabChange(index: number) {
    this.activeTabIndex = index;
    if (this.rangeDates && this.rangeDates.length === 2) {
      this.selectedCurrency = this.tabConfigs[index].currency;
      this.loadHistoricalData();
    }
  }

  private loadLatestItemState() {
    this.reportService.getLatestReport(
      this.tabConfigs[this.activeTabIndex].currency,
      this.tabConfigs[this.activeTabIndex].type
    ).subscribe({
      next: (report) => this.lastReport = report,
      error: (error) => this.toastService.showToast('Chargement de l\'état des actifs & passifs', error.error, 'error')
    });
  }

  private loadHistoricalData() {
    if (!this.rangeDates || this.rangeDates.length !== 2) return;

    this.historyService.getHistoryByFrequency(this.selectedFrequency).subscribe({
      next: (history) => this.historicalData = history,
      error: (error) => this.toastService.showToast('Chargement de l\'impasse', error.error, 'error')
    });
  }

  onDateRangeSelected(dateRange: Date[]) {
    if (dateRange && dateRange.length === 2) {
      this.rangeDates = dateRange;
      this.loadHistoricalData();
    }
  }

  onFrequencyChange(value: Frequency) {
    this.selectedFrequency = value;
    if (this.rangeDates && this.rangeDates.length === 2) {
      this.loadHistoricalData();
    }
  }

  onInvalidRange(message: string) {
    this.toastService.showToast('Période de séléction', message, 'error');
  }

  calculateValueChange(current: number, previous: number): number {
    return this.historyService.calculateValueChange(
      { value: current } as IItemValueHistory,
      { value: previous } as IItemValueHistory
    );
  }

  calculatePercentageChange(current: number, previous: number): number {
    return this.historyService.calculatePercentageChange(
      { value: current } as IItemValueHistory,
      { value: previous } as IItemValueHistory
    );
  }

  getAssetBalance(): number {
    return this.lastReport?.assetBalance ?? 0;
  }

  getLiabilityBalance(): number {
    return this.lastReport?.liabilityBalance ?? 0;
  }

  getNetPosition(): number {
    return this.getAssetBalance() - this.getLiabilityBalance();
  }
}
