import { Component, OnInit } from '@angular/core';
import { DialogService, DynamicDialogConfig } from "primeng/dynamicdialog";
import { PaginatorState } from "primeng/paginator";
import { ConfirmationService } from "primeng/api";
import {DynamicTableComponent} from "../../../../../shared/components/dynamic-table/dynamic-table.component";
import {ToolbarComponent} from "../../../../../shared/components/toolbar/toolbar.component";
import {CustomAction, CustomTableHeader} from "../../../../../core/models/custom-table.model";
import {DEFAULT_PAGE_PARAMS, getDefaultPageable, Pageable, PageParams} from "../../../../../core/models/page.model";
import {ToastService} from "../../../../../core/services/toast.service";
import {DropdownModule} from "primeng/dropdown";
import {IClassificationRule, SourceType, SourceTypeLabels} from "../../../../../core/models/classification-rule.model";
import {FormsModule} from "@angular/forms";
import {ClassificationRuleService} from "../../../../../core/services/classification-rule.service";
import {customConfirmationDialog, customDynamicDialog} from "../../../../../core/utils/utils";
import {
  ClassificationRuleDetailsComponent
} from "../../dialogs/classification-rule-details/classification-rule-details.component";
import {Button} from "primeng/button";
import {Ripple} from "primeng/ripple";

@Component({
  selector: 'app-classification-rule',
  standalone: true,
  imports: [
    DynamicTableComponent,
    ToolbarComponent,
    DropdownModule,
    FormsModule,
    Button,
    Ripple
  ],
  template: `
    <div class="flex flex-column gap-2">
      <toolbar [title]="''" [actions]="toolbarActions" (search)="actions"></toolbar>
      <div class="flex flex-column gap-4">
        <div class="w-2">
          <p-dropdown
            [options]="sourceTypes"
            [(ngModel)]="selectedSourceType"
            (onChange)="onSourceTypeChange()"
            placeholder="Filtrer par source"
            [showClear]="true">
          </p-dropdown>
        </div>
        <dynamic-table
          [headers]="columns"
          [data]="data"
          [actions]="actions"
          [pageParams]="pageParams"
          (paginate)="onPageChanged($event)">
        </dynamic-table>
      </div>
    </div>
  `
})
export class ClassificationRuleComponent implements OnInit {

  columns: CustomTableHeader[] = [
    {
      column: 'Source',
      key: 'sourceType',
      type: 'text',
      sortable: true
    },
    {
      column: 'Nom',
      key: 'name',
      type: 'text',
      sortable: true
    },
    {
      column: 'Catégorie cible',
      key: 'category.name',
      type: 'text',
      sortable: false
    },
    {
      column: 'Priorité',
      key: 'priority',
      type: 'number',
      sortable: true
    },
    {
      column: 'Statut',
      key: 'active',
      type: 'boolean',
      sortable: true
    }
  ];
  actions: CustomAction[] = [
    {
      role: 'READ_CLASSIFICATION_RULE',
      icon: 'pi pi-info-circle',
      label: 'Consulter',
      severity: 'info',
      emit: (rule) => this.openRuleDetails(rule, true),
      visible: true
    },
    {
      role: 'UPDATE_CLASSIFICATION_RULE',
      icon: 'pi pi-pencil',
      label: 'Modifier',
      severity: 'primary',
      emit: (rule) => this.openRuleDetails(rule),
      visible: true
    },
    {
      role: 'DELETE_CLASSIFICATION_RULE',
      icon: 'pi pi-trash',
      label: 'Supprimer',
      severity: 'danger',
      emit: (rule) => this.deleteRule(rule),
      visible: true
    }
  ];
  toolbarActions: CustomAction[] = [
    {
      role: 'CREATE_CLASSIFICATION_RULE',
      icon: 'pi pi-plus',
      label: 'Nouvelle règle',
      severity: 'primary',
      emit: () => this.createRule(),
      visible: true
    }
  ];

  pageParams: PageParams = DEFAULT_PAGE_PARAMS;
  data: IClassificationRule[] = [];
  sourceTypes = Object.entries(SourceTypeLabels).map(([value, label]) => ({
    value: value as SourceType,
    label
  }));
  selectedSourceType: SourceType | null = null;

  constructor(
    private ruleService: ClassificationRuleService,
    private dialogService: DialogService,
    private toastService: ToastService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit() {
    this.loadRules();
  }

  loadRules(pageable: Pageable = getDefaultPageable(this.pageParams.page, this.pageParams.rows)) {
    const source = this.selectedSourceType;
    this.ruleService.getRules(pageable, source).subscribe({
      next: (page) => {
        this.data = page.content;
        this.pageParams = {
          ...this.pageParams,
          page: page.number,
          rows: page.size,
          first: page.number * page.size,
          totalRecords: page.totalElements,
        };
      },
      error: (err) => this.toastService.showToast('Chargement des règles', err.error, 'error')
    });
  }

  search(query: string) {
    if (query.length === 0) this.loadRules();
    else {
      this.ruleService.searchRules(query, this.selectedSourceType).subscribe({
        next: (page) => {
          this.data = page.content;
          this.pageParams = {
            ...this.pageParams,
            page: page.number,
            rows: page.size,
            first: page.number * page.size,
            totalRecords: page.totalElements,
          };
        },
        error: (err) => this.toastService.showToast('Recherche règles', err.error, 'error')
      });
    }
  }

  onSourceTypeChange() {
    this.loadRules();
  }

  onPageChanged($event: PaginatorState) {
    this.loadRules(getDefaultPageable($event.page, $event.rows));
  }

  private openRuleDetails(rule: any, isView: boolean = false) {
    const header = isView ? "Détails règle" : "Modification règle";
    const config: DynamicDialogConfig = customDynamicDialog(header, {isView, isEdit: !isView, data: rule});
    const ref = this.dialogService.open(ClassificationRuleDetailsComponent, config);
    ref.onClose.subscribe(() => this.loadRules());
  }

  private createRule() {
    const config: DynamicDialogConfig = customDynamicDialog('Création règle', {isCreate: true});
    const ref = this.dialogService.open(ClassificationRuleDetailsComponent, config);
    ref.onClose.subscribe(() => this.loadRules());
  }

  private deleteRule(rule: any) {
    const dialogOptions = customConfirmationDialog(
      "Confirmation Requise",
      "Cette règle sera supprimée si vous procédez. Continuer ?",
      {
        acceptButtonStyleClass: 'p-button-danger',
        accept: () => {
          this.ruleService.deleteRule(rule.id).subscribe({
            next: () => {
              this.loadRules();
              this.toastService.showToast('Suppression règle', 'La règle a été supprimée avec succès');
            },
            error: (err) => this.toastService.showToast('Suppression règle', err.error, 'error')
          });
        }
      }
    );
    this.confirmationService.confirm(dialogOptions);
  }
}
