import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { DialogService, DynamicDialogComponent, DynamicDialogRef } from 'primeng/dynamicdialog';
import {CustomDialogData} from "../../../core/models/custom-dialog.model";
import {CounterpartyService} from "../../../core/services/fx/counterparty.service";
import {ICounterparty} from "../../../core/models/fx/counterparty.model";
import {InputTextModule} from "primeng/inputtext";
import {DropdownModule} from "primeng/dropdown";
import {Button} from "primeng/button";
import {NgIf} from "@angular/common";
import {ToastService} from "../../../core/services/toast.service";

@Component({
  selector: 'app-edit-counterparty',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    InputTextModule,
    DropdownModule,
    Button,
    NgIf,
  ],
  template: `
        <div class="flex flex-column h-full pb-2">
            <form [formGroup]="formGroup" class="flex flex-column gap-4">
                <div class="flex flex-column gap-2">
                    <label for="name" class="font-medium">Nom</label>
                    <input pInputText id="name" formControlName="name" />
                </div>

                <div class="flex flex-column gap-2">
                    <label for="type" class="font-medium">Type</label>
                    <p-dropdown id="type" [options]="counterpartyTypes" formControlName="type"
                              placeholder="Sélectionner le type" optionLabel="label" optionValue="value">
                    </p-dropdown>
                </div>

                <div class="flex flex-column gap-2">
                    <label for="bicCode" class="font-medium">Code BIC</label>
                    <input pInputText id="bicCode" formControlName="bicCode" />
                </div>

                <div class="flex flex-column gap-2">
                    <label for="swiftCode" class="font-medium">Code SWIFT</label>
                    <input pInputText id="swiftCode" formControlName="swiftCode" />
                </div>
            </form>

            <div class="flex flex-grow-1 gap-2 justify-content-end align-items-end">
                <p-button *ngIf="!formGroup.disabled" [style]="{width: '100%'}" class="mb-2"
                         [label]="boundData?.isCreate ? 'Enregistrer' : 'Modifier'"
                         [disabled]="!formGroup.valid" [loading]="isLoading"
                         (onClick)="boundData?.isCreate ? create() : update()">
                </p-button>
            </div>
        </div>
    `
})
export class EditCounterpartyComponent implements OnInit {
  formGroup = new FormGroup({
    name: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    type: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    bicCode: new FormControl(''),
    swiftCode: new FormControl('')
  });

  counterpartyTypes = [
    {label: 'Banque', value: 'BANK'},
    {label: 'Courtier', value: 'BROKER'},
    {label: 'Entreprise', value: 'CORPORATE'},
    {label: 'Individu', value: 'INDIVIDUAL'}
  ];

  boundData: CustomDialogData | undefined;
  isLoading = false;
  instance: DynamicDialogComponent | undefined;

  constructor(
    private counterpartyService: CounterpartyService,
    private dialogService: DialogService,
    private toastService: ToastService,
    private ref: DynamicDialogRef
  ) {
    this.instance = this.dialogService.getInstance(this.ref);
  }

  ngOnInit() {
    this.boundData = this.instance?.data;
    if (!this.boundData?.isCreate) {
      this.formGroup.patchValue(this.boundData?.data);
      this.formGroup.patchValue({
        type: this.counterpartyTypes.find(t => t.label == this.boundData?.data.type)?.value ?? undefined
      });
      if (this.boundData?.isView) this.formGroup.disable();
    }
  }


  create() {
    this.isLoading = true;
    const counterparty = this.formGroup.value as ICounterparty;
    this.counterpartyService.createCounterparty(counterparty).subscribe({
      next: (response) => {
        this.toastService.showToast('Contrepartie créée', `La contrepartie ${response.name} a été créée avec succès.`);
        this.isLoading = false;
        this.ref.close();
      },
      error: (error) => {
        this.toastService.showToast('Création de la contrepartie', error.error, 'error');
        this.isLoading = false;
      }
    });
  }

  update() {
    this.isLoading = true;
    const counterparty = this.formGroup.value as ICounterparty;
    this.counterpartyService.updateCounterparty(this.boundData?.data.id, counterparty).subscribe({
      next: (response) => {
        this.toastService.showToast('Contrepartie modifiée', `La contrepartie ${response.name} a été modifiée avec succès.`);
        this.isLoading = false;
        this.ref.close();
      },
      error: (error) => this.toastService.showToast('Modification de la contrepartie', error.error, 'error')
    });
  }

}
