import {CommonModule} from '@angular/common';
import {Component, EventEmitter, Input, Output, SimpleChanges} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {ButtonModule} from 'primeng/button';
import {DynamicDialogModule} from 'primeng/dynamicdialog';
import {InputNumberModule} from 'primeng/inputnumber';
import {ISimulation, ISimulationResult, ISimulationWithResult} from '../../../../../core/models/simulation.model';
import {Periodicity} from '../../../../../core/enums/periodicity.enums';
import {customConfirmationDialog} from '../../../../../core/utils/utils';
import {ConfirmationService} from 'primeng/api';
import {ToastService} from '../../../../../core/services/toast.service';
import {SimulationService} from '../../../../../core/services/simulation.service';


@Component({
  selector: 'app-pricer-result',
  standalone: true,
  imports: [DynamicDialogModule, ButtonModule, InputNumberModule, CommonModule, FormsModule],
  templateUrl: './pricer-result.component.html',
  styleUrl: './pricer-result.component.scss'
})
export class PricerResultComponent {

  @Input() simulationData: ISimulation | undefined;
  @Input() instrumentType: 'BAT' | 'OAT' = 'BAT';
  @Output() reset = new EventEmitter<boolean>();
  @Output() simulationCreated = new EventEmitter<boolean>();

  dateValeur: Date = new Date();
  currentConvention: string = 'Exact/360'; // Default value BAT
  duration: number | undefined;
  rateOfReturn: number | undefined;
  netAmount: number | undefined;
  interest: number | undefined;
  couponCouru: number | undefined;
  placementPrice: number | undefined;
  interestCourus: number | undefined;
  simulationResults: ISimulationResult | undefined;
  private conventions = {
    'BAT': 'Exact/360',
    'OAT': 'Exact/Exact ISMA'
  };

  constructor(private confirmationService: ConfirmationService, private messageService: ToastService, private simulationService: SimulationService) {
  }

  get isBAT(): boolean {
    return this.instrumentType === 'BAT';
  }

  get isOAT(): boolean {
    return this.instrumentType === 'OAT';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['simulationData'] && changes['simulationData'].currentValue) {
      this.simulationData = changes['simulationData'].currentValue;

      if (this.simulationData) {
        const dateEcheance = this.simulationData.dateEcheance ? new Date(this.simulationData.dateEcheance) : new Date();
         this.dateValeur = this.simulationData.dateValeur ? new Date(this.simulationData.dateValeur) : new Date();
        this.currentConvention = this.getConvention();
        this.getResults(dateEcheance, this.dateValeur);
      }
    }
  }

  getConvention(): string {
    return this.conventions[this.instrumentType] || 'Exact/360';
  }

  getResults(dateEcheance: Date, dateValeur: Date) {
    /* DO NOT CHANGE THIS ORDER */
    this.duration = this.computeDuration(dateEcheance, dateValeur);
    if (this.isBAT) {
      this.interest = this.computeInterests();
      this.netAmount = this.computeNetAmount();
      this.rateOfReturn = this.computeRateOfReturn();
    }
    if (this.isOAT) {
      const numberOfPeriods = this.getPeriods(this.simulationData!.periodicity);
      console.log("numberOfPeriods", numberOfPeriods)
      this.rateOfReturn = this.simulationData!.tauxRendement;
      console.log("rateOfReturn", this.rateOfReturn)
      this.couponCouru = this.computeCouponCouru(numberOfPeriods, dateEcheance, dateValeur);
      console.log("this.couponCouru", this.couponCouru)
      this.interestCourus = this.computeInterestCourus();
      console.log("this.interestCourus", this.interestCourus)
      this.placementPrice = this.computePlacementPrice(numberOfPeriods);
      console.log("this.placementPrice", this.placementPrice)

      this.netAmount = this.computeNetAmount();
      console.log("this.netAmount", this.netAmount)

    }

    this.simulationResults = {
      duration: this.duration,
      rateOfReturn: this.rateOfReturn!,
      netAmount: this.netAmount!,
      interest: this.interest!,
      couponCouru: this.couponCouru,
      placementPrice: this.placementPrice,
      interestCourus: this.interestCourus,
    };

  }

  isButtonDisabled() {
    if (this.simulationResults) {
      return false
    } else {
      return true
    }

  }

  isMarcheSecondaire() {
    const today = new Date();
    return (this.dateValeur.getTime() > today.getTime() )
}

  computeDuration(dateEcheance: Date, dateValeur: Date): number {
    const millisecondsPerDay = 1000 * 60 * 60 * 24;
    return Math.round((dateEcheance.getTime() - dateValeur.getTime()) / millisecondsPerDay);
  }

  getPeriods(periodicity: string | undefined): number {
    switch (periodicity) {
      case Periodicity.QUARTERLY:
        return 4;  // 4 quarters in a year
      case Periodicity.SEMI_ANNUAL:
        return 2;  // 2 semesters in a year
      case Periodicity.ANNUAL:
        return 1;  // 1 period per year
      default:
        return 1;  // Default to annual if periodicity is unknown
    }
  }

  computeCouponCouru(numberOfPeriods: number, dateEcheance: Date, dateValeur: Date): number {
    const couponForPeriod = (this.simulationData!.tauxInteretCoupon! * this.simulationData!.valeurNominaleUnitaire) / (100 * numberOfPeriods); // Valeur du coupon trimestriel
    console.log("COUPON FOR PERI", couponForPeriod)
    // Calculer les jours écoulés depuis le dernier coupon
    const lastCouponDate = this.getLastCouponDate(dateEcheance, dateValeur, numberOfPeriods);
    const millisecondsPerDay = 1000 * 60 * 60 * 24;
    console.log("ANNE", millisecondsPerDay)
    const daysElapsed = Math.round((dateValeur.getTime() - lastCouponDate.getTime()) / millisecondsPerDay);
    const daysWithinPeriod = this.getDaysInPeriod(numberOfPeriods, dateValeur);
    console.log("last coupon date", lastCouponDate)
    console.log("lastCouponDate millisecondsPerDay daysElapsed daysWithinPeriod", lastCouponDate, millisecondsPerDay, daysElapsed, daysWithinPeriod)

    const couponCouru = (couponForPeriod * daysElapsed / daysWithinPeriod / 100).toFixed(12);
    return parseFloat(couponCouru);
  }

  getLastCouponDate(dateEcheance: Date, dateValeur: Date, numberOfPeriods: number): Date {
    const lastCouponDate = new Date(dateEcheance);
    lastCouponDate.setFullYear(dateValeur.getFullYear());
    switch (numberOfPeriods) {
      case 1:
        return new Date(lastCouponDate.setFullYear(lastCouponDate.getFullYear() - 1));

      case 2:
        return new Date(lastCouponDate.setFullYear(lastCouponDate.getFullYear(), lastCouponDate.getMonth() - 6));

      case 4:
        return new Date(lastCouponDate.setFullYear(lastCouponDate.getFullYear(), lastCouponDate.getMonth() - 3));
    }
    return lastCouponDate;
  }

  getDaysInPeriod(numberOfPeriods: number, dateValeur: Date): number {
    const year = dateValeur.getFullYear();
    const daysInYear = this.isLeapYear(year) ? 366 : 365;
    return (daysInYear / numberOfPeriods);
  }

  isLeapYear(year: number): boolean {
    //année bissextile
    return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
  }

  computePlacementPrice(numberOfPeriods: number): number | undefined {
    const tauxRendementPeriod = this.simulationData!.tauxRendement! / 100 / numberOfPeriods;
    const couponForPeriod = (this.simulationData!.tauxInteretCoupon! * this.simulationData!.valeurNominaleUnitaire) / (100 * numberOfPeriods);

    // Compute total periods including fractional periods
    const totalNumberOfPeriodsUntilObligationMaturity = 5 * numberOfPeriods; //this.simulationData!.maturite!
    const integerPeriods = Math.floor(totalNumberOfPeriodsUntilObligationMaturity); // partie entière de la valeur totalDePériode in case maturity is a decimal
    const fractionPeriod = totalNumberOfPeriodsUntilObligationMaturity - integerPeriods; // partie décimale


    let valeurActualiseeCouponsList: number[] = [];
    let valeurActualiseeCoupons = 0;
    // Calculate value of full periods
    for (let t = 1; t <= integerPeriods; t++) {
      const result = couponForPeriod / Math.pow(1 + tauxRendementPeriod, t);

      // Add the result to the total, but round to two decimals at each step
      valeurActualiseeCouponsList.push(parseFloat(result.toFixed(2)));

      // Log the intermediate value after rounding to two decimal places
      //    console.log("tauxRendementPeriod, couponForPeriod, valeurActualiseeCoupons, t", tauxRendementPeriod, couponForPeriod, valeurActualiseeCoupons, t);

    }

    valeurActualiseeCouponsList.forEach(el => {
      valeurActualiseeCoupons = valeurActualiseeCoupons + el;
    })

    // console.log("valcoup", valeurActualiseeCoupons)
    // We add fractional period if there is one
    if (fractionPeriod > 0) {
      const couponProportionnel = couponForPeriod * fractionPeriod;
      valeurActualiseeCoupons += couponProportionnel / Math.pow(1 + tauxRendementPeriod, integerPeriods + 1);
    }

    const valeurActualiseeNominale = this.simulationData!.valeurNominaleUnitaire / Math.pow(1 + tauxRendementPeriod, tauxRendementPeriod * numberOfPeriods);

    const prixPur = valeurActualiseeCoupons + valeurActualiseeNominale;

    // console.log("totalNumberOfPeriodsUntilObligationMaturity, couponForPeriod, integerPeriods, fractionPeriod, prixPur, valeurActualiseeCoupons, valeurActualiseeNominale", totalNumberOfPeriodsUntilObligationMaturity, couponForPeriod,
    // integerPeriods, fractionPeriod, prixPur, valeurActualiseeCoupons, valeurActualiseeNominale)
    return prixPur + this.couponCouru!;
  }

  computeInterestCourus(): number | undefined {
    // Les intérêts courus sont basés sur le coupon couru et les autres intérêts accumulés
    const interest = (this.simulationData!.montantAPlacer * this.couponCouru!) / 100;
    return interest;
  }

  computeInterests() {
    /* Intérêts = (montant à placer * Taux d'intérêts / 100 * durée en jours / 360) */
    //console.log("ALCUL INTERET", this.simulationData)
    const data = this.simulationData;
    return data ? data.montantAPlacer * data.tauxPreCompte! / 100 * this.duration! / 360 : 0;
  }

  computeNetAmount() {
    if (this.isOAT) {
      /* NetAmount = Montant à placer * (Prix du placement / 100) + intérêts courus */
      return (this.simulationData!.montantAPlacer * this.placementPrice! / 100);
    } else {
      /*NetAmount = Montant a placer - interests */
      return this.simulationData!.montantAPlacer - this.interest!
    }

  }

  computeRateOfReturn() {
    /* Taux de Rendement = ((MontantAPacer - MontantNet) / montantNet) × (conventionNumberOfDays × 100 / DuréeEnJours) */
    const rendement = ((this.simulationData!.montantAPlacer - this.netAmount!) / this.netAmount!) * (360 / this.duration!);
    return rendement * 100;
  }

  saveSimulation() {
    const header = "Confirmation Requise";
    const message = "Voulez-vous procéder à la validation de la simulation et des résultats ?"
    const dialogOptions = customConfirmationDialog(
      header, message,
      {
        acceptButtonStyleClass: 'p-button-success',
        accept: () => {
          let simulationWithResults: ISimulationWithResult = {
            simulationDTO: this.simulationData!,
            result: this.simulationResults!
          };
          this.createSimulation(simulationWithResults)
        },
        reject: () => {
          console.log('rejected')
          this.processFormReset(true);

        }
      }
    );

    this.confirmationService.confirm(dialogOptions);

  }

  createSimulation(simulationWithResults: ISimulationWithResult) {
    console.log("simwithresult", simulationWithResults)
    this.simulationService.createSimulation(simulationWithResults).subscribe({
      next: (swr) => {
        //TODO: ADD LOADERS
        this.messageService.showToast('Création de simulation', 'La simulation et ses résultats ont bien été enregistrés.', 'success');
        this.processFormReset(false);
       // this.simulationCreated.emit(false);
        this.simulationCreated.emit(true);
        console.log("result from backend", swr)

      },
      error: (error) => {
        this.messageService.showToast('Création de simulation', 'Une erreur est survenue. Merci de réessayer plus tard.', 'danger');

        console.log("Error creating simulation", error)
      }
    });

  }


  showResetForm() {
    const header = "Confirmation Requise";
    const message = "Voulez-vous supprimer la simulation et son résultat ?"
    const dialogOptions = customConfirmationDialog(
      header, message,
      {
        acceptButtonStyleClass: 'p-button-success',
        accept: () => {
          this.processFormReset()
          this.messageService.showToast('Suppression', 'La simulation et son résultat ont bien été supprimés.', 'success');
        },
      }
    );

    this.confirmationService.confirm(dialogOptions);
  }

  processFormReset(resultFormOnly: boolean = true) {
    this.reset.emit(!resultFormOnly); //won't emit because i only need to reset the result form
    this.duration = undefined;
    this.rateOfReturn = undefined;
    this.netAmount = undefined;
    this.interest = undefined;
    this.couponCouru = undefined;
    this.placementPrice = undefined;
    this.interestCourus = undefined;
    this.simulationResults = undefined
  }
}

