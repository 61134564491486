// classification-rule-details.component.ts
import {Component, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {DialogService, DynamicDialogComponent, DynamicDialogRef} from 'primeng/dynamicdialog';
import {NgForOf, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault} from '@angular/common';
import {InputTextModule} from 'primeng/inputtext';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {InputNumberModule} from 'primeng/inputnumber';
import {DropdownModule} from 'primeng/dropdown';
import {CheckboxModule} from 'primeng/checkbox';
import {ButtonModule} from 'primeng/button';
import {CustomDialogData} from "../../../../../core/models/custom-dialog.model";
import {IBalanceSheetCategory, ItemSource} from "../../../../../core/models/balance-sheet.model";
import {
  ConditionOperatorLabels,
  ConditionType,
  ConditionTypeLabels,
  getConditionOperatorOptions,
  IClassificationRule,
  SourceType,
  SourceTypeLabels
} from "../../../../../core/models/classification-rule.model";
import {ClassificationRuleService} from "../../../../../core/services/classification-rule.service";
import {BalanceSheetCategoryService} from "../../../../../core/services/balance-sheet-category.service";
import {ToastService} from "../../../../../core/services/toast.service";
import {getUnpagedPageable} from "../../../../../core/models/page.model";

@Component({
  selector: 'app-classification-rule-details',
  standalone: true,
  imports: [
    // Modules
    ReactiveFormsModule, InputTextModule, InputTextareaModule, InputNumberModule, DropdownModule, CheckboxModule,
    ButtonModule,
    // Pipes & Directives
    NgIf, NgForOf, NgSwitchCase, NgSwitchDefault, NgSwitch
  ],
  templateUrl: './classification-rule-details.component.html',
  styleUrls: ['./classification-rule-details.component.scss']
})
export class ClassificationRuleDetailsComponent implements OnInit {

  ConditionType = ConditionType; // For template usage
  data: CustomDialogData | undefined;
  instance: DynamicDialogComponent | undefined;
  formGroup: FormGroup = new FormGroup({});
  categories: IBalanceSheetCategory[] = [];
  rule: IClassificationRule | undefined;

  sourceTypeOptions = Object.entries(SourceTypeLabels)
    .map(([value, label]) => ({
      value: value as SourceType,
      label
    }));

  conditionTypeOptions = Object.entries(ConditionTypeLabels)
    .map(([value, label]) => ({
      value: value as ConditionType,
      label
    }));

  constructor(
    private readonly ref: DynamicDialogRef,
    private readonly dialogService: DialogService,
    private readonly fb: FormBuilder,
    private readonly ruleService: ClassificationRuleService,
    private readonly categoryService: BalanceSheetCategoryService,
    private readonly toastService: ToastService
  )
  {
    this.instance = this.dialogService.getInstance(this.ref);
    this.data = this.instance.data;
    this.rule = this.data?.data;
    this.initForm();
  }

  ngOnInit() {
    this.loadCategories();
    if ((this.data?.isView || this.data?.isEdit) && this.rule) {
      this.patchForm(this.rule);
      if (this.data.isView) this.formGroup.disable();
    }
  }

  get conditions() {
    return this.formGroup.get('conditions') as FormArray;
  }

  getOperatorOptions(type: ConditionType) {
    return getConditionOperatorOptions(type).map(operator => ({
      value: operator,
      label: ConditionOperatorLabels[operator]
    }));
  }

  addCondition() {
    const condition = this.fb.group({
      type: ['', Validators.required],
      operator: ['', Validators.required],
      value: ['', Validators.required]
    });
    this.conditions.push(condition);
  }

  removeCondition(index: number) {
    this.conditions.removeAt(index);
  }

  onConditionTypeChange(index: number) {
    const condition = this.conditions.at(index);
    condition.patchValue({ operator: '', value: '' });
  }

  save() {
    if (this.formGroup.valid && this.conditions.length > 0) {
      const rule = this.getFormValue();
      this.ruleService.createRule(rule).subscribe({
        next: () => {
          this.toastService.showToast('Création règle', 'La règle a été créée avec succès');
          this.ref.close();
        },
        error: (err) => this.toastService.showToast('Création règle', err.error, 'error')
      });
    }
  }

  update() {
    if (this.formGroup.valid && this.conditions.length > 0) {
      const rule = this.getFormValue();
      this.ruleService.updateRule(rule).subscribe({
        next: () => {
          this.toastService.showToast('Modification règle', 'La règle a été modifiée avec succès');
          this.ref.close();
        },
        error: (err) => this.toastService.showToast('Modification règle', err.error, 'error')
      });
    }
  }

  private initForm() {
    this.formGroup = this.fb.group({
      name: ['', Validators.required],
      description: [''],
      sourceType: ['', Validators.required],
      targetCategoryId: ['', Validators.required],
      priority: [1, [Validators.required, Validators.min(1)]],
      active: [true],
      conditions: this.fb.array([])
    });
  }

  private patchForm(rule: IClassificationRule) {
    // Find the enum key from the label
    const sourceTypeKey = Object.entries(SourceTypeLabels).find(
      ([, label]) => label === rule.sourceType
    )?.[0] as SourceType;

    this.formGroup.patchValue({
      name: rule.name,
      description: rule.description,
      sourceType: sourceTypeKey, // Use the enum key instead of the label
      targetCategoryId: rule.category.id,
      priority: rule.priority,
      active: rule.active
    });

    // Clear and recreate conditions
    while (this.conditions.length) {
      this.conditions.removeAt(0);
    }

    rule.conditions.forEach(condition => {
      this.conditions.push(this.fb.group({
        type: [condition.type, Validators.required],
        operator: [condition.operator, Validators.required],
        value: [condition.value, Validators.required]
      }));
    });
  }

  private getFormValue() {
    const formValue = this.formGroup.value;
    return {
      ...(this.rule?.id ? { id: this.rule.id } : {}),
      name: formValue.name,
      description: formValue.description,
      sourceType: formValue.sourceType,
      category: this.categories.find(c => c.id === formValue.targetCategoryId),
      priority: formValue.priority,
      active: formValue.active,
      conditions: this.conditions.value.map((condition: any) => ({
        ...condition,
        ...(condition.id ? { id: condition.id } : {})
      }))
    };
  }

  private loadCategories() {
    this.categoryService.findBySource(ItemSource.INTERNAL, getUnpagedPageable()).subscribe({
      next: (page) => this.categories = page.content,
      error: (err) => this.toastService.showToast('Chargement catégories', err.error, 'error')
    });
  }

  close() {
    this.ref.close();
  }
}
