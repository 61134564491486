import {Component, OnInit} from '@angular/core';
import {DynamicTableComponent} from "../../../../shared/components/dynamic-table/dynamic-table.component";
import {ToolbarComponent} from "../../../../shared/components/toolbar/toolbar.component";
import {CustomAction, CustomTableHeader} from "../../../../core/models/custom-table.model";
import {DEFAULT_PAGE_PARAMS, getDefaultPageable, Pageable, PageParams} from "../../../../core/models/page.model";
import {DialogService, DynamicDialogConfig} from "primeng/dynamicdialog";
import {ToastService} from "../../../../core/services/toast.service";
import {customConfirmationDialog, customDynamicDialog} from "../../../../core/utils/utils";
import {BalanceSheetCategoryService} from "../../../../core/services/balance-sheet-category.service";
import {BalanceSheetCategoryDetailsComponent} from "../dialogs/balance-sheet-category-details/balance-sheet-category-details.component";
import {PaginatorState} from "primeng/paginator";
import {FlowLawCategoryDetailsComponent, FlowLawCategoryDialogData} from "../dialogs/flow-law-category-details/flow-law-category-details.component";
import {IBalanceSheetCategory, ItemType} from "../../../../core/models/balance-sheet.model";
import {ConfirmationService} from "primeng/api";

@Component({
  selector: 'app-balance-sheet-category',
  standalone: true,
  imports: [
    DynamicTableComponent,
    ToolbarComponent
  ],
  templateUrl: './balance-sheet-category.component.html',
  styleUrl: './balance-sheet-category.component.scss'
})
export class BalanceSheetCategoryComponent implements OnInit {

  columns: CustomTableHeader[] = [
    {
      column: 'Section',
      key: 'type',
      type: 'text',
      sortable: false,
      filterable: false
    },
    {
      column: 'Source',
      key: 'source',
      type: 'text',
    },
    {
      column: 'Poste',
      key: 'itemType',
      type: 'text',
      sortable: false,
      filterable: false
    },
    {
      column: 'Catégories',
      key: 'parent.name',
      type: 'text',
      sortable: false,
      filterable: false
    },
    {
      column: 'Sous-catégories',
      key: 'name',
      type: 'text',
      sortable: false,
      filterable: false
    },
    {
      column: 'Loi',
      key: 'activeLaw.name',
      type: 'text',
      sortable: false,
      filterable: false
    },
  ];
  actions: CustomAction[] = [
    {
      role: 'READ_BALANCE_SHEET_CATEGORY',
      icon: 'pi pi-info-circle',
      label: 'Consulter',
      severity: 'info',
      emit: (balanceSheetCategory: IBalanceSheetCategory) => this.openLawDetail(balanceSheetCategory, true),
      visible: true
    },
    {
      role: 'UPDATE_BALANCE_SHEET_CATEGORY',
      icon: 'pi pi-pen-to-square',
      label: 'Modifier',
      severity: 'primary',
      emit: (balanceSheetCategory: IBalanceSheetCategory) => this.openLawDetail(balanceSheetCategory),
      visible: (balanceSheetCategory: IBalanceSheetCategory) => balanceSheetCategory.canUpdate
    },
    {
      role: 'UPDATE_BALANCE_SHEET_CATEGORY',
      icon: 'pi pi-link',
      label: 'Lois',
      severity: 'primary',
      emit: (balanceSheetCategory: IBalanceSheetCategory) => this.openFlowLawCategory(balanceSheetCategory),
      visible: (balanceSheetCategory: IBalanceSheetCategory) => balanceSheetCategory.itemType === ItemType.CONVENTIONAL
    },
    {
      role: 'DELETE_BALANCE_SHEET_CATEGORY',
      icon: 'pi pi-trash',
      label: 'Supprimer',
      severity: 'danger',
      emit: (item: IBalanceSheetCategory) => this.deleteCategory(item),
      visible: (item: IBalanceSheetCategory) => item.canDelete
    }
  ];
  toolbarActions: CustomAction[] = [
    {
      role: 'CREATE_BALANCE_SHEET_CATEGORY',
      icon: 'pi pi-plus',
      label: 'Nouveau',
      severity: 'primary',
      emit: () => this.createFlowFlaw(),
      visible: true
    },
    /**
    {
      role: 'CREATE_BALANCE_SHEET_CATEGORY',
      icon: 'pi pi-download',
      label: 'Charger',
      severity: 'secondary',
      emit: () => this.importBalanceSheetCategory(),
      visible: true
    },
    {
      role: 'CREATE_BALANCE_SHEET_CATEGORY',
      icon: 'pi pi-sync',
      label: 'Synchroniser',
      severity: 'secondary',
      emit: () => this.initCategories(),
      visible: true
    },
      **/
  ];
  pageParams: PageParams = DEFAULT_PAGE_PARAMS;
  data: IBalanceSheetCategory[] = [];

  constructor(
    private readonly categoryService: BalanceSheetCategoryService,
    private readonly dialogService: DialogService,
    private readonly messageService: ToastService,
    private readonly toastService: ToastService,
    private readonly confirmationService: ConfirmationService
  ) {
  }

  ngOnInit() {
    this.loadBalanceSheetCategories();
    // this.fetchValues() //this is for the bilan daily test. Will be removed later.
  }

  fetchValues(pageable: Pageable = getDefaultPageable(this.pageParams.page, this.pageParams.rows)) {
    this.categoryService.getVal(pageable).subscribe({
      next: (page) => {
        console.log("next", page)

      }
    })
  }


  loadBalanceSheetCategories(pageable: Pageable = getDefaultPageable(this.pageParams.page, this.pageParams.rows)) {
    this.categoryService.getLeafCategories(pageable).subscribe({
      next: (page) => {
        this.data = page.content;
        this.data.forEach(value => {
          if (!value.parent) value.parent = value;
        });
        this.pageParams = {
          ...this.pageParams,
          page: page.number,
          rows: page.size,
          first: page.number * page.size,
          totalRecords: page.totalElements,
        };
      },
      error: (err) => this.toastService.showToast('Chargement des catégories', err.error, 'error')
    });
  }

  search(query: string) {
    if (query.length === 0) this.loadBalanceSheetCategories();
    else {
      this.categoryService.searchCategories(query).subscribe({
        next: (page) => {
          this.data = page.content;
          this.pageParams = {
            ...this.pageParams,
            page: page.number,
            rows: page.size,
            first: page.number * page.size,
            totalRecords: page.totalElements,
          };
        },
        error: (err) => this.toastService.showToast('Recherche catégories', err.error, 'error')
      });
    }
  }

  onPageChanged($event: PaginatorState) {
    this.loadBalanceSheetCategories(getDefaultPageable($event.page, $event.rows));
  }

  initCategories() {
    const summary = 'Catégories par défaut';
    this.categoryService.initBalanceSheetCategories().subscribe({
      next: () => {
        this.toastService.showToast(summary, 'Les catégories par défaut ont été créées avec succès.');
        this.loadBalanceSheetCategories();
      },
      error: (err) => this.toastService.showToast(summary, err.error, 'error')
    })
  }

  private openLawDetail(balanceSheetCategory: IBalanceSheetCategory, isView: boolean = false) {
    const header = isView ? "Détails catégorie" : "Modification détails catégorie";
    const config: DynamicDialogConfig = customDynamicDialog(header, {isView, isEdit: !isView, data: balanceSheetCategory});
    const ref = this.dialogService.open(BalanceSheetCategoryDetailsComponent, config);
    ref.onClose.subscribe(() => this.loadBalanceSheetCategories());
  }

  private createFlowFlaw() {
    const config: DynamicDialogConfig = customDynamicDialog('Création catégorie de bilan', {isCreate: true});
    const ref = this.dialogService.open(BalanceSheetCategoryDetailsComponent, config);
    ref.onClose.subscribe(() => this.loadBalanceSheetCategories());
  }

  private importBalanceSheetCategory() {
    console.log('Import Flow Law');
  }

  private openFlowLawCategory(balanceSheetCategory: IBalanceSheetCategory) {
    const data = {isCreate: true, data: {category: balanceSheetCategory}} as FlowLawCategoryDialogData
    const config: DynamicDialogConfig = customDynamicDialog('Loi / Catégorie', data);
    const ref = this.dialogService.open(FlowLawCategoryDetailsComponent, config);
    ref.onClose.subscribe(() => this.loadBalanceSheetCategories());
  }


  private deleteCategory(balanceSheetCategory: IBalanceSheetCategory) {
    const header = "Confirmation Requise";
    const message = "Cette catégorie sera supprimée si vous procédez. Procéder ?"

    const dialogOptions = customConfirmationDialog(
      header, message,
      {
        acceptButtonStyleClass: 'p-button-success',
        accept: () => {
          this.categoryService.deleteCategory(balanceSheetCategory.id).subscribe({
            next: () => {
              this.loadBalanceSheetCategories();
              this.messageService.showToast('Suppression catégorie', 'La suppression de la catégorie a été effectuée.');
            },
            error: () => {
              this.messageService.showToast('Erreur', 'Une erreur est survenue lors de la récupération des informations. Merci de contacter l\'administrateur', 'error');
            }
          });
        }
      });
    this.confirmationService.confirm(dialogOptions);
  }
}

