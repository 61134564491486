import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {TreeNode} from 'primeng/api';
import {TreeTableModule} from 'primeng/treetable';
import {CurrencyPipe, DatePipe, DecimalPipe, NgForOf, NgIf} from '@angular/common';
import {IDailyBalanceReport} from "../../../core/models/daily-balance.model";
import {BalanceSheetType} from "../../../core/models/balance-sheet.model";
import {TableModule} from "primeng/table";
import {TooltipModule} from "primeng/tooltip";

@Component({
  selector: 'balance-tree-table',
  standalone: true,
  imports: [
    TreeTableModule,
    CurrencyPipe,
    DatePipe,
    DecimalPipe,
    TooltipModule,
    NgForOf,
    TableModule,
    NgIf,
  ],
  template: `
    <div class="flex flex-column gap-4">
      <!-- Unified Header Table -->
      <div *ngIf="reports.length > 0">
        <p-table [value]="[{}]" styleClass="p-datatable-sm" [scrollable]="true"
                 [scrollHeight]="'50px'" [style]="{'margin-bottom': '0'}">
          <ng-template pTemplate="colgroup">
            <colgroup>
              <col style="width: 300px; min-width: 300px">
              <col style="width: 100px; min-width: 100px">
              <col *ngFor="let col of cols.slice(2)" style="width: 150px; min-width: 150px">
            </colgroup>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th [style.width]="'300px'" class="text-left">Catégorie</th>
              <th [style.width]="'100px'" class="text-center">%</th>
              <ng-container *ngFor="let col of cols.slice(2)">
                <th [style.width]="'150px'" class="text-center">
                  {{ col.header }}
                </th>
              </ng-container>
            </tr>
          </ng-template>
        </p-table>
      </div>

      <div class="flex flex-column gap-2">
        <!-- Assets Table -->
        <div *ngIf="require(BalanceSheetType.ASSET)" class="overflow-x-auto">
          <p-treeTable
            [value]="assetNodes"
            [columns]="cols"
            [scrollable]="true"
            [resizableColumns]="true"
            [reorderableColumns]="true"
            [loading]="loading"
            [scrollHeight]="'400px'"
            styleClass="p-treetable-sm no-header">

            <!-- Column Groups -->
            <ng-template pTemplate="colgroup" let-columns>
              <colgroup>
                <col style="width: 300px; min-width: 300px">
                <col style="width: 100px; min-width: 100px">
                <col *ngFor="let col of columns.slice(2)" style="width: 150px; min-width: 150px">
              </colgroup>
            </ng-template>

            <!-- Table Body -->
            <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
              <tr [ttRow]="rowNode">
                <td class="sticky-col">
                  <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                  <span>{{rowData.name}}</span>
                </td>
                <td class="text-center">
                  {{rowData.percentageInParent | number:'1.2-2'}}%
                </td>
                <ng-container *ngFor="let col of cols.slice(2)">
                  <td class="text-center">
                    {{rowData[col.field] | currency:'XOF':'symbol':'1.0-0':'fr'}}
                  </td>
                </ng-container>
              </tr>
            </ng-template>

            <!-- Assets Footer -->
            <ng-template pTemplate="footer" let-columns>
              <tr class="font-bold text-xl">
                <td class="font-bold ">Total Actifs</td>
                <td class="text-center font-bold">100%</td>
                <ng-container *ngFor="let col of cols.slice(2)">
                  <td class="text-center font-bold">
                    {{getAssetTotalForColumn(col.field) | currency:'XOF':'symbol':'1.0-0':'fr'}}
                  </td>
                </ng-container>
              </tr>
            </ng-template>
          </p-treeTable>
        </div>

        <!-- Liabilities Table -->
        <div *ngIf="require(BalanceSheetType.LIABILITY)" class="overflow-x-auto">
          <p-treeTable
            [value]="liabilityNodes"
            [columns]="cols"
            [scrollable]="true"
            [resizableColumns]="true"
            [reorderableColumns]="true"
            [loading]="loading"
            [scrollHeight]="'400px'"
            styleClass="no-header">

            <!-- Column Groups -->
            <ng-template pTemplate="colgroup" let-columns>
              <colgroup>
                <col [style.width]="'300px'">
                <col [style.width]="'100px'">
                <col *ngFor="let col of columns.slice(2)" [style.width]="'150px'">
              </colgroup>
            </ng-template>

            <!-- Table Body -->
            <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
              <tr [ttRow]="rowNode">
                <td class="sticky-col">
                  <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                  <span>{{rowData.name}}</span>
                </td>
                <td class="text-center">
                  {{rowData.percentageInParent | number:'1.2-2'}}%
                </td>
                <ng-container *ngFor="let col of cols.slice(2)">
                  <td class="text-center">
                    {{rowData[col.field] | currency:'XOF':'symbol':'1.0-0':'fr'}}
                  </td>
                </ng-container>
              </tr>
            </ng-template>

            <!-- Liabilities Footer -->
            <ng-template pTemplate="footer" let-columns>
              <tr class="font-bold text-xl">
                <td class="font-bold">Total Passifs</td>
                <td class="font-bold text-center">100%</td>
                <ng-container *ngFor="let col of cols.slice(2)">
                  <td class="text-center font-bold">
                    {{getLiabilityTotalForColumn(col.field) | currency:'XOF':'symbol':'1.0-0':'fr'}}
                  </td>
                </ng-container>
              </tr>
            </ng-template>
          </p-treeTable>
        </div>
      </div>
    </div>

  `,
  styles: [`
    :host ::ng-deep .p-treetable .p-treetable-thead > tr > th,
    :host ::ng-deep .p-datatable .p-datatable-thead > tr > th {
      white-space: nowrap;
    }

    :host ::ng-deep .p-treetable .p-treetable-tbody > tr > td {
      white-space: nowrap;
    }

    :host ::ng-deep .p-treetable-wrapper,
    :host ::ng-deep .p-datatable-wrapper {
      overflow-x: auto;
    }

    :host ::ng-deep .p-treetable .p-treetable-thead > tr > th {
      background-color: #f8f9fa;
      padding: 0.5rem;
      text-align: left;
      border: 1px solid #dee2e6;
    }

    :host ::ng-deep .p-treetable .p-treetable-tbody > tr > td {
      padding: 0.5rem;
      border: 1px solid #dee2e6;
    }

    :host ::ng-deep .p-treetable {
      margin-bottom: 1rem;
    }

    :host ::ng-deep .p-treetable .p-treetable-thead tr th {
      position: sticky;
      top: 0;
      z-index: 1;
    }

    .sticky-header {
      position: sticky;
      top: 0;
      z-index: 2;
      background: white;
    }

    .sticky-col {
      position: sticky;
      left: 0;
      background: white;
      z-index: 1;
    }

    :host ::ng-deep .p-treetable .p-treetable-tbody > tr > td.sticky-col,
    :host ::ng-deep .p-datatable .p-datatable-thead > tr > th.sticky-col {
      position: sticky;
      left: 0;
      background: white;
      z-index: 1;
    }

    :host ::ng-deep .p-treetable .p-treetable-tbody > tr > td.sticky-col::after,
    :host ::ng-deep .p-datatable .p-datatable-thead > tr > th.sticky-col::after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 1px;
      background: #dee2e6;
    }

    :host ::ng-deep .p-treetable,
    :host ::ng-deep .p-datatable {
      .p-datatable-wrapper,
      .p-treetable-wrapper {
        overflow-x: auto;
      }

      table {
        min-width: 100%;
        table-layout: fixed;
      }
    }

    /* Add shadow effect for sticky column */
    :host ::ng-deep .p-treetable .p-treetable-tbody > tr > td.sticky-col::after {
      box-shadow: 2px 0 4px -2px rgba(0, 0, 0, 0.1);
    }

    /* Handle background color for footer sticky column */
    :host ::ng-deep .p-treetable .p-treetable-tfoot > tr > td:first-child {
      position: sticky;
      left: 0;
      background: inherit;
      z-index: 1;
    }
  `]
})
export class BalanceTreeTableComponent implements OnChanges {
  @Input() reports: IDailyBalanceReport[] = [];
  @Input() loading = false;

  assetNodes: TreeNode[] = [];
  liabilityNodes: TreeNode[] = [];
  cols: any[] = [];
  private sortedReports: IDailyBalanceReport[] = [];

  ngOnChanges(changes: SimpleChanges) {
    if (changes['reports']) {
      this.sortReports();
      this.initializeTable();
    }
  }

  private sortReports() {
    this.sortedReports = [...this.reports].sort((a, b) =>
      new Date(b.reportDate).getTime() - new Date(a.reportDate).getTime()
    );
  }

  private initializeTable() {
    if (!this.reports.length) return;
    this.initializeColumns();
    this.separateAndTransformNodes();
  }

  private initializeColumns() {
    this.cols = [
      {
        field: 'name',
        header: 'Catégorie',
        frozen: true,
        resizeable: true,
        styleClass: 'w-20rem'
      },
      {
        field: 'percentageInParent',
        header: '%',
        resizeable: true,
        styleClass: 'w-8rem'
      },
      ...this.sortedReports.map(report => ({
        field: `balance_${report.id}`,
        header: new Date(report.reportDate).toLocaleDateString('fr-FR'),
        resizeable: true,
        styleClass: 'w-15rem'
      }))
    ];
  }

  private transformToTreeNodes(categories: any[], isRoot: boolean = true): TreeNode[] {
    return categories.map(category => {
      const node: TreeNode = {
        data: {
          name: category.category.name,
          type: category.category.type,
          percentageInParent: category.percentageInParent,
        },
        expanded: !isRoot
      };

      this.sortedReports.forEach(report => {
        const matchingCategory = this.findCategoryInReport(report, category.category.id);
        node.data[`balance_${report.id}`] = matchingCategory?.balance || 0;
      });

      if (category.childrenBalances?.length) {
        node.children = this.transformToTreeNodes(category.childrenBalances, false);
      }

      return node;
    });
  }

  private separateAndTransformNodes() {
    if (!this.reports[0]?.rootCategoryBalances) return;

    const assetCategories = this.reports[0].rootCategoryBalances.filter(
      cat => cat.category.type === BalanceSheetType.ASSET
    );
    const liabilityCategories = this.reports[0].rootCategoryBalances.filter(
      cat => cat.category.type === BalanceSheetType.LIABILITY
    );

    this.assetNodes = this.transformToTreeNodes(assetCategories, true);
    this.liabilityNodes = this.transformToTreeNodes(liabilityCategories, true);
  }

  private findCategoryInReport(report: IDailyBalanceReport, categoryId: string): any {
    const findInCategories = (categories: any[]): any => {
      for (const cat of categories) {
        if (cat.category.id === categoryId) return cat;
        if (cat.childrenBalances?.length) {
          const found = findInCategories(cat.childrenBalances);
          if (found) return found;
        }
      }
      return null;
    };

    return findInCategories(report.rootCategoryBalances);
  }

  getAssetTotalForColumn(field: string): number {
    return this.getTotalForColumn(field, this.assetNodes);
  }

  getLiabilityTotalForColumn(field: string): number {
    return this.getTotalForColumn(field, this.liabilityNodes);
  }

  private getTotalForColumn(field: string, nodes: TreeNode[]): number {
    let total = 0;
    const processNode = (node: TreeNode) => {
      if (!node.children?.length) {
        total += (node.data[field] || 0);
      }
      node.children?.forEach(processNode);
    };
    nodes.forEach(processNode);
    return total;
  }

  protected readonly BalanceSheetType = BalanceSheetType;

  require(type: BalanceSheetType): boolean {
    return this.reports.map(r => r.rootCategoryBalances).flat().some(c => c.category.type === type);
  }

  getTableWidth(): string {
    const baseWidth = 400; // 300px for category + 100px for percentage
    const reportWidth = this.reports.length * 150; // 150px per report
    return `${baseWidth + reportWidth}px`;
  }
}
