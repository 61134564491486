import {Component, OnDestroy, OnInit} from '@angular/core';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {PaginatorState} from 'primeng/paginator';
import {ConfirmationService} from 'primeng/api';
import {ToolbarComponent} from "../../../shared/components/toolbar/toolbar.component";
import {DynamicTableComponent} from "../../../shared/components/dynamic-table/dynamic-table.component";
import {CustomAction, CustomTableHeader} from "../../../core/models/custom-table.model";
import {ICounterparty, ICounterpartySearchCriteria} from "../../../core/models/fx/counterparty.model";
import {getDefaultPageable, Pageable, PageParams} from "../../../core/models/page.model";
import {customConfirmationDialog, customDynamicDialog, DEFAULT_ROWS_PER_PAGE_OPTIONS} from "../../../core/utils/utils";
import {CounterpartyService} from "../../../core/services/fx/counterparty.service";
import {ToastService} from "../../../core/services/toast.service";
import {EditCounterpartyComponent} from "./edit-counterparty.component";
import {SearchCounterpartyComponent} from "./search-counterparty.component";

@Component({
  selector: 'app-counterparty',
  standalone: true,
  imports: [
    DynamicTableComponent,
    ToolbarComponent,
    ToolbarComponent,
    DynamicTableComponent
  ],
  template: `
        <div class="flex flex-column gap-4 w-full h-full align-content-between">
            <toolbar class="w-full" title="Contreparties" [actions]="toolbarActions" />
            <dynamic-table [headers]="columns" [data]="counterparties" [pageParams]="pageable"
                         [actions]="actions" (paginate)="onPageChange($event)" />
        </div>
    `
})
export class CounterpartyComponent implements OnInit, OnDestroy {
  counterparties: ICounterparty[] = [];
  columns: CustomTableHeader[] = [
    {
      key: 'name',
      column: 'Nom',
      type: 'text'
    },
    {
      key: 'type',
      column: 'Type',
      type: 'text'
    },
    {
      key: 'bicCode',
      column: 'Code BIC',
      type: 'text'
    },
    {
      key: 'swiftCode',
      column: 'Code SWIFT',
      type: 'text'
    }
  ];
  pageable: PageParams = {
    page: 0,
    first: 0,
    rows: 0,
    totalRecords: 0,
    rowPerPageOptions: DEFAULT_ROWS_PER_PAGE_OPTIONS
  };
  ref: DynamicDialogRef | undefined;
  actions: CustomAction[] = [];
  toolbarActions: CustomAction[] = [];
  private currentSearchCriteria: ICounterpartySearchCriteria = { name: '', type: '', bicCode: '', swiftCode: '' };

  constructor(
    private counterpartyService: CounterpartyService,
    private toastService: ToastService,
    public dialogService: DialogService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit() {
    this.loadCounterparties();
    this.actions = this.getActions();
    this.toolbarActions = this.getToolbarActions();
  }

  ngOnDestroy() {
    this.ref?.close();
  }

  getToolbarActions(): CustomAction[] {
    return [
      {
        role: 'CREATE_COUNTERPARTY',
        icon: 'pi pi-plus',
        label: 'Nouveau',
        severity: 'primary',
        emit: () => this.createCounterparty(),
        visible: true
      },
      {
        role: 'READ_COUNTERPARTY',
        icon: 'pi pi-search',
        label: 'Rechercher',
        severity: 'secondary',
        emit: () => this.showSearchDialog(),
        visible: true
      }
    ];
  }

  getActions(): CustomAction[] {
    return [
      {
        role: 'READ_COUNTERPARTY',
        icon: 'pi pi-info-circle',
        label: 'Consulter',
        severity: 'info',
        emit: (item) => this.viewCounterparty(item),
        visible: true
      },
      {
        role: 'UPDATE_COUNTERPARTY',
        icon: 'pi pi-pencil',
        label: 'Modifier',
        severity: 'primary',
        emit: (item) => this.editCounterparty(item),
        visible: true
      },
      {
        role: 'DELETE_COUNTERPARTY',
        icon: 'pi pi-trash',
        label: 'Supprimer',
        severity: 'danger',
        emit: (item) => this.confirmDeletion(item),
        visible: true
      }
    ];
  }

  loadCounterparties(pageable: Pageable = getDefaultPageable()) {
    this.counterpartyService.getAllCounterparties(pageable).subscribe({
      next: page => {
        this.counterparties = page.content;
        this.pageable = {
          page: page.number,
          first: page.number * page.size,
          rows: page.size,
          totalRecords: page.totalElements,
          rowPerPageOptions: DEFAULT_ROWS_PER_PAGE_OPTIONS
        };
      },
      error: err => this.toastService.showToast('Chargement des contreparties', err.error, 'error')
    });
  }

  createCounterparty() {
    const data = { isCreate: true };
    this.ref = this.dialogService.open(EditCounterpartyComponent, customDynamicDialog('Création contrepartie', data));
    this.ref.onClose.subscribe(() => this.loadCounterparties());
  }

  editCounterparty(counterparty: ICounterparty) {
    const data = { isEdit: true, data: counterparty };
    this.ref = this.dialogService.open(EditCounterpartyComponent, customDynamicDialog('Modification contrepartie', data));
    this.ref.onClose.subscribe(() => this.loadCounterparties());
  }

  viewCounterparty(counterparty: ICounterparty) {
    const data = { isView: true, data: counterparty };
    this.ref = this.dialogService.open(EditCounterpartyComponent, customDynamicDialog('Détails contrepartie', data));
  }

  confirmDeletion(counterparty: ICounterparty) {
    const message = `Voulez-vous supprimer la contrepartie ${counterparty.name} ?`;
    const header = 'Suppression de contrepartie';
    const options = customConfirmationDialog(header, message, {
      accept: () => this.deleteCounterparty(counterparty)
    });
    this.confirmationService.confirm(options);
  }

  deleteCounterparty(counterparty: ICounterparty) {
    this.counterpartyService.deleteCounterparty(counterparty.id!).subscribe({
      next: () => {
        this.toastService.showToast('Contrepartie supprimée',
          `La contrepartie ${counterparty.name} a été supprimée avec succès.`);
        this.loadCounterparties();
      },
      error: err => this.toastService.showToast('Suppression de la contrepartie', err.error, 'error')
    });
  }

  showSearchDialog() {
    this.ref = this.dialogService.open(SearchCounterpartyComponent, {
      header: 'Rechercher des contreparties',
      width: '25%',
      height: 'auto',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000
    });

    this.ref.onClose.subscribe((criteria: any) => {
      if (criteria) {
        this.currentSearchCriteria = criteria;
        this.searchCounterparties(criteria);
      }
    });
  }

  searchCounterparties(criteria: ICounterpartySearchCriteria, pageable: Pageable = getDefaultPageable()) {
    this.counterpartyService.searchCounterparties(criteria, pageable).subscribe({
      next: page => {
        this.counterparties = page.content;
        this.pageable = {
          page: page.number,
          first: page.number * page.size,
          rows: page.size,
          totalRecords: page.totalElements,
          rowPerPageOptions: DEFAULT_ROWS_PER_PAGE_OPTIONS
        };
      },
      error: err => this.toastService.showToast('Recherche des contreparties', err.error, 'error')
    });
  }

// Update the onPageChange method to handle search:
  onPageChange = (event: PaginatorState) => {
    const pageable = getDefaultPageable(event.page, event.rows);
    if (this.currentSearchCriteria) this.searchCounterparties(this.currentSearchCriteria, pageable);
    else this.loadCounterparties(pageable);
  };
}
