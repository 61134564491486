<!-- risk-overview.component.html -->
<div class="flex flex-column gap-4">
  <div class="w-full flex justify-content-between border-bottom-1 border-gray-300 pb-2 mb-2">
    <span class="font-bold text-2xl text-black-alpha-70">Impasse de liquidités</span>
  </div>

  <div class="w-full flex justify-content-between">
    <div class="w-full">
      <p-tabView [(activeIndex)]="activeTabIndex" (activeIndexChange)="onTabChange($event)">
        <p-tabPanel header="Global">
          <ng-container *ngTemplateOutlet="content"></ng-container>
        </p-tabPanel>
        <p-tabPanel header="Devise locale">
          <ng-container *ngTemplateOutlet="content"></ng-container>
        </p-tabPanel>
        <p-tabPanel header="Devise étrangère">
          <ng-container *ngTemplateOutlet="content"></ng-container>
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>

  <div class="w-full">
    <ng-template #content>
      <div class="w-full flex flex-column justify-content-between gap-4">
        <!-- Main Dashboard Cards -->
        <div *ngIf="lastReport" class="w-full flex justify-content-between gap-2 h-20rem">
          <!-- Assets Card -->
          <div class="w-6 border-1 border-round border-black-alpha-10 p-4 h-full overflow-y-scroll">
            <div class="flex justify-content-between align-items-center mb-3">
              <div class="flex gap-2 align-items-center">
                <div class="px-1 border-2 bg-green-50 border-round border-green-500">
                  <i class="font-medium text-sm pi pi-angle-double-up text-green-800"></i>
                </div>
                <span class="text-xl font-bold text-green-800">Actif</span>
              </div>
              <span class="text-green-600 font-bold">
                {{ getAssetBalance() | currency : selectedCurrency : 'symbol' : '1.0-0':'fr' }}
              </span>
            </div>
          </div>

          <!-- Liabilities Card -->
          <div  class="w-6 border-1 border-round border-black-alpha-10 p-4 h-full overflow-y-scroll">
            <div class="flex justify-content-between align-items-center mb-3">
              <div class="flex gap-2 align-items-center">
                <div class="px-1 border-2 bg-red-50 border-round border-red-500">
                  <i class="font-medium pi pi-angle-double-down text-red-600"></i>
                </div>
                <span class="text-red-600 font-bold text-xl font-bold">Passif</span>
              </div>
              <span class="text-red-600 font-bold">
              {{ getLiabilityBalance() | currency : selectedCurrency : 'symbol' : '1.0-0':'fr' }}
            </span>
            </div>
          </div>
        </div>

        <!-- Liquidity Gap Card -->
        <div *ngIf="lastReport" class="border-1 border-round border-blue-300 px-4 py-2 bg-blue-50">
          <div class="flex justify-content-between align-items-center">
            <div class="flex gap-2 align-items-center">
              <i class="pi pi-info-circle text-blue-600"></i>
              <span class="text-xl font-semibold">Gap de liquidité</span>
            </div>
            <span class="text-blue-600 text-xl font-semibold">
            {{ getNetPosition() | currency : selectedCurrency : 'symbol' : '1.0-0':'fr' }}
          </span>
          </div>
        </div>

        <div class="w-full flex justify-content-between">
          <date-range class="w-2" [rangeDates]="rangeDates" (dateRangeSelected)="onDateRangeSelected($event)" (invalidRange)="onInvalidRange($event)"/>
          <p-selectButton
            [options]="frequencyOptions"
            [(ngModel)]="selectedFrequency"
            (onChange)="onFrequencyChange($event.value)"
            styleClass="text-xs p-button-sm">
          </p-selectButton>
        </div>
        <!-- Time Series Chart -->
        <div class="w-full flex gap-2">
          <value-history-chart class="w-full" [history]="historicalData" [frequency]="selectedFrequency" [currency]="selectedCurrency"
          />
        </div>

      </div>
    </ng-template>
  </div>
</div>
