import {Injectable} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {JwtToken} from '../models/jwt.model';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor(private jwtHelper: JwtHelperService) { }

  public getToken(): JwtToken {
    const token = localStorage.getItem('token');
    if (token) return this.jwtHelper.decodeToken(token) as JwtToken;
    return {} as JwtToken;
  }

  public setToken(token: string) {
    localStorage.setItem('token', token);
  }

  public removeToken() {
    this.removeRefreshToken();
    localStorage.removeItem('token');
  }

  public getRefreshToken(): string | null {
    return localStorage.getItem('refresh');
  }

  public setRefreshToken(token: string) {
    localStorage.setItem('refresh', token);
  }

  public removeRefreshToken() {
    localStorage.removeItem('refresh');
  }

  clearStorage() {
    this.removeToken();
    this.removeRefreshToken();
    this.removePublicKey();
  }

  public setPublicKey(publicKey: string) {
    localStorage.setItem('publicKey', publicKey);
  }

  public getPublicKey(): string | null {
    return localStorage.getItem('publicKey');
  }

  public removePublicKey() {
    localStorage.removeItem('publicKey');
  }
}
