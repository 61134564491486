import {IBalanceSheetCategory} from "./balance-sheet.model";

export interface IClassificationRule {
  id: string;
  institutionId: string;
  name: string;
  description?: string;
  sourceType: string;
  category: IBalanceSheetCategory;
  conditions: IRuleCondition[];
  priority: number;
  active: boolean;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface IRuleCondition {
  id: string;
  ruleId: string;
  type: ConditionType;
  operator: ConditionOperator;
  value: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export enum SourceType {
  LOAN = 'LOAN',
  ACCOUNT = 'ACCOUNT',
  SECURITIES = 'SECURITIES',
  BORROWING = 'BORROWING'
}

// condition-type.enum.ts
export enum ConditionType {
  AMOUNT = 'AMOUNT',
  DURATION = 'DURATION',
  RATE = 'RATE',
  COUNTERPARTY = 'COUNTERPARTY'
}

// condition-operator.enum.ts
export enum ConditionOperator {
  EQUALS = 'EQUALS',
  NOT_EQUALS = 'NOT_EQUALS',
  GREATER_THAN = 'GREATER_THAN',
  LESS_THAN = 'LESS_THAN',
  GREATER_THAN_EQUALS = 'GREATER_THAN_EQUALS',
  LESS_THAN_EQUALS = 'LESS_THAN_EQUALS',
  BETWEEN = 'BETWEEN',
  IN = 'IN',
  NOT_IN = 'NOT_IN'
}


// Display helpers
export const SourceTypeLabels: Record<SourceType, string> = {
  [SourceType.LOAN]: 'Prêts',
  [SourceType.ACCOUNT]: 'Comptes',
  [SourceType.SECURITIES]: 'Titres',
  [SourceType.BORROWING]: 'Emprunts'
};

export const ConditionTypeLabels: Record<ConditionType, string> = {
  [ConditionType.AMOUNT]: 'Montant',
  [ConditionType.DURATION]: 'Durée',
  [ConditionType.RATE]: 'Taux',
  [ConditionType.COUNTERPARTY]: 'Contrepartie'
};

export const ConditionOperatorLabels: Record<ConditionOperator, string> = {
  [ConditionOperator.EQUALS]: 'Égal à',
  [ConditionOperator.NOT_EQUALS]: 'Différent de',
  [ConditionOperator.GREATER_THAN]: 'Supérieur à',
  [ConditionOperator.LESS_THAN]: 'Inférieur à',
  [ConditionOperator.GREATER_THAN_EQUALS]: 'Supérieur ou égal à',
  [ConditionOperator.LESS_THAN_EQUALS]: 'Inférieur ou égal à',
  [ConditionOperator.BETWEEN]: 'Entre',
  [ConditionOperator.IN]: 'Dans la liste',
  [ConditionOperator.NOT_IN]: 'Pas dans la liste'
};

// Helper functions
export const getConditionOperatorOptions = (conditionType: ConditionType): ConditionOperator[] => {
  switch (conditionType) {
    case ConditionType.AMOUNT:
    case ConditionType.DURATION:
    case ConditionType.RATE:
      return [
        ConditionOperator.EQUALS,
        ConditionOperator.NOT_EQUALS,
        ConditionOperator.GREATER_THAN,
        ConditionOperator.LESS_THAN,
        ConditionOperator.GREATER_THAN_EQUALS,
        ConditionOperator.LESS_THAN_EQUALS,
        ConditionOperator.BETWEEN
      ];
    case ConditionType.COUNTERPARTY:
      return [
        ConditionOperator.EQUALS,
        ConditionOperator.NOT_EQUALS,
        ConditionOperator.IN,
        ConditionOperator.NOT_IN
      ];
    default:
      return Object.values(ConditionOperator);
  }
};

// Type guards
export const isNumericConditionType = (type: ConditionType): boolean => {
  return [
    ConditionType.AMOUNT,
    ConditionType.DURATION,
    ConditionType.RATE
  ].includes(type);
};

export const isListOperator = (operator: ConditionOperator): boolean => {
  return [
    ConditionOperator.IN,
    ConditionOperator.NOT_IN
  ].includes(operator);
};
