import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {getDefaultPageable, getPageableAsParams, Page, Pageable} from '../models/page.model';
import {IBalanceSheetCategory} from '../models/balance-sheet.model';

@Injectable({
  providedIn: 'root'
})
export class BalanceSheetCategoryService {
  private readonly apiUrl = `risque/v1/balance-sheet-categories`;

  constructor(private readonly http: HttpClient) { }

  getCategoryName(categoryCode: string): Observable<string> {
    return this.http.get<string>(`${this.apiUrl}/category-name/${categoryCode}`, { responseType: 'text' as 'json' });
  }

  createCategory(category: IBalanceSheetCategory): Observable<IBalanceSheetCategory> {
    return this.http.post<IBalanceSheetCategory>(this.apiUrl, category);
  }

  updateCategory(category: IBalanceSheetCategory): Observable<IBalanceSheetCategory> {
    return this.http.put<IBalanceSheetCategory>(`${this.apiUrl}/${category.id}`, category);
  }

  searchCategories(query: string = '', pageable: Pageable = getDefaultPageable()): Observable<Page<IBalanceSheetCategory>> {
    const params = getPageableAsParams(pageable).append('query', query);
    return this.http.get<Page<IBalanceSheetCategory>>(`${this.apiUrl}/search`, { params });
  }

  findBySource(source: string, pageable: Pageable): Observable<Page<IBalanceSheetCategory>> {
    const params = getPageableAsParams(pageable).append('source', source);
    return this.http.get<Page<IBalanceSheetCategory>>(`${this.apiUrl}/source`, { params });
  }

  addChildCategory(parentId: string, childCategory: IBalanceSheetCategory): Observable<IBalanceSheetCategory> {
    return this.http.post<IBalanceSheetCategory>(`${this.apiUrl}/${parentId}/children`, childCategory);
  }

  getCategoryById(id: string): Observable<IBalanceSheetCategory> {
    return this.http.get<IBalanceSheetCategory>(`${this.apiUrl}/${id}`);
  }

  getCategoryByCode(code: string): Observable<IBalanceSheetCategory> {
    return this.http.get<IBalanceSheetCategory>(`${this.apiUrl}/code/${code}`);
  }

  getAllCategories(pageable: Pageable): Observable<Page<IBalanceSheetCategory>> {
    const params = getPageableAsParams(pageable);
    return this.http.get<Page<IBalanceSheetCategory>>(this.apiUrl, { params });
  }

  getCurrentUserCategories(pageable: Pageable): Observable<Page<IBalanceSheetCategory>> {
    const params = getPageableAsParams(pageable);
    return this.http.get<Page<IBalanceSheetCategory>>(`${this.apiUrl}/current-user`, { params });
  }

  getRootCategories(): Observable<IBalanceSheetCategory[]> {
    return this.http.get<IBalanceSheetCategory[]>(`${this.apiUrl}/root`);
  }

  getCategoriesByType(type: string): Observable<IBalanceSheetCategory[]> {
    return this.http.get<IBalanceSheetCategory[]>(`${this.apiUrl}/type/${type}`);
  }

  getLeafCategories(pageable: Pageable): Observable<Page<IBalanceSheetCategory>> {
    const params = getPageableAsParams(pageable);
    return this.http.get<Page<IBalanceSheetCategory>>(`${this.apiUrl}/leaf`, { params });
  }

  getLeafCategoriesByItemType(pageable: Pageable, itemType: string): Observable<Page<IBalanceSheetCategory>>  {
    const params = getPageableAsParams(pageable);
    return this.http.get<Page<IBalanceSheetCategory>>(`${this.apiUrl}/leaf/itemType/${itemType}`, { params })
  }

  deleteCategory(id: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${id}`);
  }

  getBalanceSheetTypes(): Observable<string[]> {
    return this.http.get<string[]>(`${this.apiUrl}/types`);
  }

  initBalanceSheetCategories(): Observable<void> {
    return this.http.get<void>(`${this.apiUrl}/init`)
  }

  getVal(pageable: Pageable) {
    const params = getPageableAsParams(pageable);
    return  this.http.get(`risque/v1/administration/bilan`, { params })
  }

}
