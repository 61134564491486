import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ChartModule } from 'primeng/chart';
import { FormsModule } from '@angular/forms';
import { Frequency } from '../../../core/models/daily-balance.model';
import {BalanceProjection} from "../../../core/models/item-value.model";

@Component({
  selector: 'value-history-chart',
  standalone: true,
  imports: [
    ChartModule,
    FormsModule,
  ],
  template: `
    <div class="p-4 border-1 border-round border-black-alpha-10 flex flex-column gap-4 w-full">
      <p-chart type="bar" [data]="chartData" [options]="chartOptions" height="400px" />
    </div>
  `
})
export class ValueHistoryChartComponent implements OnChanges {
  @Input() history: BalanceProjection[] = [];
  @Input() frequency: Frequency = Frequency.DAILY;
  @Input() currency: string = 'XOF';

  chartData: any;
  chartOptions: any;

  constructor() {
    this.initChartOptions();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['history'] || changes['frequency']) this.updateChartData();
    if (changes['currency']) this.initChartOptions();
  }

  private initChartOptions() {
    this.chartOptions = {
      plugins: {
        legend: {
          position: 'top',
          align: 'center',
          labels: {
            usePointStyle: true,
            padding: 20,
            font: { size: 11 }
          }
        },
        tooltip: {
          mode: 'nearest',
          intersect: true,
          callbacks: {
            label: (context: any) => {
              const label = context.dataset.label || '';
              const value = context.raw || 0;
              return `${label}: ${this.formatCurrency(value)}`;
            }
          }
        }
      },
      scales: {
        x: {
          stacked: true
        },
        y: {
          stacked: true,
          title: {
            display: true,
            text: this.currency === 'XOF' ? 'Francs CFA - XOF' : 'Euros - EUR'
          },
          ticks: {
            callback: (value: number) => {
              return new Intl.NumberFormat('fr-FR', {
                style: 'decimal',
                notation: 'compact'
              }).format(value);
            }
          }
        }
      },
      interaction: {
        mode: 'nearest',
        intersect: true
      }
    };
  }

  private updateChartData() {
    const dates = this.history.map(item =>
      new Date(item.date).toLocaleDateString('fr-FR')
    );

    const assetData = this.history.map(item => item.assetBalance);
    const liabilityData = this.history.map(item => -item.liabilityBalance); // Negative for visual stacking
    const netPosition = this.history.map(item => item.assetBalance - item.liabilityBalance);

    this.chartData = {
      labels: dates,
      datasets: [
        {
          type: 'bar',
          label: 'Actifs',
          data: assetData,
          backgroundColor: '#4CAF50',
          borderColor: '#4CAF50',
          stack: 'stack'
        },
        {
          type: 'bar',
          label: 'Passifs',
          data: liabilityData,
          backgroundColor: '#f44336',
          borderColor: '#f44336',
          stack: 'stack'
        },
        {
          type: 'line',
          label: 'Position nette',
          data: netPosition,
          borderColor: '#2196F3',
          borderWidth: 2,
          fill: false,
          tension: 0.4
        }
      ]
    };
  }

  private formatCurrency(value: number): string {
    return new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: this.currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(Math.abs(value));
  }
}
