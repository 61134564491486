// sidebar-menu.component.ts
import { Component, OnInit } from '@angular/core';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SidebarModule } from 'primeng/sidebar';
import { StyleClassModule } from 'primeng/styleclass';
import { RippleModule } from 'primeng/ripple';
import { StorageService } from '../../../core/services/storage.service';
import { HasRoleDirective } from '../../../core/directives/has-role.directive';
import { HasModuleDirective } from '../../../core/directives/has-module.directive';
import { HasProductDirective } from '../../../core/directives/has-product.directive';
import { ModulesEnum, PermissionsEnum, ProductsEnum } from '../../../core/enums/access-controls.enums';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MenuItemComponent } from "./menu-item.component";
import { MenuItem } from "../../../core/models/menu-item.model";
import { AccessService } from "../../../core/services/access.service";

@Component({
  selector: 'app-sidebar-menu',
  standalone: true,
  imports: [
    // Modules
    CommonModule, SidebarModule, StyleClassModule, RippleModule,
    // Components
    MenuItemComponent,
    // Directives
    RouterLink, RouterLinkActive, HasRoleDirective, HasModuleDirective, HasProductDirective
  ],
  template: `
    <div class="flex flex-column h-full gap-4">
      <!-- Header -->
      <div class="flex align-items-center justify-content-between px-4 pt-3 flex-shrink-0">
        <div class="inline-flex align-items-center gap-2">
          <span class="font-semibold text-4xl">alima</span>
          <span class="text-sm text-color-secondary">by finappli</span>
        </div>
      </div>

      <!-- Menu Items -->
      <div class="overflow-y-auto flex flex-column gap-4">
        <!-- Principal Section -->
        <div class="menu-section flex flex-column">
          <div class="p-3 flex align-items-center justify-content-between text-600 cursor-pointer"
               (click)="toggleSection('principal')"
               [class.active]="expandedSections.principal">
            <span class="font-medium">PRINCIPAL</span>
            <i class="pi" [class.pi-chevron-down]="expandedSections.principal"
               [class.pi-chevron-right]="!expandedSections.principal"></i>
          </div>

          <div class="pl-2" [@slideInOut]="expandedSections.principal">
            <ng-container *ngFor="let item of principalMenus">
              <app-menu-item class="pl-2" [item]="item" [expanded]="false" *ngIf="checkAccess(item)"></app-menu-item>
            </ng-container>
          </div>
        </div>

        <!-- Administration Section -->
        <div class="menu-section">
          <div class="p-3 flex align-items-center justify-content-between text-600 cursor-pointer"
               (click)="toggleSection('admin')" [class.active]="expandedSections.admin">
            <span class="font-medium">ADMINISTRATION</span>
            <i class="pi" [class.pi-chevron-down]="expandedSections.admin" [class.pi-chevron-right]="!expandedSections.admin"></i>
          </div>

          <div class="pl-2" [@slideInOut]="expandedSections.admin">
            <ng-container *ngFor="let item of adminMenus">
              <app-menu-item class="pl-2" [item]="item" [expanded]="false" *ngIf="checkAccess(item)"></app-menu-item>
            </ng-container>
          </div>
        </div>
      </div>

      <!-- Footer -->
      <div class="mt-auto border-t-1 surface-border p-2 flex flex-grow-1 align-items-end">
        <div class="flex align-items-center cursor-pointer gap-2 border-round text-700
                    hover:surface-100 transition-duration-150 transition-colors w-full p-3" (click)="logout()">
          <div class="flex flex-column">
            <span class="font-bold">{{username | uppercase}}</span>
            <span class="font-medium">{{institution}}</span>
          </div>
          <i class="pi pi-sign-out ml-auto"></i>
        </div>
      </div>
    </div>
  `,
  styles: [`
    :host {
      display: block;
      height: 100%;
    }

    :host ::ng-deep {
      .p-sidebar {
        height: 100vh;
        width: 300px;
      }
    }

    .overflow-y-auto {
      height: calc(100% - 140px); // Adjust based on header and footer height
    }
  `],
  animations: [
    trigger('slideInOut', [
      state('true', style({ height: '*' })),
      state('false', style({ height: '0', overflow: 'hidden' })),
      transition('true <=> false', animate('300ms ease-in-out'))
    ])
  ]
})
export class SidebarMenuComponent implements OnInit {
  username: string = '';
  institution: string = '';
  expandedSections = {
    principal: true,
    admin: true
  };

  principalMenus: MenuItem[] = [
    /**
    {
      label: 'Dashboard',
      icon: 'pi pi-chart-bar',
      link: 'dashboard',
      requiredModule: ModulesEnum.FOREX
    },
     **/
    {
      label: 'Trésorerie',
      icon: 'pi pi-wallet',
      requiredModule: ModulesEnum.TRESORERIE,
      subMenus: [
        {
          label: 'Locale',
          requiredProduct: ProductsEnum.LOCALE,
          subMenus: [
            { label: 'Relevé', link: 'tresorerie/locale/releve', requiredRole: 'READ_OPERATION' },
            { label: 'Prévisionnel', link: 'tresorerie/locale/previsionnel', requiredRole: 'READ_OPERATION' },
            { label: 'Prêts & Emprunts', link: 'tresorerie/locale/loan', requiredRole: 'READ_OPERATION' }
          ]
        },
        {
          label: 'Devise',
          requiredProduct: ProductsEnum.DEVISE,
          subMenus: [
            { label: 'Relevé', link: 'tresorerie/devise/releve', requiredRole: 'READ_OPERATION' },
            { label: 'Prévisionnel', link: 'tresorerie/devise/previsionnel', requiredRole: 'READ_OPERATION' },
            { label: 'Prêts & Emprunts', link: 'tresorerie/devise/loan', requiredRole: 'READ_OPERATION' }
          ]
        },
        {
          label: 'Correspondant',
          requiredProduct: ProductsEnum.VIREMENT,
          subMenus: [
            { label: 'Suivi', link: 'tresorerie/correspondant/suivi', requiredRole: 'READ_OPERATION' },
            { label: 'Virements', link: 'tresorerie/correspondant/virement', requiredRole: 'READ_VIREMENT' },
            { label: 'Bénéficiaires', link: 'tresorerie/correspondant/beneficiary', requiredRole: 'READ_BENEFICIARY' }
          ]
        }
      ]
    },
    {
      label: 'Forex',
      icon: 'pi pi-wallet',
      requiredModule: ModulesEnum.FIXED_INCOME,
      subMenus: [
        { label: 'Trade', link: 'forex/trade' },
        { label: 'Contrepartie', link: 'forex/counterparties' },
      ]
    },
    {
      label: 'Fixed Income',
      icon: 'pi pi-chart-line',
      requiredModule: ModulesEnum.FIXED_INCOME,
      subMenus: [
        { label: 'Primaire', link: 'fixedincome/primaire' },
        { label: 'Secondaire', link: 'fixedincome/secondaire' },
        { label: 'Portefeuille', link: 'fixedincome/portefeuille' },
        { label: 'Opérations', link: 'fixedincome/operation' }
      ]
    },
    {
      label: 'Risques',
      icon: 'pi pi-exclamation-circle',
      requiredModule: ModulesEnum.RISK_MANAGEMENT,
      subMenus: [
        { label: 'Administration', link: 'risque-alm/parametrage' },
        { label: 'Operation', link: 'risque-alm/operations' },
        { label: 'Bilan de gestion', link: 'risque-alm/bilan' },
        { label: 'Impasse', link: 'risque-alm/impasse' },
        { label: 'Simulateur', link: 'risque-alm/simulations' }
      ]
    },
    /**
    {
      label: 'Back Office',
      icon: 'pi pi-inbox',
      link: 'back-office',
      requiredModule: ModulesEnum.BACK_OFFICE
    }
      **/
  ];

  adminMenus: MenuItem[] = [
    {
      label: 'Institutions',
      icon: 'pi pi-building',
      link: 'institution',
      requiredRole: PermissionsEnum.READ_INSTITUTION
    },
    {
      label: 'Utilisateurs',
      icon: 'pi pi-users',
      link: 'user',
      requiredRole: PermissionsEnum.READ_USER
    },
    {
      label: 'Groupes',
      icon: 'pi pi-th-large',
      link: 'group',
      requiredRole: PermissionsEnum.READ_GROUP
    },
    {
      label: 'Chaînes de validation',
      icon: 'pi pi-check-square',
      link: 'validation',
      requiredRole: PermissionsEnum.READ_VALIDATION
    },
    {
      label: 'Paramètres',
      icon: 'pi pi-cog',
      link: 'profile'
    }
  ];

  constructor(
    private router: Router,
    private tokenManager: StorageService,
    private accessService: AccessService
  ) {}

  ngOnInit() {
    const jwt = this.tokenManager.getToken();
    if (jwt) {
      this.username = jwt.sub || '';
      this.institution = jwt.institution || '';
    }
  }

  toggleSection(section: 'principal' | 'admin') {
    this.expandedSections[section] = !this.expandedSections[section];
  }

  checkAccess = (item: MenuItem) => this.accessService.checkAccess(item);

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh');
    this.router.navigateByUrl('/login').then();
  }
}
