import {IFlowLaw} from "./flow-law.model";

export interface IBalanceSheetCategory {
  id: string;
  institutionId: string;
  code: string;
  name: string;
  common: boolean;
  description?: string;
  parent?: IBalanceSheetCategory;
  type: string; //actif ou passif
  itemType: ItemType;
  source: ItemSource;
  activeLaw: IFlowLaw;
  isLeaf: boolean;
  canUpdate: boolean;
  canDelete: boolean;
}

export enum ItemType {
  CONVENTIONAL = "Conventionnel",
  CONTRACTUAL = "Contractuel"
}

export enum ItemSource {
  EXTERNAL = "Externe",
  INTERNAL = "Interne"
}

export enum BalanceSheetType {
  ASSET = "Actif",
  LIABILITY = "Passif"
}
