import {Component, OnInit} from '@angular/core';
import {DateRangeComponent} from "../../../../../shared/date-range/date-range.component";
import {DropdownModule} from "primeng/dropdown";
import {ExportComponent, ExportData} from "../../../../../shared/components/export.component";
import {ToastService} from "../../../../../core/services/toast.service";
import {ForecastCashFlowService} from "../../../../../core/services/forecast-cash-flow.service";
import {IForecastCashFlowOverview} from "../../../../../core/models/forecast-cash-flow.model";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {PrevOverviewTableComponent} from "./prev-overview-table/prev-overview-table.component";
import {PrevOverviewChartComponent} from "./prev-overview-chart/prev-overview-chart.component";
import {AccountBalanceService} from "../../../../../core/services/account.balance.service";
import {forkJoin} from "rxjs";
import {ToggleButtonModule} from "primeng/togglebutton";
import {NgIf} from "@angular/common";
import {getFilenameFromHeader} from "../../../../../core/utils/utils";
import {SelectButtonModule} from "primeng/selectbutton";
import {Router} from "@angular/router";
import {getUnpagedPageable} from "../../../../../core/models/page.model";
import {CurrencyService} from "../../../../../core/services/currency.service";
import {ICurrency} from "../../../../../core/models/devise.model";
import {ReserveService} from "../../../../../core/services/reserve.service";
import {IReserve} from "../../../../../core/models/reserve.model";

@Component({
  selector: 'app-overview',
  standalone: true,
  imports: [
    // Modules
    DropdownModule, FormsModule, ToggleButtonModule,
    // Components
    DateRangeComponent, ExportComponent, PrevOverviewTableComponent, PrevOverviewChartComponent,
    // Pipes & Directives
    NgIf, SelectButtonModule, ReactiveFormsModule
  ],
  templateUrl: './previsionnel-overview.component.html',
  styleUrl: './previsionnel-overview.component.scss'
})
export class PrevisionnelOverviewComponent implements OnInit {

  data: IForecastCashFlowOverview[] = [];
  minDate: Date = new Date();
  rangeDates: Date[] = [];
  timeGroupings = [
    { label: 'Quotidien', value: 'daily' },
    { label: 'Hebdomadaire', value: 'week' },
    { label: 'Bimensuel', value: 'biweekly' },
    { label: 'Mensuel', value: 'month' },
    { label: 'Trimestriel', value: 'quarter' }
  ];
  selectedTimeGrouping = 'daily';
  currentBalance: number = 0;
  activeViews: number[] = [1, 2];
  viewOptions: any[] = [
    { name: 'Tableau', icon: 'pi pi-table', value: 1 },
    { name: 'Graphes', icon: 'pi pi-chart-bar', value: 2 },
  ];
  isDataLoaded: boolean = false;
  isLocal: boolean = false;
  currencies: ICurrency[] = [];
  currency: string = 'XOF';
  reserves: IReserve[] = [];

  constructor(
    private readonly forecastService: ForecastCashFlowService,
    private readonly toastService: ToastService,
    private readonly accountBalanceService: AccountBalanceService,
    private readonly currencyService: CurrencyService,
    private readonly reserveService: ReserveService,
    private readonly router: Router
  ) {}

  ngOnInit() {
    const maxDate = new Date(this.minDate.getFullYear(), this.minDate.getMonth() + 1, this.minDate.getDate());
    this.rangeDates = [this.minDate, maxDate];
    if (this.router.url.includes('locale')) {
      this.isLocal = true;
      this.loadReserve();
    }
    else {
      this.loadCurrencies();
      this.currency = 'EUR';
    }
    this.loadInitialData();
  }

  loadInitialData() {
    forkJoin({
      overview: this.forecastService.fetchOverview(this.rangeDates[0], this.rangeDates[1], this.currency),
      balance: this.accountBalanceService.getLatestBalanceByCurrency(this.currency, new Date())
    }).subscribe({
      next: ({ overview, balance }) => {
        this.data = overview;
        this.currentBalance = balance ? balance.balance : 0;
        this.isDataLoaded = true;
      },
      error: () => this.toastService.showToast('Erreur de chargement', 'Impossible de charger les données initiales', 'error')
    });
  }

  loadReserve() {
    console.log('Loading reserves');
    this.reserveService.findForCurrentUser(getUnpagedPageable(), this.rangeDates[0], this.rangeDates[1]).subscribe({
      next: (response) => this.reserves = response.content,
      error: (err) => this.toastService.showToast('Chargement de la réserves', err.error, 'error')
    });
  }

  onDateRangeSelected(dateRange: Date[]) {
    if (dateRange.length == 2 && dateRange[0] && dateRange[1]) {
      this.rangeDates = dateRange;
      this.minDate = dateRange[0];
      this.loadOverview(dateRange[0], dateRange[1]);
    }
  }

  loadOverview(startDate: Date, endDate: Date) {
    this.forecastService.fetchOverview(startDate, endDate, this.currency).subscribe({
      next: (data) => this.data = data,
      error: (err) => this.toastService.showToast('Récapitulatif de prévisionnel', err.error, 'error')
    });
  }

  onInvalidRange(message: string) {
    this.toastService.showToast('Récapitulatif de prévisionnel', message, 'error');
  }

  loadCurrencies() {
    this.currencyService.getAllDevises(getUnpagedPageable()).subscribe({
      next: (page) => this.currencies = page.content,
      error: () => {
        const summary = 'Récupération des devises';
        const detail = 'Une erreur est survenue lors de la récupération des devises. Veuillez réessayer plus tard.';
        this.toastService.showToast(summary, detail, 'error');
      }
    });
  }

  exportOverview($event: ExportData) {
    if ($event.dates?.[0] == null || $event.dates?.[1] == null) this.toastService.showToast('Export du prévisionnel', 'Veuillez renseigner les dates de début et de fin.', 'error');
    this.forecastService.exportForecastCashFlowOverview($event.dates![0], $event.dates![1], this.currency)
      .subscribe({
        next: response => {
          const blob = response.body;
          const filename = getFilenameFromHeader(response);

          if (blob) {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = filename || `releve_previsionnel.xlsx`;
            link.click();
            window.URL.revokeObjectURL(url);
          } else {
            this.toastService.showToast('Export du récapitulatif de prévisionnel', 'Une erreur est survenue lors de l\'export.', 'error');
          }
        },
        error: err => this.toastService.showToast('Export du récapitulatif prévisionnel', err.error, 'error')
      });
  }

}
