import {Component, OnInit} from '@angular/core';
import { ReserveTableComponent } from './reserve-table.component';
import { ReserveChartComponent } from './reserve-chart.component';
import {PrimeTemplate} from "primeng/api";
import {SelectButtonModule} from "primeng/selectbutton";
import {FormsModule} from "@angular/forms";
import {NgIf} from "@angular/common";
import {Button} from "primeng/button";
import {ExportComponent, ExportData} from "../../../../../shared/components/export.component";
import {CustomDialogData} from "../../../../../core/models/custom-dialog.model";
import {ReserveDetailsComponent} from "./reserve-details/reserve-details.component";
import {customDynamicDialog, getFilenameFromHeader} from "../../../../../core/utils/utils";
import {take} from "rxjs";
import {DialogService} from "primeng/dynamicdialog";
import {ToastService} from "../../../../../core/services/toast.service";
import {ReserveService} from "../../../../../core/services/reserve.service";

@Component({
  selector: 'app-reserve-obligatoire',
  standalone: true,
  imports: [ReserveTableComponent, ReserveChartComponent, PrimeTemplate, SelectButtonModule, FormsModule, NgIf, Button, ExportComponent],
  template: `
    <div class="flex flex-column gap-4 mb-2">
      <div class="flex justify-content-between align-items-end">
        <div class="flex flex-column gap-4">
          <span class="font-bold text-2xl">Évolution des Réserves Obligatoires</span>
          <p-selectButton [multiple]="true" [options]="viewOptions" [(ngModel)]="activeViews" optionLabel="icon" optionValue="value">
            <ng-template let-item pTemplate>
              <div class="flex gap-2">
                <i [class]="item.icon"></i>
                <span>{{item.name}}</span>
              </div>
            </ng-template>
          </p-selectButton>
        </div>
        <div class="card flex gap-2 justify-content-center">
          <div class="flex gap-2">
            <p-button icon="pi pi-plus" label="Nouveau" (onClick)="createNewEntry()" />
            <export label="Opérations futures" [iconOnly]="false" (handler)="exportReserve($event)" />
          </div>
        </div>
      </div>
      <div class="w-full flex flex-column gap-8">
        <app-reserve-chart class="w-full" *ngIf="activeViews.includes(2)"></app-reserve-chart>
        <app-reserve-table *ngIf="activeViews.includes(1)"></app-reserve-table>
      </div>
    </div>
  `,
  styles: [`
    .divider {
      border-top: 1px solid var(--surface-border);
    }
  `]
})
export class ReserveObligatoireComponent implements OnInit {

  activeViews: number[] = [1, 2]
  viewOptions: any[] = [
    { name: 'Tableau', icon: 'pi pi-table', value: 1 },
    { name: 'Graphes', icon: 'pi pi-chart-bar', value: 2 },
  ];

  constructor(
    private readonly dialogService: DialogService,
    private readonly toastService: ToastService,
    private readonly reserveService: ReserveService
  ) {
  }

  ngOnInit() {
  }

  createNewEntry() {
    const data = { isCreate: true } as CustomDialogData;
    const ref = this.dialogService.open(ReserveDetailsComponent, customDynamicDialog('Nouvelle entrée de réserve obligatoire', data));
    ref.onClose.pipe(take(1)).subscribe(() => this.ngOnInit())
  }

  exportReserve($event: ExportData) {
    const summary = 'Export de l\'historique des réserves obligatoires';
    const detail = 'Veuillez renseigner les dates de début et de fin.';
    if ($event.dates?.[0] == null || $event.dates?.[1] == null) {
      this.toastService.showToast(summary, detail, 'error');
    }
    this.reserveService.exportReserve($event.dates![0], $event.dates![1], $event.extension === 'excel')
      .subscribe({
        next: response => {
          const blob = response.body;
          const filename = getFilenameFromHeader(response);
          if (blob) {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = filename || `releve_reserve_obligatoire.xlsx`;
            link.click();
            window.URL.revokeObjectURL(url);
          } else {
            this.toastService.showToast(summary, 'Une erreur est survenue lors de l\'export.', 'error');
          }
        },
        error: err => this.toastService.showToast(summary, err.error, 'error')
      });
  }
}
