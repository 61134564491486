<!-- risk-overview.component.html -->
<div class="flex flex-column gap-4">
  <div class="w-full flex justify-content-between border-bottom-1 border-gray-300 pb-2 mb-2">
    <span class="font-bold text-2xl text-black-alpha-70">Bilan de gestion</span>
  </div>

  <div class="w-full flex justify-content-between">
    <div class="w-full">
      <p-tabView [(activeIndex)]="activeTabIndex" (activeIndexChange)="onTabChange($event)">
        <p-tabPanel header="Global">
          <ng-container *ngTemplateOutlet="reportContent"></ng-container>
        </p-tabPanel>
        <p-tabPanel header="Devise locale">
          <ng-container *ngTemplateOutlet="reportContent"></ng-container>
        </p-tabPanel>
        <p-tabPanel header="Devise étrangère">
          <ng-container *ngTemplateOutlet="reportContent"></ng-container>
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>

  <div class="w-full">
    <ng-template #reportContent>
      <div class="w-full flex flex-column justify-content-between gap-4">
        <!-- Main Dashboard Cards -->
        <div class="w-full flex justify-content-between gap-2 h-20rem">
          <!-- Assets Card -->
          <div class="w-6 border-1 border-round border-black-alpha-10 p-4 h-full overflow-y-scroll">
            <div class="flex justify-content-between align-items-center mb-3">
              <div class="flex gap-2 align-items-center">
                <div class="px-1 border-2 bg-green-50 border-round border-green-500">
                  <i class="font-medium text-sm pi pi-angle-double-up text-green-800"></i>
                </div>
                <span class="text-xl font-bold text-green-800">Actif</span>
              </div>
              <span class="text-green-600 font-bold">
              {{ getCurrentAssetBalance() | currency : selectedCurrency : 'symbol' : '1.0-0':'fr' }}
            </span>
            </div>

            <!-- Assets Categories Table -->
            <div class="">
              <div class="flex flex-column">
                <ng-container *ngFor="let asset of assets">
                  <div (click)="getCurrentCategoryBalance(asset) > 0 ? openCategoryDetail(asset) : null;" class="flex justify-content-between align-items-center px-2 py-1 border-round-lg hover:surface-50 cursor-pointer">
                    <div class="flex flex-column align-items-start gap-1">
                      <!-- <i class="pi pi-chart-line text-green-600"></i> -->
                      <span class="font-semibold text-black-alpha-70">{{ asset.category.name }}</span>
                      <span class="font-semibold text-sm text-green-400">{{ getCurrentPercentage(asset) }}%</span>
                    </div>
                    <div class="flex flex-column align-items-end gap-1">
                      <i class="pi pi-eye text-black-alpha-20 text-sm font-semibold hover:text-primary-900"></i>
                      <span class="text-sm font-semibold">{{ getCurrentCategoryBalance(asset) | currency : selectedCurrency : 'symbol' : '1.0-0':'fr' }}</span>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>

          <!-- Liabilities Card -->
          <div class="w-6 border-1 border-round border-black-alpha-10 p-4 h-full overflow-y-scroll">
            <div class="flex justify-content-between align-items-center mb-3">
              <div class="flex gap-2 align-items-center">
                <div class="px-1 border-2 bg-red-50 border-round border-red-500">
                  <i class="font-medium pi pi-angle-double-down text-red-600"></i>
                </div>
                <span class="text-red-600 font-bold text-xl font-bold">Passif</span>
              </div>
              <span class="text-red-600 font-bold">
              {{ getCurrentLiabilityBalance() | currency : selectedCurrency : 'symbol' : '1.0-0':'fr' }}
            </span>
            </div>

            <!-- Liabilities Categories Table -->
            <div class="">
              <div class="flex flex-column">
                <ng-container *ngFor="let liability of liabilities">
                  <div (click)="getCurrentCategoryBalance(liability) > 0 ? openCategoryDetail(liability) : null;" class="flex justify-content-between align-items-center px-2 py-1 border-round-lg hover:surface-50 cursor-pointer">
                    <div class="flex flex-column align-items-start gap-1">
                      <span class="font-semibold text-black-alpha-70">{{ liability.category.name }}</span>
                      <span class="font-semibold text-sm text-red-400">{{ getCurrentPercentage(liability) }}%</span>
                    </div>
                    <div class="flex flex-column align-items-end">
                      <i class="pi pi-eye text-black-alpha-20 text-sm font-semibold hover:text-primary-900"></i>
                      <span class="text-sm font-semibold">{{ getCurrentCategoryBalance(liability) | currency : selectedCurrency : 'symbol' : '1.0-0':'fr' }}</span>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>

        <!-- Liquidity Gap Card -->
        <div class="border-1 border-round border-blue-300 px-4 py-2 bg-blue-50">
          <div class="flex justify-content-between align-items-center">
            <div class="flex gap-2 align-items-center">
              <i class="pi pi-info-circle text-blue-600"></i>
              <span class="text-xl font-semibold">Gap de liquidité</span>
            </div>
            <span class="text-blue-600 text-xl font-semibold">
            {{ getNetPosition(data[data.length - 1]) | currency : selectedCurrency : 'symbol' : '1.0-0':'fr' }}
          </span>
          </div>
        </div>

        <div class="w-full flex justify-content-between">
          <date-range class="w-2" [rangeDates]="rangeDates" (dateRangeSelected)="onDateRangeSelected($event)" (invalidRange)="onInvalidRange($event)"/>
          <p-selectButton
            [options]="frequencyOptions"
            [(ngModel)]="selectedFrequency"
            (onChange)="onFrequencyChange($event.value)"
            styleClass="text-xs p-button-sm">
          </p-selectButton>
        </div>
        <!-- Time Series Chart -->
        <div class="w-full flex gap-2">
          <report-balance-chart [barThickness]="barThickness" class="w-full" [reports]="data" [assets]="assets" [liabilities]="liabilities" />
        </div>

        <div class="w-full">
          <balance-tree-table [reports]="data"></balance-tree-table>
        </div>
      </div>
    </ng-template>
  </div>
</div>
