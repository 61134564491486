import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { getDefaultPageable, getPageableAsParams, Page, Pageable } from '../models/page.model';
import {BalanceProjection, IItemValueHistory} from '../models/item-value.model';
import {Frequency} from "../models/daily-balance.model";

@Injectable({
  providedIn: 'root'
})
export class ItemValueHistoryService {
  private readonly apiUrl = 'risque/v1/item-value-history';

  constructor(private readonly http: HttpClient) {}

  /**
   * Get complete history for an item
   * @param itemId Item ID
   */
  getItemHistory(itemId: string): Observable<IItemValueHistory[]> {
    return this.http.get<IItemValueHistory[]>(`${this.apiUrl}/${itemId}`);
  }

  /**
   * Get history between two dates with pagination
   * @param itemId Item ID
   * @param startDate Start date
   * @param endDate End date
   * @param pageable Pagination information
   */
  getHistoryBetween(
    itemId: string,
    startDate: Date,
    endDate: Date,
    pageable: Pageable = getDefaultPageable()
  ): Observable<Page<IItemValueHistory>> {
    const params = getPageableAsParams(pageable)
      .append('startDate', this.formatDate(startDate))
      .append('endDate', this.formatDate(endDate));

    return this.http.get<Page<IItemValueHistory>>(`${this.apiUrl}/${itemId}/between`, { params });
  }

  /**
   * Get history grouped by frequency
   * @param frequency Frequency to group by
   */
  getHistoryByFrequency(frequency: Frequency): Observable<BalanceProjection[]> {
    const params = new HttpParams().append('frequency', frequency);

    return this.http.get<BalanceProjection[]>(`${this.apiUrl}/frequency`, { params });
  }

  /**
   * Get latest history entry for an item
   * @param itemId Item ID
   */
  getLatestHistory(itemId: string): Observable<IItemValueHistory> {
    return this.http.get<IItemValueHistory>(`${this.apiUrl}/${itemId}/latest`);
  }

  /**
   * Generate history for an item
   * @param itemId Item ID
   */
  generateHistory(itemId: string): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/${itemId}/generate`, {});
  }

  // Analysis helpers

  /**
   * Calculate the change between two values
   * @param current Current value
   * @param previous Previous value
   */
  calculateValueChange(current: IItemValueHistory, previous: IItemValueHistory): number {
    if (!current || !previous) return 0;
    return current.value - previous.value;
  }

  /**
   * Calculate percentage change between two values
   * @param current Current value
   * @param previous Previous value
   */
  calculatePercentageChange(current: IItemValueHistory, previous: IItemValueHistory): number {
    if (!current || !previous || previous.value === 0) return 0;
    return ((current.value - previous.value) / Math.abs(previous.value)) * 100;
  }

  /**
   * Calculate the average value over a set of history entries
   * @param history Array of history entries
   */
  calculateAverageValue(history: IItemValueHistory[]): number {
    if (!history || history.length === 0) return 0;
    const sum = history.reduce((acc, curr) => acc + curr.value, 0);
    return sum / history.length;
  }

  /**
   * Format a date to YYYY-MM-DD string
   * @param date Date to format
   * @private
   */
  private formatDate(date: Date): string {
    return date.toISOString().split('T')[0];
  }
}
