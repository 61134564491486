import {Component, OnInit} from '@angular/core';
import {TabMenuModule} from "primeng/tabmenu";
import {ConfirmationService, MenuItem} from "primeng/api";
import {ToolbarComponent} from "../../../shared/components/toolbar/toolbar.component";
import {DynamicTableComponent} from "../../../shared/components/dynamic-table/dynamic-table.component";
import {CustomAction, CustomTableHeader} from "../../../core/models/custom-table.model";
import {OperationLoadedBilanModel} from "../../../core/models/risque/operation-loaded-bilan.model";
import {DialogService, DynamicDialogConfig} from "primeng/dynamicdialog";
import {ToastService} from "../../../core/services/toast.service";
import {DEFAULT_PAGE_PARAMS, getDefaultPageable, PageParams} from "../../../core/models/page.model";
import {
  UploadOperationComponent
} from "../administration/dialogs/upload-file/upload-operation/upload-operation.component";
import {customDynamicDialog} from "../../../core/utils/utils";
import {
  OperationLoadedBilanDetailsComponent
} from "./operation-loaded-bilan-details/operation-loaded-bilan-details.component";
import {BalanceSheetItemService} from "../../../core/services/balance-sheet-item.service";
import {IBalanceSheetItem, ItemSearchCriteria} from "../../../core/models/balance-sheet-item.model";

@Component({
  selector: 'app-operations-risque',
  standalone: true,
  imports: [
    TabMenuModule,
    ToolbarComponent,
    DynamicTableComponent
  ],
  templateUrl: './operations-risque.component.html',
  styleUrl: './operations-risque.component.scss'
})
export class OperationsRisqueComponent implements OnInit{

  items: MenuItem[] | undefined;
  pageTitle = 'Opérations';
  data: IBalanceSheetItem[] = [];
  pageParams: PageParams = DEFAULT_PAGE_PARAMS;
  pageable: PageParams = {page: 0, first: 0, rows: 0, totalRecords: 0, rowPerPageOptions: []};

  actions: CustomAction[] = [
    {
      role: 'READ_BALANCE_SHEET_CATEGORY',
      icon: 'pi pi-info-circle',
      label: 'Consulter',
      severity: 'info',
      emit: (operationLoadedBilanModel: OperationLoadedBilanModel) => this.openOperationLoadedBilanDetail(operationLoadedBilanModel, true),
      visible: true
    },
    {
      role: 'DELETE_REGULATION_LAW',
      icon: 'pi pi-trash',
      label: 'Supprimer',
      severity: 'danger',
      emit: (item: OperationLoadedBilanModel) => this.delete(item),
      visible: true
    }
  ];
  columns: CustomTableHeader[] = [
    {
      column: 'Catégorie',
      key: 'category.name',
      type: 'text',
      sortable: true,
      filterable: false
    },
    {
      column: 'Date de valeur',
      key: 'valueDate',
      type: 'date',
      sortable: true,
      filterable: false
    },
    {
      column: 'Date de maturité',
      key: 'maturityDate',
      type: 'date',
      sortable: true,
      filterable: false
    },
    {
      column: 'Type de poste',
      key: 'category.itemType',
      type: 'text',
      sortable: true,
      filterable: false
    },
    {
      column: 'Montant',
      key: 'amount',
      type: 'number',
      sortable: true,
      filterable: false
    },
    {
      column: 'Devise',
      key: 'currency',
      type: 'text',
      sortable: true,
      filterable: false
    },
    {
      column: 'Taux',
      key: 'rate',
      type: 'number',
      sortable: true,
      filterable: false
    }
  ];
  toolbarActions: CustomAction[] = [
    {
      role: 'CREATE_REGULATION_LAW',
      icon: 'pi pi-download',
      label: 'Charger',
      severity: 'secondary',
      emit: () => this.upload(),
      visible: true
    }
  ];

  constructor(
    private service: BalanceSheetItemService,
    private dialogService: DialogService,
    private confirmationService: ConfirmationService,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    this.loadOperation();
  }

  search($event: string) {
    console.log('Searched: ', $event);
  }

  private loadOperation() {
    this.service.searchItems({ uploaded: true } as ItemSearchCriteria, getDefaultPageable()).subscribe({
      next: (page) => {
        this.data = page.content;
        this.pageParams = {
          ...this.pageParams,
          page: page.number,
          rows: page.size,
          first: page.number * page.size,
          totalRecords: page.totalElements,
        };
      },
      error: (err) => this.toastService.showToast('Chargement des opérations', err.error, 'error')
    });
  }


  private delete(item: OperationLoadedBilanModel): void {
    this.confirmationService.confirm({
      message: `Êtes-vous sûr de vouloir supprimer cette opération ?`,
      acceptLabel: 'Oui',
      rejectLabel: 'Non',
      accept: () => {
        this.service.deleteItem(item.id!.toString()).subscribe({
          next: () => {
            this.toastService.showToast('Succès', 'Opération supprimé avec succès.', 'success');
            this.loadOperation();
          },
          error: (err) => {
            this.toastService.showToast('Erreur', 'Échec de la suppression de cette operation.', 'error');
            console.error('Erreur lors de la suppression :', err);
          }
        });
      }
    });
  }

  upload() {
    const ref = this.dialogService.open(UploadOperationComponent, {
      header: 'Chargement de Fichier',
      width: 'auto',
    });
    ref.onClose.subscribe(() => this.loadOperation());
  }

  private openOperationLoadedBilanDetail(operationLoadedBilanModel: OperationLoadedBilanModel, isView: boolean = false) {
    const header =  "Détails de l\'opération" ;
    const config: DynamicDialogConfig = customDynamicDialog(header, { isView, isEdit: !isView, data: operationLoadedBilanModel });
    const ref = this.dialogService.open(OperationLoadedBilanDetailsComponent, config);
    ref.onClose.subscribe(() => this.loadOperation());
  }
}
