import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {getPageableAsParams, Page, Pageable} from "../models/page.model";
import {ICategoryCalculation} from '../models/category-calculation.model';
import {ISimulation, ISimulationResult, ISimulationWithResult} from '../models/simulation.model';
import {BulkSubmissionModel} from '../models/details-submissionn.model';
import {ITitreWithEmission} from '../models/risque/simulation/risque-emission_model';


@Injectable({
  providedIn: 'root'
})
export class SimulationService {

  private readonly API_URL = `risque/v1`;
  private readonly SIMULATION_BASE_URL = this.API_URL + '/simulation'

  constructor(private readonly http: HttpClient) {
  }

  getEmissionsByInstrumentType(pageable: Pageable, instrumentType: string): Observable<Page<ITitreWithEmission>> {
    let params = getPageableAsParams(pageable).set('typeInstrument', instrumentType);
    return this.http.get<Page<ITitreWithEmission>>(`${this.SIMULATION_BASE_URL}/emissions/titres/instrument`, { params });
  }

  createSimulation(simulationWithResult: ISimulationWithResult) {
    return this.http.post<ISimulationWithResult>(`${this.SIMULATION_BASE_URL}/create`, simulationWithResult);
  }

  getSimulations(pageable: Pageable, filterParam?: string): Observable<Page<ISimulation>> {
    let params = getPageableAsParams(pageable);
    return this.http.get<Page<ISimulation>>(`${this.SIMULATION_BASE_URL}/filterParam/${filterParam}`, { params });
  }

  getSimulationResult(simulationId: string): Observable<ISimulationResult> {
    return this.http.get<ISimulationResult>(`${this.SIMULATION_BASE_URL}/${simulationId}/result`);
  }

  getSimulationResultsInBulk(pageable: Pageable, simulationIds: string[]): Observable<Page<ISimulationResult>> {
    let params = getPageableAsParams(pageable);
    simulationIds.forEach(id => {
      params = params.append('simulationIds', id);
    });
    return this.http.get<Page<ISimulationResult>>(`${this.SIMULATION_BASE_URL}/result/bulk`, { params });
  }

  cancelSimulation(simulationToCancelIDs: string[]) {
    return this.http.put<ISimulation[]>(`${this.SIMULATION_BASE_URL}/cancel`, simulationToCancelIDs);
  }

  createSubmissionFromSimulation(submissions: BulkSubmissionModel[]) {
    return this.http.post<void>(`${this.SIMULATION_BASE_URL}/new-submissions`, submissions);
  }

  deleteSimulations(simulationToCancelIDs: string[]) {
    const params = new HttpParams().set('ids', simulationToCancelIDs.join(','));
    return this.http.delete(`${this.SIMULATION_BASE_URL}/delete`, { params });
  }

  getResultsForSuiviALM(pageable: Pageable, year: number): Observable<ICategoryCalculation[]> {
    let params = getPageableAsParams(pageable);

    return this.http.get<ICategoryCalculation[]>(`${this.API_URL}/suivi-alm/year/${year}`, { params });
  }


}

