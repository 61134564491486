<div class="flex flex-column h-full">
  <form [formGroup]="formGroup" class="flex flex-column gap-3 h-full">
    <div class="flex flex-column gap-2">
      <label>Modules</label>
      <p-dropdown formControlName="sourceType"
                [options]="sourceTypeOptions"
                placeholder="Sélectionner une source"
                [disabled]="data?.isView ?? false">
      </p-dropdown>
    </div>

    <div class="flex flex-column gap-2">
    <label>Catégorie cible</label>
    <p-dropdown formControlName="targetCategoryId" [options]="categories" optionLabel="name"
                optionValue="id" placeholder="Sélectionner une catégorie" [disabled]="data?.isView ?? false"
                [filter]="true">
    </p-dropdown>
  </div>

    <div class="flex flex-column gap-2">
    <label>Nom</label>
    <input pInputText formControlName="name" [disabled]="data?.isView ?? false"/>
  </div>

    <div class="flex flex-column gap-2">
      <label>Description</label>
      <textarea pInputTextarea formControlName="description" [disabled]="data?.isView ?? false" rows="3"></textarea>
    </div>

    <div class="flex flex-column gap-2">
      <label>Priorité</label>
      <p-inputNumber formControlName="priority" showButtons="true" [min]="1" [disabled]="data?.isView ?? false" />
    </div>

    <div class="flex align-items-center gap-2">
      <p-checkbox formControlName="active" [binary]="true" [disabled]="data?.isView ?? false"></p-checkbox>
      <label>Actif</label>
    </div>

    <div class="flex justify-content-between align-items-center">
    <label class="font-bold">Conditions</label>
    <button *ngIf="!data?.isView" pButton label="Ajouter condition" icon="pi pi-plus"
            (click)="addCondition()" class="p-button-sm">
    </button>
  </div>

    <div formArrayName="conditions" class="flex flex-column gap-1 w-full">
      <div *ngFor="let condition of conditions.controls; let i=index" [formGroupName]="i"
           class="flex gap-1 align-items-start py-2 surface-ground border-round w-full">
        <p-dropdown formControlName="type"
                    [options]="conditionTypeOptions"
                    placeholder="Type"
                    [disabled]="data?.isView ?? false"
                    (onChange)="onConditionTypeChange(i)"
                    class="w-3">
        </p-dropdown>

        <p-dropdown formControlName="operator"
                    [options]="getOperatorOptions(condition.get('type')?.value)"
                    placeholder="Opérateur"
                    [disabled]="data?.isView ?? false"
                    class="w-3">
        </p-dropdown>

        <div class="w-full">
          <div [ngSwitch]="condition.get('type')?.value">
            <ng-container *ngSwitchCase="ConditionType.AMOUNT">
              <p-inputNumber formControlName="value"
                             mode="currency"
                             currency="XOF"
                             [disabled]="data?.isView ?? false"
                             class="w-full">
              </p-inputNumber>
            </ng-container>

            <ng-container *ngSwitchCase="ConditionType.RATE">
              <p-inputNumber formControlName="value"
                             mode="decimal"
                             [minFractionDigits]="2"
                             [maxFractionDigits]="2"
                             suffix="%"
                             [disabled]="data?.isView ?? false"
                             class="w-full">
              </p-inputNumber>
            </ng-container>

            <ng-container *ngSwitchCase="ConditionType.DURATION">
              <p-inputNumber formControlName="value"
                             [disabled]="data?.isView ?? false"
                             suffix=" mois"
                             class="w-full">
              </p-inputNumber>
            </ng-container>

            <ng-container *ngSwitchDefault>
              <input pInputText formControlName="value"
                     [disabled]="data?.isView ?? false"
                     class="w-full"/>
            </ng-container>
          </div>
        </div>

        <button *ngIf="!data?.isView"
                pButton
                icon="pi pi-trash"
                class="p-button-danger p-button-sm"
                (click)="removeCondition(i)">
        </button>
      </div>
    </div>

    <div class="flex flex-grow-1 gap-2 mt-4 align-items-end justify-content-end">
      <button *ngIf="!data?.isView"
              pButton
              label="Annuler"
              (click)="close()"
              class="p-button-secondary">
      </button>
      <button *ngIf="data?.isCreate"
              pButton
              label="Enregistrer"
              (click)="save()"
              [disabled]="!formGroup.valid || !conditions.length">
      </button>
      <button *ngIf="data?.isEdit"
              pButton
              label="Modifier"
              (click)="update()"
              [disabled]="!formGroup.valid || !conditions.length">
      </button>
    </div>
  </form>
</div>
