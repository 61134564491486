// risk-overview.component.ts
import {Component, OnInit} from '@angular/core';
import {DailyBalanceReportService} from "../../../core/services/daily-balance-report.service";
import {Frequency, ICategoryBalance, IDailyBalanceReport, ReportType} from "../../../core/models/daily-balance.model";
import {CurrencyPipe, DatePipe, NgClass, NgForOf, NgTemplateOutlet} from "@angular/common";
import {BalanceSheetType} from "../../../core/models/balance-sheet.model";
import {ChartModule} from "primeng/chart";
import {TableModule} from 'primeng/table';
import {DateRangeComponent} from "../../../shared/date-range/date-range.component";
import {ToastService} from "../../../core/services/toast.service";
import {DropdownModule} from "primeng/dropdown";
import {FormsModule} from "@angular/forms";
import {Button} from "primeng/button";
import {BalanceTreeTableComponent} from "./report-tree-table.component";
import {DialogService} from "primeng/dynamicdialog";
import {CategoryOverviewComponent} from "./category-overview/category-overview.component";
import {CustomDialogData} from "../../../core/models/custom-dialog.model";
import {customDynamicDialog} from "../../../core/utils/utils";
import {ReportBalanceChartComponent} from "./report-chart.component";
import {TabViewModule} from "primeng/tabview";
import {SelectButtonModule} from "primeng/selectbutton";

@Component({
  selector: 'app-overview',
  standalone: true,
  imports: [
    // Modules
    ChartModule, TableModule, DropdownModule, FormsModule, TabViewModule, SelectButtonModule,
    // Components
    DateRangeComponent, BalanceTreeTableComponent, ReportBalanceChartComponent,
    // Pipes & Directives
    CurrencyPipe, DatePipe, NgClass, NgForOf, Button, NgTemplateOutlet
  ],
  templateUrl: './risk-overview.component.html',
})
export class RiskOverviewComponent implements OnInit {

  data: IDailyBalanceReport[] = [];
  assets: ICategoryBalance[] = [];
  liabilities: ICategoryBalance[] = [];
  rangeDates: Date[] | null = null;
  frequencyOptions = [
    { label: '1D', value: Frequency.DAILY },
    { label: '1W', value: Frequency.WEEKLY },
    { label: '1M', value: Frequency.MONTHLY },
    { label: '3M', value: Frequency.QUARTERLY },
    { label: '1Y', value: Frequency.ANNUALLY }
  ];
  selectedFrequency: Frequency = Frequency.QUARTERLY;
  selectedCurrency: string = 'XOF';
  activeTabIndex: number = 0;  // Default to Global view
  barThickness: number = 150;

  // Map to convert tab index to report configuration
  private tabConfigs = [
    { type: ReportType.GLOBAL, currency: 'XOF' },  // Global
    { type: ReportType.CURRENCY, currency: 'XOF' }, // XOF
    { type: ReportType.CURRENCY, currency: 'EUR' }  // EUR
  ];

  constructor(
    private readonly reportService: DailyBalanceReportService,
    private readonly toastService: ToastService,
    private readonly dialogService: DialogService,
  )
  {
  }

  ngOnInit() {
    const today = new Date();
    const oneYearAgo = new Date();
    oneYearAgo.setMonth(today.getMonth() - 12);
    this.rangeDates = [oneYearAgo, today];

    this.loadData();
  }

  onTabChange(index: number) {
    this.activeTabIndex = index;
    if (this.rangeDates && this.rangeDates.length === 2) {
      this.selectedCurrency = this.tabConfigs[index].currency;
      this.loadData(this.tabConfigs[index].currency, this.tabConfigs[index].type);
    }
  }

  private loadData(currency: string = 'XOF', type: ReportType = this.tabConfigs[this.activeTabIndex].type) {
    this.reportService.getBalancesByFrequency(
      this.selectedFrequency,
      this.rangeDates![0],
      this.rangeDates![1],
      currency,
      type
    ).subscribe({
      next: (resp) => {
        this.data = resp;
        if (this.data.length > 0) {
          // Get all unique categories from all reports
          const allCategories = this.data.reduce((acc, report) => {
            report.rootCategoryBalances.forEach(category => {
              const existingCategory = acc.find(c => c.category.id === category.category.id);
              if (!existingCategory) {
                acc.push(category);
              }
            });
            return acc;
          }, [] as ICategoryBalance[]);

          // Separate into assets and liabilities
          this.assets = allCategories.filter(cat => cat.category.type === BalanceSheetType.ASSET);
          this.liabilities = allCategories.filter(cat => cat.category.type === BalanceSheetType.LIABILITY);
        }
      },
      error: (error) => this.toastService.showToast('Erreur', error.error, 'error')
    });
  }

  onDateRangeSelected(dateRange: Date[]) {
    if (dateRange && dateRange.length === 2) {
      this.rangeDates = dateRange;
      this.loadData();
    }
  }

  onFrequencyChange(value: Frequency) {
    this.selectedFrequency = value;
    if (this.rangeDates && this.rangeDates.length === 2) {
      this.loadData(this.tabConfigs[this.activeTabIndex].currency, this.tabConfigs[this.activeTabIndex].type);
    }
    switch (value) {
      case Frequency.DAILY:
        this.barThickness = 10;
        break;
      case Frequency.WEEKLY:
        this.barThickness = 25;
        break;
      case Frequency.MONTHLY:
        this.barThickness = 50;
        break;
      case Frequency.QUARTERLY:
        this.barThickness = 150;
        break;
      case Frequency.ANNUALLY:
        this.barThickness = 250;
        break;
    }
  }

  getNetPosition(report: IDailyBalanceReport) {
    return this.reportService.calculateNetPosition(report);
  }

  onInvalidRange(message: string) {
    this.toastService.showToast('Période de séléction', message, 'error');
  }

  getCurrentAssetBalance(): number {
    return this.data[(this.data.length - 1)]?.assetBalance ?? 0;
  }

  getCurrentLiabilityBalance(): number {
    return this.data[(this.data.length - 1)]?.liabilityBalance ?? 0;
  }

  getCurrentCategoryBalance(category: ICategoryBalance) {
    return this.data[(this.data.length-1)].rootCategoryBalances.find(c => c.category.id == category.category.id)?.balance ?? 0;
  }

  getCurrentPercentage(category: ICategoryBalance) {
    return this.data[(this.data.length-1)].rootCategoryBalances.find(c => c.category.id == category.category.id)?.percentageInParent ?? 0;
  }

  openCategoryDetail(asset: ICategoryBalance) {
    const options = { position: 'center', width: '75vw', height: '75vh' };
    const reports = this.data.map(r => {
      r.rootCategoryBalances = r.rootCategoryBalances.filter(c => c.category.id == asset.category.id);
      return r;
    });
    const data = { data: reports } as CustomDialogData;
    this.dialogService.open(
      CategoryOverviewComponent,
      customDynamicDialog(`Bilan de gestion - ${asset.category.name}`, data, options)
    ).onClose.subscribe(() => this.ngOnInit());
  }
}
