import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ToastModule} from "primeng/toast";
import {StorageService} from "./core/services/storage.service";
import {Subscription} from "rxjs";
import {LoaderService} from "./core/services/loader.service";
import {NgxSpinnerComponent, NgxSpinnerService} from "ngx-spinner";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, ToastModule, ConfirmDialogModule, NgxSpinnerComponent, NgIf],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit, OnDestroy {

  private loadingSubscription: Subscription = new Subscription();

  constructor(
    private readonly storageService: StorageService,
    private readonly loaderService: LoaderService,
    private readonly spinner: NgxSpinnerService,
  ) {
  }

  ngOnInit() {
    this.loadingSubscription = this.loaderService.loading$.subscribe((loading) => {
      loading ? this.spinner.show() : this.spinner.hide();
    });
  }

  ngOnDestroy() {
    if (this.loadingSubscription) this.loadingSubscription.unsubscribe();
    this.storageService.clearStorage()
  }
}
