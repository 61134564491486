// search-counterparty.component.ts
import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule} from '@angular/forms';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import {InputTextModule} from "primeng/inputtext";
import {DropdownModule} from "primeng/dropdown";
import {ButtonModule} from "primeng/button";

@Component({
  selector: 'app-search-counterparty',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    InputTextModule,
    DropdownModule,
    ButtonModule
  ],
  template: `
        <div class="flex flex-column gap-4 p-4">
            <form [formGroup]="searchForm" class="flex flex-column gap-4">
                <div class="flex flex-column gap-2">
                    <label for="name" class="font-medium">Nom</label>
                    <input pInputText id="name" formControlName="name" />
                </div>

                <div class="flex flex-column gap-2">
                    <label for="type" class="font-medium">Type</label>
                    <p-dropdown id="type" [options]="counterpartyTypes" formControlName="type"
                              placeholder="Sélectionner le type" optionLabel="label" optionValue="value">
                    </p-dropdown>
                </div>

                <div class="flex flex-column gap-2">
                    <label for="bicCode" class="font-medium">Code BIC</label>
                    <input pInputText id="bicCode" formControlName="bicCode" />
                </div>

                <div class="flex flex-column gap-2">
                    <label for="swiftCode" class="font-medium">Code SWIFT</label>
                    <input pInputText id="swiftCode" formControlName="swiftCode" />
                </div>

                <div class="flex justify-content-end gap-2">
                    <p-button label="Réinitialiser" severity="secondary" (onClick)="reset()"></p-button>
                    <p-button label="Rechercher" severity="primary" (onClick)="search()"></p-button>
                </div>
            </form>
        </div>
    `
})
export class SearchCounterpartyComponent implements OnInit {
  searchForm: FormGroup;
  counterpartyTypes = [
    { label: 'Banque', value: 'BANK' },
    { label: 'Courtier', value: 'BROKER' },
    { label: 'Entreprise', value: 'CORPORATE' },
    { label: 'Individu', value: 'INDIVIDUAL' }
  ];

  constructor(
    private fb: FormBuilder,
    private ref: DynamicDialogRef
  ) {
    this.searchForm = this.fb.group({
      name: [''],
      type: [''],
      bicCode: [''],
      swiftCode: ['']
    });
  }

  ngOnInit(): void {}

  search(): void {
    const criteria = this.searchForm.value;
    // Remove empty values
    Object.keys(criteria).forEach(key => !criteria[key] && delete criteria[key]);
    this.ref.close(criteria);
  }

  reset(): void {
    this.searchForm.reset();
  }
}
