import {Component, OnInit} from '@angular/core';
import {MenuItem} from "primeng/api";
import {TabMenuModule} from "primeng/tabmenu";

@Component({
  selector: 'app-administration-risque',
  standalone: true,
  imports: [ TabMenuModule ],
  templateUrl: './administration-risque.component.html',
  styleUrl: './administration-risque.component.scss'
})
export class AdministrationRisqueComponent implements OnInit {

  items: MenuItem[] | undefined;

  constructor() {}

  ngOnInit() {
    this.items = [
      { label: 'Résumé', routerLink: 'resume' },
      { label: 'Catégories', routerLink: 'categories' },
      { label: 'Lois', routerLink: 'lois' },
      { label: 'Règles', routerLink: 'règles' }
    ];
  }

}
