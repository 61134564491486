import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {ILoanBorrowing, IRepaymentFrequency, LoanBorrowingType} from "../../../../../core/models/loan-borrowing.model";
import {IRepaymentEntry} from "../../../../../core/models/repayment-entry.model";
import {LoanBorrowingService} from "../../../../../core/services/loan-borrowing.service";
import {RepaymentService} from "../../../../../core/services/repayment-entry.service";
import {Button} from "primeng/button";
import {InputTextModule} from "primeng/inputtext";
import {ICurrency} from "../../../../../core/models/devise.model";
import {getUnpagedPageable} from "../../../../../core/models/page.model";
import {CurrencyService} from "../../../../../core/services/currency.service";
import {ToastService} from "../../../../../core/services/toast.service";
import {CalendarModule} from "primeng/calendar";
import {DropdownModule} from "primeng/dropdown";
import {InputNumberModule} from "primeng/inputnumber";
import {DialogModule} from "primeng/dialog";
import {RepaymentListComponent} from "../../../../../shared/repayment-list/repayment-list.component";

@Component({
  selector: 'app-loan-borrowing-details',
  standalone: true,
  imports: [
    // Modules
    CommonModule, ReactiveFormsModule, InputTextModule, CalendarModule, DropdownModule, InputNumberModule, DialogModule,
    // Components
    RepaymentListComponent,
    // Components
    Button
  ],
  templateUrl: './loan-borrowing-details.component.html',
  styleUrls: ['./loan-borrowing-details.component.scss']
})
export class LoanBorrowingDetailsComponent implements OnInit {

  @Input() repaymentTypes: LoanBorrowingType[] = [LoanBorrowingType.LOAN, LoanBorrowingType.BORROWING];
  loanBorrowingForm: FormGroup;
  loanBorrowing: ILoanBorrowing = {} as ILoanBorrowing;
  repaymentEntries: IRepaymentEntry[] = [];
  isCreate: boolean = false;
  currencies: ICurrency[] = [];
  today: Date = new Date();
  frequencies: IRepaymentFrequency[] = [];
  dialogVisible: boolean = false;
  counterPartyTypes: string[] = [];
  formEnabled: boolean = false;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly fb: FormBuilder,
    private readonly loanBorrowingService: LoanBorrowingService,
    private readonly repaymentService: RepaymentService,
    private readonly currencyService: CurrencyService,
    private readonly toastService: ToastService
  ) {
    this.loanBorrowingForm = this.fb.group({
      libelle: new FormControl({value: '', disabled: true}, Validators.required),
      amount: new FormControl('', [Validators.required, Validators.min(0)]),
      interestRate: new FormControl('', [Validators.required, Validators.min(0), Validators.max(100)]),
      valueDate: new FormControl('', Validators.required),
      maturityDate: new FormControl('', Validators.required),
      firstPaymentDate: new FormControl('', Validators.required),
      frequency: new FormControl('', Validators.required),
      type: new FormControl('', Validators.required),
      currency: new FormControl('', Validators.required),
      counterparty: new FormControl('', Validators.required),
      counterpartyType: new FormControl('', Validators.required),
      description: new FormControl('')
    });

    // Listen for changes in type, counterparty, and currency
    this.loanBorrowingForm.get('type')?.valueChanges.subscribe(() => this.updateLibelle());
    this.loanBorrowingForm.get('counterparty')?.valueChanges.subscribe(() => this.updateLibelle());
    this.loanBorrowingForm.get('currency')?.valueChanges.subscribe(() => this.updateLibelle());
  }

  ngOnInit() {
    this.loadCounterPartyTypes();
    this.route.queryParams.subscribe(params => {
      if (params['types']) this.repaymentTypes = params['types'].split(',') as LoanBorrowingType[];
      else this.repaymentTypes = [LoanBorrowingType.LOAN, LoanBorrowingType.BORROWING];

      // If there's only one type, set it as the default in the form
      if (this.repaymentTypes.length === 1) this.loanBorrowingForm.patchValue({ type: this.repaymentTypes[0] });
    });

    this.loadCurrencies();
    this.loadRepaymentFrequencies();
    const id = this.route.snapshot.paramMap.get('id');
    this.isCreate = !id;

    if (this.isCreate) {
      this.formEnabled = true;
      const repaymentTypeParam = this.route.snapshot.paramMap.get('repaymentType');
      if (repaymentTypeParam) {
        this.repaymentTypes = [repaymentTypeParam as LoanBorrowingType];
        this.loanBorrowingForm.patchValue({ type: repaymentTypeParam });
      }
    } else if (id) {
      this.loadLoanBorrowing(id);
    }

    // If it's not a create operation, get the repaymentType from route data
    if (!this.isCreate) {
      this.route.data.subscribe(data => {
        if (data['repaymentType']) this.repaymentTypes = data['repaymentType'];
      });
    }
  }

  loadCounterPartyTypes() {
    this.loanBorrowingService.getTypesOfCounterparty().subscribe({
      next: (types) => this.counterPartyTypes = types,
      error: (err) => this.toastService.showToast('Récupération des types de contrepartie', err.error, 'error')
    });
  }

  updateLibelle() {
    const type = this.loanBorrowingForm.get('type')?.value;
    const counterparty = this.loanBorrowingForm.get('counterparty')?.value;
    const currency = this.loanBorrowingForm.get('currency')?.value;

    if (type && counterparty && currency) {
      const libelle = `${type} - ${counterparty} - ${currency}`;
      this.loanBorrowingForm.get('libelle')?.patchValue(libelle, { emitEvent: false });
    }
  }

  loadLoanBorrowing(id: string) {
    this.loanBorrowingService.getLoanBorrowingById(id).subscribe({
      next: (loanBorrowing) => {
        this.loanBorrowing = loanBorrowing;
        this.loanBorrowingForm.patchValue(loanBorrowing);
        this.loanBorrowingForm.patchValue({
          valueDate: new Date(loanBorrowing.valueDate),
          maturityDate: new Date(loanBorrowing.maturityDate),
          firstPaymentDate: new Date(loanBorrowing.firstPaymentDate),
          currency: loanBorrowing.currency.code,
          libelle: loanBorrowing.libelle
        });
        this.formEnabled = false;
        this.loanBorrowingForm.disable();
        this.loadRepaymentEntries(id);
      },
      error: (err) => this.toastService.showToast('Récupération du prêt/emprunt', err.error, 'error')
    });
  }

  loadRepaymentEntries(loanBorrowingId: string) {
    this.repaymentService.getRepaymentEntriesByLoanBorrowingId(loanBorrowingId).subscribe({
      next: (entries) => this.repaymentEntries = entries,
      error: (error) => this.toastService.showToast('Récupération des remboursements', error.error, 'error')
    });
  }

  loadCurrencies() {
    this.currencyService.getAllDevises(getUnpagedPageable()).subscribe({
      next: (page) => {
        this.currencies = page.content;
        this.loanBorrowingForm.patchValue({ currency: this.currencies.find(c => c.code === 'XOF')?.code });
      },
      error: () => {
        const summary = 'Récupération des devises';
        const detail = 'Une erreur est survenue lors de la récupération des devises. Veuillez réessayer plus tard.';
        this.toastService.showToast(summary, detail, 'error');
      }
    });
  }

  onSubmit() {
    if (this.loanBorrowingForm.valid) {
      // Get both enabled and disabled controls
      const formValue = {...this.loanBorrowingForm.value, ...this.loanBorrowingForm.getRawValue()};

      const loanBorrowing = formValue as ILoanBorrowing;
      const currency = this.loanBorrowingForm.get('currency')?.value;
      loanBorrowing.currency = this.currencies.find(c => c.code === currency)!;

      if (this.isCreate) this.createLoanBorrowing(loanBorrowing);
      else this.updateLoanBorrowing(loanBorrowing);
    }
  }

  createLoanBorrowing(formData: ILoanBorrowing) {
    this.loanBorrowingService.createLoanBorrowing(formData).subscribe({
      next: (createdLoanBorrowing) => {
        this.toastService.showToast('Création du prêt/emprunt', 'Le prêt/emprunt a été créé avec succès.');
        this.router.navigate(['/main/tresorerie/locale/loan/details', createdLoanBorrowing.id]).then();
      },
      error: (err) => this.toastService.showToast('Création du prêt/emprunt', err.error, 'error')
    });
  }

  updateLoanBorrowing(formData: ILoanBorrowing) {
    if (this.loanBorrowing?.id) {
      this.loanBorrowingService.updateLoanBorrowing(this.loanBorrowing.id, formData).subscribe({
        next: (updatedLoanBorrowing) => {
          this.toastService.showToast('Mise à jour du prêt/emprunt', 'Le prêt/emprunt a été mis à jour avec succès.');
          this.loanBorrowing = updatedLoanBorrowing;
          this.ngOnInit();
        },
        error: (error) => this.toastService.showToast('Mise à jour du prêt/emprunt', error.error, 'error')
      });
    }
  }

  simulateRepaymentEntries() {
    if (this.loanBorrowingForm.valid) {
      // Get both enabled and disabled controls
      const formValue = {...this.loanBorrowingForm.value, ...this.loanBorrowingForm.getRawValue()};

      const loanBorrowing = formValue as ILoanBorrowing;
      const currency = this.loanBorrowingForm.get('currency')?.value;
      loanBorrowing.currency = this.currencies.find(c => c.code === currency)!;

      this.repaymentService.simulateRepaymentEntries(loanBorrowing).subscribe({
        next: (simulatedEntries) => {
          this.loanBorrowing = loanBorrowing;
          this.repaymentEntries = simulatedEntries;
          this.dialogVisible = true;
        },
        error: (err) => {
          this.toastService.showToast('Simulation des remboursements', err.error, 'error');
        }
      });
    }
  }

  postponeRepaymentEntries(paymentNumber: number) {
    if (this.loanBorrowing?.id) {
      this.repaymentService.postponeRepaymentEntries(this.loanBorrowing.id, paymentNumber).subscribe({
        next: () => this.loadRepaymentEntries(this.loanBorrowing.id),
        error: (error) => this.toastService.showToast('Report des remboursements', error.error, 'error')
      });
    }
  }

  anticipateRepaymentEntries(paymentNumber: number) {
    if (this.loanBorrowing?.id) {
      this.repaymentService.anticipateRepaymentEntries(this.loanBorrowing.id, paymentNumber).subscribe({
        next: () => this.loadRepaymentEntries(this.loanBorrowing.id),
        error: (error) => this.toastService.showToast('Anticipation', error.error, 'error')
      });
    }
  }


  private loadRepaymentFrequencies() {
    this.repaymentService.getFrequencies().subscribe({
      next: (frequencies) => this.frequencies = frequencies,
      error: (err) => this.toastService.showToast('Récupération des fréquences de remboursement',err.error, 'error')
    })
  }

  enableForm() {
    this.loanBorrowingForm.enable();
    this.formEnabled = true;
  }
}
