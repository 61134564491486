import {Component, OnInit} from '@angular/core';
import {CustomDialogData} from "../../../../core/models/custom-dialog.model";
import {DynamicDialogConfig} from "primeng/dynamicdialog";
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {OperationLoadedBilanModel} from "../../../../core/models/risque/operation-loaded-bilan.model";
import {ChipsModule} from "primeng/chips";
import {NgForOf, SlicePipe} from "@angular/common";

@Component({
  selector: 'app-operation-loaded-bilan-details',
  standalone: true,
  templateUrl: './operation-loaded-bilan-details.component.html',
  imports: [
    ReactiveFormsModule,
    ChipsModule,
    SlicePipe,
    NgForOf
  ],
  styleUrls: ['./operation-loaded-bilan-details.component.scss']
})
export class OperationLoadedBilanDetailsComponent implements OnInit {

  staticFields = [
    { id: 'categoryCode', label: 'Catégorie' },
    { id: 'subCategoryCode', label: 'Sous-catégorie' },
    { id: 'operationDate', label: 'Date de l\'opération', type: 'date' },
    { id: 'maturityDate', label: 'Date de maturité', type: 'date' },
    { id: 'poste', label: 'Poste' },
    { id: 'outstanding', label: 'Encours', type: 'number' },
    { id: 'rate', label: 'Taux', type: 'number' },
  ];

  amortFields = [
    [
      { id: 'oneMonth', label: '1M' },
      { id: 'threeMonth', label: '3M' },
    ],
    [
      { id: 'sixMonth', label: '6M' },
      { id: 'nineMonth', label: '9M' },
    ],
    [
      { id: 'oneYear', label: '1Y' },
      { id: 'threeYear', label: '3Y' },
    ],
    [
      { id: 'fiveYear', label: '5Y' },
      { id: 'tenYear', label: '10Y' },
    ],
  ];

  data: CustomDialogData | undefined;
  formGroup: FormGroup = new FormGroup({});
  operation: OperationLoadedBilanModel | undefined;

  constructor(
    public config: DynamicDialogConfig,
    private fb: FormBuilder
  ) {
    this.data = config.data;
    this.operation = this.data?.data;
    this.initForm();
  }

  private initForm() {
    this.formGroup = this.fb.group({
      categoryCode: new FormControl('', [Validators.required]),
      subCategoryCode: new FormControl('', [Validators.required]),
      operationDate: new FormControl('', [Validators.required]),
      maturityDate: new FormControl('', [Validators.required]),
      poste: new FormControl('', [Validators.required]),
      outstanding: new FormControl('', [Validators.required]),
      rate: new FormControl('', [Validators.required]),
      oneMonth: new FormControl(''),
      threeMonth: new FormControl(''),
      sixMonth: new FormControl(''),
      nineMonth: new FormControl(''),
      oneYear: new FormControl(''),
      threeYear: new FormControl(''),
      fiveYear: new FormControl(''),
      tenYear: new FormControl(''),
    });
  }

  ngOnInit(): void {
    if (this.operation) {
      const formattedOperationDate = new Date(this.operation.operationDate).toISOString().split('T')[0];
      const formattedMaturityDate = new Date(this.operation.maturityDate).toISOString().split('T')[0];
      this.formGroup.patchValue({ operationDate: formattedOperationDate });
      this.formGroup.patchValue({ maturityDate: formattedMaturityDate });

      this.formGroup.patchValue({
        categoryCode: this.operation.categoryCode,
        subCategoryCode: this.operation.subCategoryCode,
        poste: this.operation.poste,
        outstanding: this.operation.outstanding,
        rate: this.operation.rate,
        oneMonth: this.operation.oneMonth,
        threeMonth: this.operation.threeMonth,
        sixMonth: this.operation.sixMonth,
        nineMonth: this.operation.nineMonth,
        oneYear: this.operation.oneYear,
        threeYear: this.operation.threeYear,
        fiveYear: this.operation.fiveYear,
        tenYear: this.operation.tenYear
      });
    }
  }


}
