import {Component} from '@angular/core';
import {Button} from "primeng/button";
import {FileSelectEvent, FileUploadModule} from "primeng/fileupload";
import {ToastService} from "../../../../../../core/services/toast.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {DialogService, DynamicDialogComponent, DynamicDialogRef} from "primeng/dynamicdialog";
import {BalanceSheetItemService} from "../../../../../../core/services/balance-sheet-item.service";
import {NgIf} from "@angular/common";
import {Ripple} from "primeng/ripple";
import {UploadTableComponent} from "../../../../../../shared/components/upload-table/upload-table.component";
import {CustomTableHeader} from "../../../../../../core/models/custom-table.model";
import {DEFAULT_PAGE_PARAMS, PageParams} from "../../../../../../core/models/page.model";
import {IUploadBalanceSheetItem} from "../../../../../../core/models/balance-sheet-item.model";

@Component({
  selector: 'app-upload-operation',
  standalone: true,
  imports: [
    Button,
    FileUploadModule,
    NgIf,
    Ripple,
    UploadTableComponent
  ],
  templateUrl: './upload-operation.component.html',
  styleUrl: './upload-operation.component.scss'
})
export class UploadOperationComponent {

  instance: DynamicDialogComponent | undefined;
  uploadedFile: File = {} as File;
  isLoading: boolean = false;
  operations: IUploadBalanceSheetItem[] = [];
  columns: CustomTableHeader[] = [
    { key: 'category.name', column: 'Catégorie', type: 'text' },
    { key: 'category.type', column: 'Type', type: 'text' },
    { key: 'valueDate', column: 'Date de valeur', type: 'date' },
    { key: 'maturityDate', column: 'Date de maturité', type: 'date' },
    { key: 'amount', column: 'Montant', type: 'currency', currencyKey: 'item.currency' },
    { key: 'item.rate', column: 'Taux', type: 'number' }
  ];
  pageable: PageParams = DEFAULT_PAGE_PARAMS;

  constructor(
    private readonly toastService: ToastService,
    private readonly http: HttpClient,
    private readonly ref: DynamicDialogRef,
    private readonly dialogService: DialogService,
    private readonly service : BalanceSheetItemService
  )
  {
    this.instance = this.dialogService.getInstance(this.ref);
  }


  downloadTemplate() {
    this.http.get('/assets/templates/risques/operation/ALIMA - Modèle Opérations.xlsx', {
      responseType: 'arraybuffer',
      observe: 'response',
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache'
      })
    }).subscribe({
      next: (response) => {
        if (!response.body) {
          this.toastService.showToast(
            'Téléchargement du modèle',
            'Une erreur est survenue lors du téléchargement du modèle',
            'error'
          );
          return;
        }

        const blob = new Blob([response.body], {
          type: 'application/octet-stream'
        });

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'ALIMA - Modèle Opérations.xlsx';

        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();

        // Cleanup after a short delay
        setTimeout(() => {
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        }, 100);
      },
      error: () => {
        this.toastService.showToast(
          'Téléchargement du modèle',
          'Une erreur est survenue lors du téléchargement du modèle',
          'error'
        );
      }
    });
  }

  onUpload(event: FileSelectEvent) {
    this.uploadedFile = event.files[event.currentFiles.length - 1];
  }

  importOperation() {
    if (this.uploadedFile) {
      this.service.parseExcelFile(this.uploadedFile).subscribe({
        next: (response) => {
          this.operations = response;
          this.toastService.showToast('Succès', `Le fichier a été importé avec succès.`)
        },
        error: (error) => {
          this.toastService.showToast('Chargement sans succès', error.error, 'error');
          this.ref.close();
        },
      });
    }
  }

  upload($event: IUploadBalanceSheetItem[]) {
    const operations = $event.filter(item => item.valid);
    if (operations.length === 0) {
      this.toastService.showToast('Données non conformes', 'Veuillez sélectionner au moins une opération.', 'error');
      return;
    }
    this.isLoading = true;
    this.service.uploadItems(operations).subscribe({
      next: () => this.toastService.showToast('Succès', 'Les opérations ont été importées avec succès.'),
      error: (error) => this.toastService.showToast('Import d\'opérations', error.error, 'error'),
      complete: () => {
        this.isLoading = false;
        this.ref.close();
      }
    });
  }
}
