import {Component, OnInit} from '@angular/core';
import {CustomDialogData} from "../../../../../core/models/custom-dialog.model";
import {DialogService, DynamicDialogComponent, DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from "@angular/forms";
import {ToastService} from "../../../../../core/services/toast.service";
import {BalanceSheetCategoryService} from "../../../../../core/services/balance-sheet-category.service";
import {IBalanceSheetCategory, ItemSource, ItemType} from "../../../../../core/models/balance-sheet.model";
import {Button} from "primeng/button";
import {InputNumberModule} from "primeng/inputnumber";
import {InputTextModule} from "primeng/inputtext";
import {InputTextareaModule} from "primeng/inputtextarea";
import {NgForOf, NgIf} from "@angular/common";
import {Ripple} from "primeng/ripple";
import {CheckboxChangeEvent, CheckboxModule} from "primeng/checkbox";
import {DropdownModule} from "primeng/dropdown";
import {getUnpagedPageable} from "../../../../../core/models/page.model";

@Component({
  selector: 'app-balance-sheet-category-details',
  standalone: true,
  imports: [
    // Modules
    FormsModule, InputNumberModule, InputTextModule, InputTextareaModule, ReactiveFormsModule,
    // Pipes & Directives
    Button, NgForOf, NgIf, Ripple, CheckboxModule, DropdownModule
  ],
  templateUrl: './balance-sheet-category-details.component.html',
  styleUrl: './balance-sheet-category-details.component.scss'
})
export class BalanceSheetCategoryDetailsComponent implements OnInit {

  data: CustomDialogData | undefined;
  instance: DynamicDialogComponent | undefined;
  formGroup: FormGroup = new FormGroup({});
  isLoading: boolean = false;
  category: IBalanceSheetCategory | undefined;
  types: string[] = [];
  rootCategories: IBalanceSheetCategory[]  = [];
  hasParent: boolean = false;
  itemTypes: ItemType[] = Object.values(ItemType);
  itemSources: ItemSource[] = Object.values(ItemSource);

  constructor(
    private categoryService: BalanceSheetCategoryService,
    private readonly ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private readonly dialogService: DialogService,
    private fb: FormBuilder,
    private toastService: ToastService
  ) {
    this.instance = this.dialogService.getInstance(this.ref);
    this.data = this.instance.data;
    this.category = this.data?.data;
    this.initForm();
  }

  private initForm() {
    this.formGroup = this.fb.group({
      name: new FormControl('', [Validators.required]),
      code: new FormControl('', [Validators.required]),
      type: new FormControl('', [Validators.required]),
      itemType: new FormControl('', [Validators.required]),
      parent: new FormControl(''),
      description: new FormControl(''),
      source: new FormControl('', [Validators.required]),
      hasParent: new FormGroup(false)
    });
  }

  ngOnInit() {
    this.loadCategories();
    this.loadTypes();
    if ((this.data?.isView || this.data?.isEdit) && this.category) {
      this.formGroup.patchValue(this.category);
      if (this.category.parent) {
        this.hasParent = true;
        this.formGroup.patchValue({ parent: this.category.parent.id, hasParen: true })
      }
      if (this.data?.isView) this.formGroup.disable();
    }
  }

  get flowRates() {
    return this.formGroup.get('flowRates') as FormArray;
  }

  getFormValue(): IBalanceSheetCategory {
    let parent: IBalanceSheetCategory | undefined = undefined
    if (this.hasParent) {
      parent = this.rootCategories.find(r => r.id == this.formGroup.value.parent);
      if (parent == undefined) {
        this.toastService.showToast('Formulaire invalide', 'La catégorie parente doit être renseignée.', 'error')
      }
    }
    return {
      parent,
      id: this.category ? this.category.id : '',
      institutionId: '',
      canUpdate: false,
      canDelete: false,
      name: this.formGroup.value.name,
      code: this.formGroup.value.code,
      description: this.formGroup.value.description,
      type: this.formGroup.value.type,
      itemType: this.formGroup.value.itemType,
      source: this.formGroup.value.source,
    } as IBalanceSheetCategory;
  }

  save() {
    const summary = 'Création catégorie de bilan';
    this.category = this.getFormValue();
    this.categoryService.createCategory(this.category).subscribe({
      next: () => this.toastService.showToast(summary, 'La catégorie de bilan a été créée avec succès'),
      error: (err) => this.toastService.showToast(summary, err.error, 'error'),
      complete: () => this.ref.close()
    });
  }

  update() {
    const summary = 'Modification catégorie de bilan';
    const id = this.category?.id;
    const category = this.getFormValue();
    category.id = this.category?.id!;
    this.categoryService.updateCategory(category).subscribe({
      next: () => this.toastService.showToast(summary, 'La catégorie de bilan a été modifiée avec succès'),
      error: (err) => this.toastService.showToast(summary, err.error, 'error'),
      complete: () => this.ref.close()
    });
  }


  private loadTypes() {
    this.categoryService.getBalanceSheetTypes().subscribe({
      next: value => this.types = value,
      error: err => this.toastService.showToast('Types de catégories', err.error, 'error')
    })
  }

  private loadCategories() {
    this.categoryService.getCurrentUserCategories(getUnpagedPageable()).subscribe({
      next: value => this.rootCategories = value.content,
      error: err => this.toastService.showToast('Catégories parentes', err.error, 'error')
    })
  }

  toggleHasParent = ($event: CheckboxChangeEvent) => this.hasParent = $event.checked as boolean;
}
