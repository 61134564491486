import {ApplicationConfig, importProvidersFrom, provideZoneChangeDetection} from '@angular/core';
import {provideRouter} from '@angular/router';
import {routes} from './app.routes';
import {provideHttpClient, withInterceptors} from "@angular/common/http";
import {DatePipe, registerLocaleData} from "@angular/common";
import localeFr from "@angular/common/locales/fr";
import {ConfirmationService, MessageService} from "primeng/api";
import {DialogService} from "primeng/dynamicdialog";
import {provideAnimations} from "@angular/platform-browser/animations";
import {JwtModule} from "@auth0/angular-jwt";
import {jwtInterceptor} from "./core/middlewares/jwt.interceptor";
import {FullCalendarComponent, FullCalendarModule} from '@fullcalendar/angular';
import {DragDropModule} from "primeng/dragdrop";
import {loaderInterceptor} from "./core/middlewares/loader.interceptor";
import {NgxSpinnerModule} from "ngx-spinner";

// Register French locale
registerLocaleData(localeFr);

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideHttpClient(withInterceptors([jwtInterceptor, loaderInterceptor])),
    provideAnimations(),
    importProvidersFrom([JwtModule.forRoot({})]),
    importProvidersFrom(NgxSpinnerModule.forRoot({ type: 'ball-fussion' })),
    { provide: 'LOCALE_ID', useValue: 'fr' },
    DialogService,
    MessageService,
    ConfirmationService,
    FullCalendarComponent,
    DragDropModule,
    FullCalendarModule,
    DatePipe
  ]
};
