import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NgZone } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private isLoading = new BehaviorSubject<boolean>(false);
  private requestCount = 0;

  constructor(private ngZone: NgZone) {}

  show() {
    this.ngZone.run(() => {
      this.requestCount++;
      if (this.requestCount === 1) this.isLoading.next(true);
    });
  }

  hide() {
    this.ngZone.run(() => {
      this.requestCount--;
      if (this.requestCount === 0) this.isLoading.next(false);
    });
  }

  get loading$() {
    return this.isLoading.asObservable();
  }
}
