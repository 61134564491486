import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {DropdownModule} from "primeng/dropdown";
import {DynamicTableComponent} from "../../../../shared/components/dynamic-table/dynamic-table.component";
import {ToolbarComponent} from "../../../../shared/components/toolbar/toolbar.component";
import {CommonModule, DatePipe, NgForOf} from "@angular/common";
import {CustomDialogData} from "../../../../core/models/custom-dialog.model";
import {PrimaireEmissionModel} from "../../../../core/models/primaire.emission.model";
import {CustomAction, CustomTableHeader} from "../../../../core/models/custom-table.model";
import {getDefaultPageable, Pageable, PageParams} from "../../../../core/models/page.model";
import {DialogService, DynamicDialogModule, DynamicDialogRef} from "primeng/dynamicdialog";
import {CalendarOptions} from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, {DateClickArg} from "@fullcalendar/interaction";
import frLocale from "@fullcalendar/core/locales/fr";
import {PaginatorState} from "primeng/paginator";
import {TabViewModule} from "primeng/tabview";
import {FileUpload, FileUploadModule} from "primeng/fileupload";
import {EmissionPrimaireService} from "../../../../core/services/emission.primaire.service";
import {FormBuilder, FormGroup} from "@angular/forms";
import {customDynamicDialog, DEFAULT_ROWS_PER_PAGE_OPTIONS} from "../../../../core/utils/utils";
import {ToastService} from "../../../../core/services/toast.service";
import {DialogModule} from "primeng/dialog";
import {FullCalendarComponent, FullCalendarModule} from '@fullcalendar/angular';
import {EmissionsDetailComponent} from "./emissions-detail/emissions-detail.component";
import {take} from "rxjs";
import {NewSoumissionComponent} from "../soumissions/new-soumission/new-soumission.component";
import {EmissionWithTitresDTO} from "../../../../core/models/emission.model";
import {TitresDTO} from "../../../../core/models/titres.model";
import {differenceInMonths} from 'date-fns';
import {AddTitreComponent} from "./add-titre/add-titre.component";
import {countriesUemoa} from "../../../../../assets/country-flags";

@Component({
  selector: 'app-emissions',
  standalone: true,
  imports: [
    // Modules
    CommonModule, FullCalendarModule, DropdownModule, TabViewModule, FileUploadModule, DynamicDialogModule, DialogModule,
    // Components
    ToolbarComponent, DynamicTableComponent,
    // Pipes & Directives
    NgForOf, DatePipe
  ],
  templateUrl: './emissions.component.html',
  styleUrl: './emissions.component.css',
})
export class EmissionsComponent implements OnInit, AfterViewInit {

  @ViewChild('calendar') calendarComponent!: FullCalendarComponent;
  @ViewChild('fileUpload') fileUpload!: FileUpload;

  form: FormGroup;
  countries = countriesUemoa;
  boundData?: CustomDialogData;
  primary: EmissionWithTitresDTO[] = [];
  titres: TitresDTO[] = [];
  filteredTitres: TitresDTO[] = [];
  pageable: PageParams = {page: 0, first: 0, rows: 10, totalRecords: 0, rowPerPageOptions: [10, 20, 50]};
  ref?: DynamicDialogRef;
  isLoading: boolean = false;
  actions: CustomAction[] = [];
  displayDialog: boolean = false;
  displayMoreDetailsDialog: boolean = false;
  selectedEvent: any;
  columns: CustomTableHeader[] = [
    {key: 'isin', column: 'ISIN', type: 'text'},
    {key: 'region', column: 'Emetteur', type: 'custom'},
    {key: 'typeInstrument', column: 'Instrument', type: 'text'},
    {key: 'operationDate', column: 'Date opération', type: 'text'},
    {key: 'valueDate', column: 'Date valeur', type: 'text'},
    {key: 'dueDate', column: 'Echéance', type: 'text'},
    {key: 'duration', column: 'Durée (en mois)', type: 'text'}
  ];
  calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin, interactionPlugin],
    initialView: 'dayGridMonth',
    dateClick: this.handleDateClick.bind(this),
    locale: frLocale,
    dayHeaderFormat: {
      weekday: 'long',
    },
    buttonText: {
      today: "Aujourd'hui",
      month: 'Mois',
      week: 'Semaine',
      day: 'Jour'
    },
    firstDay: 1,
    events: [],
    eventBackgroundColor: '#004F78',
    eventContent: (arg: any) => ({
      html: `<div style="white-space: normal; word-break: break-word; overflow-wrap: break-word; width: 100%;">
           ${arg.event.title}
         </div>`
    }),
    eventClick: this.handleEventClick.bind(this),
    editable: true,
    droppable: true,
    contentHeight: 'auto',
    height: 'auto',
  };

  constructor(private readonly emissionPrimaireService : EmissionPrimaireService,
              public readonly dialogService: DialogService,
              private readonly datePipe: DatePipe,
              private readonly fb: FormBuilder,
              private readonly toastService :ToastService) {

    this.form = this.fb.group({});
  }

  ngOnInit(): void {
    this.loadEmission();
    this.loadEventsForCalendar();
    this.form.get('region')?.valueChanges.subscribe(selectedCountry => {
      this.filter(selectedCountry);
    });
    this.actions = this.getActions();

  }
  handleDateClick(info: DateClickArg) {
    if (this.isDateEmpty(info.dateStr)) {
      this.openEmissionDialog(info.dateStr);
    }
  }
  openEmissionDialog(selectedDate: string) {
    const data = { isCreate: true, date: selectedDate } as CustomDialogData;
    const ref = this.dialogService.open(EmissionsDetailComponent, {
      header: 'Nouvelle émission',
      width: '50%',
      data: data
    });

    ref.onClose.pipe(take(1)).subscribe(() => {
      // Logique à exécuter après la fermeture de la boîte de dialogue
      this.loadEmission()
    });
  }

  isDateEmpty(dateStr: string): boolean {
    // Logique pour vérifier s'il y a un événement pour la date donnée
    // Retourne true si la date est vide (sans événements)
    return true; // Placeholder: à remplacer avec la vérification réelle
  }


  ngAfterViewInit(): void {
    if (this.calendarComponent) {
      this.calendarComponent.getApi().render();
    }
  }

  handleEventClick(info: any) {
    this.selectedEvent = {
      id: info.event.extendedProps.id,
      title: info.event.title,
      operationDate: info.event.extendedProps.operationDate,
      amount: info.event.extendedProps.amount,
      valueDate: info.event.extendedProps.valueDate
    };
    this.filteredTitres = this.titres.filter(detail => {
      return detail.emission.id === this.selectedEvent.id;
    });
    this.displayDialog = true;
  }

  closeDialog() {
    this.displayDialog = false;
  }

  closeMoreDetailsDialog() {
    this.displayMoreDetailsDialog = false;
  }
  onPageChange = (event: PaginatorState) => this.loadEmission(getDefaultPageable(event.page, event.rows));

  getActions(): CustomAction[] {
    return [
      {
        role: 'READ_EMISSION',
        icon: 'pi pi-eye',
        label: 'Consulter',
        severity: 'info',
        emit: (item) => this.viewEmission(item),
        visible: true
      },
      {
        role: 'UPDATE_EMISSION',
        icon: 'pi pi-plus-circle',
        label: 'Nouvelle Soumission',
        severity: 'primary',
        emit: (item) => this.createSubmission(item, true),
        visible: true
      },
      {
        role: 'DELETE_EMISSION',
        icon: 'pi pi-trash',
        label: 'Supprimer',
        severity: 'danger',
        emit: (item) => this.delete(item),
        visible: true
      },
    ];
  }

  onUpload(event: any) {
    const file = event.files[0];
    if (file) {
      this.emissionPrimaireService.importEmission(file).subscribe({
        next: (response) => {
          this.toastService.showToast('Succès', `Le fichier a été importé avec succès.`, 'success', response);
          this.loadEmission(getDefaultPageable(0, 10));
          this.resetFileUpload();
        },
        error: (error) => {

          this.toastService.showToast('Erreur', 'Echec du chargement des émissions', error);
          this.resetFileUpload();
        }
      });
    }
  }

  resetFileUpload() {
    if (this.fileUpload) {
      this.fileUpload.clear();
    }
  }

  onCalendarCountryChange(event: any): void {
    const selectedCountry = event.value;
    this.emissionPrimaireService.getAllEmissionsWithTitres(0, 20).subscribe({
      next: (page) => {
        let filteredEmissions = page.content;
        if (selectedCountry) {
          filteredEmissions = filteredEmissions.filter(emission =>
            emission.region === (typeof selectedCountry === 'string' ? selectedCountry : selectedCountry.code)
          );
        }
        const newEvents = filteredEmissions.map(emission => ({
          title: `Émission de Bons et Obligations Assimilables du Trésor du ${this.getCountryName(emission.region)} du ${this.formatDate(emission.operationDate)}`,
          date: this.formatDateToISO(emission.operationDate),
        }));
        if (this.calendarComponent) {
          const calendarApi = this.calendarComponent.getApi();
          calendarApi.removeAllEvents();
          calendarApi.addEventSource(newEvents);
        }
      },
      error: (error) => {
        this.toastService.showToast('Erreur', 'Échec du chargement des émissions pour le calendrier', error);
      }
    });
  }

  createSubmission(primary: TitresDTO, isEdit: boolean = false) {
    const data = {isView: !isEdit, isEdit, data: primary} as CustomDialogData;
    const countryData = this.countries.find((c: any) => c.code === primary.emission.region);

    if (countryData) {
      this.ref = this.dialogService.open(NewSoumissionComponent, {
        header: `Soumissions - Titre N°${primary.isin} - ${primary.typeInstrument}`,
        width: '75%',
        data: data
      });
    } else {
      this.ref = this.dialogService.open(NewSoumissionComponent, {
        header: `Soumissions - Titre N°${primary.isin} - Région inconnue`,
        width: '75%',
        data: data
      });
    }
    this.ref.onClose.pipe(take(1)).subscribe(() => {
      this.loadEmission();
    });
  }

  private filter(selectedCountry: string): void {
    if (selectedCountry) {
      this.titres = this.titres.filter(detail =>
        this.getCountryName(detail.emission.region) === selectedCountry
      );
    } else {
      this.loadEmission(getDefaultPageable(0, 10));
    }
  }
  private loadEmission(pageable: Pageable = getDefaultPageable(0, 10)): void {
    this.emissionPrimaireService.getAllTitres(pageable.page, pageable.limit, true)
      .subscribe({
        next: detailsPage => {
          this.titres = detailsPage.content.map(detail => {
            const duration = this.calculateDuration(detail.emission.operationDate, detail.dueDate);
            return {
              ...detail,
              operationDate: this.formatDate(detail.emission.operationDate),
              valueDate: this.formatDate(detail.emission.valueDate),
              dueDate: this.formatDate(detail.dueDate),
              amount: detail.emission.amount,
              duration: duration,
              region: this.getCountryName(detail.emission.region),
            };
          });
          this.pageable = {
            page: detailsPage.number,
            first: detailsPage.number * detailsPage.size,
            rows: detailsPage.size,
            totalRecords: detailsPage.totalElements,
            rowPerPageOptions: DEFAULT_ROWS_PER_PAGE_OPTIONS
          };
        },
        error: err => {
          this.toastService.showToast('Erreur lors du chargement des détails des émissions', err, 'error');
        }
      });
  }


  private loadEventsForCalendar(): void {
    this.emissionPrimaireService.getAllEmissionsWithTitres(0,10)
      .subscribe({
        next: emissionPage => {
          const emissions = emissionPage.content;
          const calendarEvents = emissions.map(emission => ({
            title: `Émission de Bons et Obligations Assimilables du Trésor du ${this.getCountryName(emission.region)} du ${this.formatDate(emission.operationDate)}`,
            date: this.formatDateToISO(emission.operationDate),
            extendedProps: {
              id: emission.id,
              amount: emission.amount,
              valueDate: this.formatDate(emission.valueDate),
              operationDate: this.formatDate(emission.operationDate)
            }
          }));

          if (this.calendarComponent) {
            const calendarApi = this.calendarComponent.getApi();
            calendarApi.removeAllEvents();
            calendarApi.addEventSource(calendarEvents);
          }
        },
        error: err => {
          this.toastService.showToast('Erreur lors du chargement des événements du calendrier', err, 'error');
        }
      });
  }


  private viewEmission(primaire: PrimaireEmissionModel, isEdit: boolean = false) {
    const data = {isView: !isEdit, isEdit, data: primaire} as CustomDialogData;
    this.ref = this.dialogService.open(EmissionsDetailComponent, customDynamicDialog('Détails d\'émission', data));
    this.ref.onClose.pipe(take(1)).subscribe(() => this.loadEmission());
  }

  private formatDateToISO(date: string | Date): string {
    const parsedDate = new Date(date);
    return parsedDate.toISOString().split('T')[0];
  }

  private delete(primary: TitresDTO) {
    this.emissionPrimaireService.delete(primary.id, true).subscribe({
      next: () => {
        this.toastService.showToast('Titre supprimée', `Le titre de l'émission ${primary.isin} a été supprimée avec succès.`);
        this.loadEmission();
      },
      error: (err) => {
        this.toastService.showToast('Erreur lors de la suppression du titre de l\'émission', err, 'error');
      }
    });
  }

  private getCountryName(region: string): string {
    const country = this.countries.find((c: any) => c.code === region);
    return country ? country.country : region;
  }

  private formatDate(date: Date | string): string {
    return this.datePipe.transform(date, 'dd/MM/yyyy') ?? '';
  }

  private calculateDuration(startDate: string | Date, endDate: string | Date): number {
    const start = new Date(startDate);
    const end = new Date(endDate);
    return differenceInMonths(end, start);
  }

  onMoreButtonClick() {
    this.displayDialog = false;
    this.ref = this.dialogService.open(AddTitreComponent, {
      header: 'Nouveau Titre',
      width: '50%',
      data: {
        emissionId: this.selectedEvent?.id
      }
    });
    this.ref.onClose.pipe(take(1)).subscribe(() => {
      this.loadEmission();
    });
  }

  downloadTemplate(): void {
    const link = document.createElement('a');
    link.href = 'assets/template/template-vente.xlsx';
    link.download = 'template-vente.xlsx';
    link.click();
  }
}

