<div class="flex flex-column h-full">
  <form [formGroup]="formGroup" class="flex flex-column gap-4 mt-4">
    <div *ngIf="data?.isCreate || data?.isEdit" class="flex align-items-center gap-4 w-full">
      <p-checkbox (onChange)="toggleHasParent($event)" [binary]="true" class="w-fit" id="hasParent" formControlName="hasParent" />
      <label for="hasParent">Est une sous-catégorie ?</label>
    </div>
    <div *ngIf="hasParent" class="flex flex-column gap-2">
      <label for="parent" class="font-medium">Catégorie</label>
      <p-dropdown id="parent" aria-describedby="parent-help" formControlName="parent" optionValue="id"
                  [options]="rootCategories" optionLabel="name" placeholder="Sélectionner la catégorie mère"></p-dropdown>
    </div>
    <div class="flex flex-column gap-2">
      <label for="type" class="font-medium">Section</label>
      <p-dropdown id="type" aria-describedby="parent-help" formControlName="type" [options]="types" placeholder="Sélectionner la section"></p-dropdown>
    </div>
    <div class="flex flex-column gap-2">
      <label for="itemType" class="font-medium">Type de poste</label>
      <p-dropdown id="itemType" aria-describedby="itemType-help" formControlName="itemType" [options]="itemTypes" placeholder="Sélectionner le type de poste"></p-dropdown>
    </div>
    <div class="flex flex-column gap-2">
      <label for="source" class="font-medium">Source des données</label>
      <p-dropdown id="source" aria-describedby="source-help" formControlName="source" [options]="itemSources" placeholder="Sélectionner la source des données de la catégorie"></p-dropdown>
    </div>
    <div class="flex flex-column gap-2">
      <label for="name" class="font-medium">Nom</label>
      <input pInputText id="name" aria-describedby="name-help" formControlName="name" />
    </div>
    <div class="flex flex-column gap-2">
      <label for="code" class="font-medium">Code</label>
      <input pInputText id="code" aria-describedby="code-help" formControlName="code" />
    </div>
    <div class="flex flex-column gap-2">
      <label for="desc" class="font-medium">Description</label>
      <textarea id="desc" rows="5" cols="30" pInputTextarea formControlName="description"></textarea>
    </div>
  </form>
  <div class="flex flex-grow-1 gap-2 align-items-end mt-4 justify-content-end">
    <p-button pRipple *ngIf="data?.isCreate" label="Enregistrer" [disabled]="!formGroup.valid" [loading]="isLoading" (onClick)="save()" />
    <p-button pRipple severity="secondary" *ngIf="data?.isEdit && category?.canUpdate" label="Modifier" [disabled]="!formGroup.valid" [loading]="isLoading" (onClick)="update()" />
  </div>
</div>
