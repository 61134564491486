<div class="flex flex-column gap-4 w-full h-full mt-2">
  <div class="flex justify-content-between w-full">
    <div class="flex gap-2">
      <div class="flex gap-2">
        <date-range [minDate]="minDate" (dateRangeSelected)="onDateRangeSelected($event)" (invalidRange)="onInvalidRange($event)" />
        <p-dropdown [options]="timeGroupings" [(ngModel)]="selectedTimeGrouping" />
        <p-dropdown *ngIf="!isLocal" id="currency" aria-describedby="currency-help" [(ngModel)]="currency"
                    [editable]="true" filter="true" filterBy="code,description" [options]="currencies"
                    optionLabel="description" optionValue="code">
          <ng-template let-currency pTemplate="item">
            <div class="flex align-items-center gap-2">
              <div>{{ currency.description }} - {{ currency.code }} - {{ currency.symbol }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
    </div>
    <div class="flex gap-2">
      <div class="card flex justify-content-center">
        <p-selectButton [multiple]="true" [options]="viewOptions" [(ngModel)]="activeViews" optionLabel="icon" optionValue="value">
          <ng-template let-item pTemplate>
            <div class="flex gap-2">
              <i [class]="item.icon"></i>
              <span>{{item.name}}</span>
            </div>
          </ng-template>
        </p-selectButton>
      </div>
      <export [withExtensions]="false" label="Récapitulatif du prévisionnel" [iconOnly]="true" (handler)="exportOverview($event)" />
    </div>
  </div>
  <div class="w-full h-fit pb-4 flex flex-column gap-4">
    <div *ngIf="activeViews.includes(2)" class="flex flex-column gap-4">
      <!--<span class="font-semibold text-2xl underline">Courbes récapitulatives</span>-->
      <div class="w-full flex justify-content-center">
        <div class="w-9 flex justify-content-center">
          <app-prev-overview-chart class="w-full" [reserves]="reserves" [currency]="currency" [currentBalance]="currentBalance" [data]="data" [dates]="rangeDates" [timeGrouping]="selectedTimeGrouping"/>
        </div>
      </div>
    </div>
    <div *ngIf="activeViews.includes(1)" class="flex flex-column gap-4">
      <span class="font-semibold text-2xl underline">Tableau récapitulatif</span>
      <app-prev-overview-table [currentBalance]="currentBalance" [data]="data" [timeGrouping]="selectedTimeGrouping" />
    </div>
  </div>
</div>
