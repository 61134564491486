import {HttpClient, HttpParams} from "@angular/common/http";
import {getPageableAsParams, Page, Pageable} from "../models/page.model";
import {SourceType} from "../models/classification-rule.model";
import {Observable} from "rxjs";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class ClassificationRuleService {
  private readonly apiUrl = 'risque/v1/classification-rules';

  constructor(private http: HttpClient) {}

  getRules(pageable: Pageable, sourceType?: SourceType | null): Observable<Page<any>> {
    let params = getPageableAsParams(pageable);
    if (sourceType) {
      params = params.append('sourceType', sourceType);
    }
    return this.http.get<Page<any>>(this.apiUrl, { params });
  }

  searchRules(query: string, sourceType?: SourceType | null): Observable<Page<any>> {
    let params = new HttpParams().append('query', query);
    if (sourceType) params = params.append('sourceType', sourceType);
    return this.http.get<Page<any>>(`${this.apiUrl}/search`, { params });
  }

  createRule(rule: any): Observable<any> {
    return this.http.post<any>(this.apiUrl, rule);
  }

  updateRule(rule: any): Observable<any> {
    return this.http.put<any>(`${this.apiUrl}/${rule.id}`, rule);
  }

  deleteRule(id: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${id}`);
  }
}
