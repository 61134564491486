import {Component, EventEmitter, Input, Output, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {ButtonModule} from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import {DropdownModule} from 'primeng/dropdown';
import {InputTextModule} from 'primeng/inputtext';
import {TableModule} from 'primeng/table';
import {DialogModule} from 'primeng/dialog';
import {DynamicDialogModule} from 'primeng/dynamicdialog';
import {InputNumberModule} from 'primeng/inputnumber';
import {CommonModule, UpperCasePipe} from '@angular/common';
import {IEmission, ITitre, ITitreWithEmission} from '../../../../../core/models/risque/simulation/risque-emission_model';
import {countriesUemoa} from '../../../../../../assets/country-flags';
import {ICountry} from '../../../../../core/models/country.model';
import {ToastService} from '../../../../../core/services/toast.service';
import {ISimulation} from '../../../../../core/models/simulation.model';
import {Periodicity} from '../../../../../core/enums/periodicity.enums';
import {AmortissementsMode} from '../../../../../core/enums/amortissements-mode.enums';
import {SimulationService} from '../../../../../core/services/simulation.service';
import {getDefaultPageable, Pageable, PageParams} from '../../../../../core/models/page.model';
import {EmissionPrimaireService} from '../../../../../core/services/emission.primaire.service';

@Component({
  selector: 'app-pricer-form',
  standalone: true,
  imports: [ReactiveFormsModule,
    DynamicDialogModule,
    ButtonModule,
    CalendarModule,
    DropdownModule,
    InputTextModule,
    TableModule, DialogModule, InputNumberModule, UpperCasePipe, CommonModule],
  templateUrl: './pricer-form.component.html',
  styleUrl: './pricer-form.component.scss'
})

export class PricerFormComponent {

  @Input() instrumentType: 'BAT' | 'OAT' = 'BAT';  // BAT by default
  @Input() resetForm = false;
  @Output() simulationData = new EventEmitter<ISimulation>();
  @Output() reload = new EventEmitter();

  pricerForm!: FormGroup;
  isinCodes: string[] = [];
  uemoaCountriesWithEmissions: ICountry[] = [];
  valeurNominaleUnitaire: number = 0;
  emissions: IEmission[] = [];
  titres: ITitre[] = [];
  allTitresWithEmissions: ITitreWithEmission[] = []
  globalEmissionMontant: number = 0;
  selectedCountry: ICountry | undefined;
  selectedIsinCode: string = '';
  currentlySelectedTitreWithEmission: ITitreWithEmission | undefined;
  currentDateValeur: Date | undefined;
  today: Date = new Date();
  selectedPeriodicity: string | undefined;
  selectedAmortissementMode: string | undefined;
  periodicities = [Periodicity.QUARTERLY, Periodicity.SEMI_ANNUAL, Periodicity.ANNUAL];
  modesAmortissement = [AmortissementsMode.IN_FINE, AmortissementsMode.AMORTISSABLE];
  isFormReady = false;
  pageable: PageParams = {page: 0, first: 0, rows: 0, totalRecords: 0, rowPerPageOptions: []};

  constructor(private toastService: ToastService, private simualtionService: SimulationService, private emissionPrimaireService: EmissionPrimaireService) {
  }

  get isBAT(): boolean {
    return this.instrumentType === 'BAT';
  }

  get isOAT(): boolean {
    return this.instrumentType === 'OAT';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['resetForm'] && changes['resetForm'].currentValue) {
      const reset = changes['resetForm'].currentValue;
      if (reset) {
        this.pricerForm.reset();
      }
    }
  }

  ngOnInit(): void {

    this.loadEmissions();
    this.initializeForm();

  }

  initializeForm(): void {
    this.pricerForm = new FormGroup({
      paysEmetteur: new FormControl('', Validators.required),
      codeISIN: new FormControl('', Validators.required),
      valeurNominaleUnitaire: new FormControl(
        this.valeurNominaleUnitaire,
        Validators.required
      ),
      montantAPlacer: new FormControl(null, [
        Validators.required,
      ]),
      montantGlobalEmission: new FormControl(this.globalEmissionMontant, [
        Validators.required,
      ]),
      dateValeur: new FormControl(
        {value: null, disabled: false},
        Validators.required
      ),
      dateEcheance: new FormControl(
        {value: null, disabled: true},
        Validators.required
      ),
      // Additional controls for OAT
      ...(this.isOAT && {
        maturite: new FormControl({value: null, disabled: true}, [
          Validators.required
        ]),
        differe: new FormControl({value: null, disabled: this.selectedAmortissementMode === 'IN FINE'}, Validators.required),
        periodicity: new FormControl(null, Validators.required),
        amortissement: new FormControl(null, Validators.required),
        tauxInteretCoupon: new FormControl(null, [
          Validators.required,
        ]),
        tauxRendement: new FormControl(null, [
          Validators.required,
        ])
      }),
      // Additional controls for BAT
      ...(this.isBAT && {
        tauxPreCompte: new FormControl(0, [
          Validators.required,
          Validators.min(0.1),
        ])
      })
    });
  }

  loadEmissions(pageable: Pageable = getDefaultPageable()): void {
    this.simualtionService.getEmissionsByInstrumentType(pageable, this.instrumentType).subscribe({
      next: (response => {
        this.allTitresWithEmissions = response.content;
        response.content.forEach(titreWithEmission => {
          const {emission, ...titre} = titreWithEmission;
          this.titres.push(titre);

          if (!this.emissions.some(em => em.id === emission.id)) {
            this.emissions.push(emission);
          }
        });

        // After all emissions are processed, load UEMOA countries
        this.loadUemoaCountriesWithEmissions();

        this.valeurNominaleUnitaire = this.titres
          .map(titre => titre.unitNominalValue)[0];

      })

    });
  }

  loadUemoaCountriesWithEmissions() {
    const countriesSet = this.emissions.reduce((set, emission) => {
      const country = countriesUemoa.find(countryUemoa => countryUemoa.code === emission.region);

      if (country) {
        set.add(country);  // Add country to Set if found
      }
      return set;
    }, new Set<ICountry>());

    // Convert the set to an array and store it
    this.uemoaCountriesWithEmissions = Array.from(countriesSet);

    console.log("Countries with emissions:", this.uemoaCountriesWithEmissions);
  }


  loadIsinCodesForCountry() {
    if (this.selectedCountry) {
      // Filter emissions by selected country
      const isins = this.allTitresWithEmissions
        .filter(titreWithEmission => titreWithEmission.emission.region === this.selectedCountry!.code) // Match region
        .map(titreWithEmission => titreWithEmission.isin); // Extract the ISIN from the titre
      return isins;
    }
    return [];

  }

  onCountryChange(event: ICountry) {
    this.selectedCountry = event;
    this.isinCodes = this.loadIsinCodesForCountry();
  }

  onIsinCodeChange(event: string) {

    this.selectedIsinCode = event;

     this.currentlySelectedTitreWithEmission = this.allTitresWithEmissions.find(titreWithEmission =>
      titreWithEmission.isin === this.selectedIsinCode
    );

    if (this.currentlySelectedTitreWithEmission) {
      // Get the corresponding emission and titre
      const currentlySelectedEmission = this.currentlySelectedTitreWithEmission?.emission;
      const currentlySelectedTitre = this.currentlySelectedTitreWithEmission;

      // Proceed to update form and variables
      if (currentlySelectedEmission && currentlySelectedTitre) {
        const dateValeur = new Date(currentlySelectedEmission.valueDate);
        const dateEcheance = new Date(currentlySelectedTitre.dueDate);
        this.globalEmissionMontant = currentlySelectedEmission.amount;
        console.log("global em", this.globalEmissionMontant)
        this.currentDateValeur = dateValeur;

        // Update the form values using the selected titre and emission details
        this.pricerForm.patchValue({
          'dateValeur': dateValeur,
          'dateEcheance': dateEcheance,
          'montantGlobalEmission': this.globalEmissionMontant,
          'valeurNominaleUnitaire': this.valeurNominaleUnitaire,
          ...(this.isOAT && {
            'maturite': this.getMaturity(dateEcheance, dateValeur),
            'tauxInteretCoupon': this.getCouponInterestRate(),
          }),
          ...(this.isBAT && {
            'tauxPreCompte': 0.1,
          })
        });

        this.isFormReady = true;
      }
    }

  }

  getMaturity(dateEcheance: Date, dateValeur: Date) {
    const diffInMilliseconds = dateEcheance.getTime() - dateValeur.getTime();
    const diffInDays = diffInMilliseconds / (1000 * 3600 * 24);
    const diffInYears = diffInDays / 365; // 365 because of the EXACT/EXACT ISMA convention
    const maturity = parseFloat(diffInYears.toFixed(2));

    return maturity

  }


  getCouponInterestRate() {
    return this.currentlySelectedTitreWithEmission!.rate

  }


  onAmortissementModeChange(event: string) {
    this.selectedAmortissementMode = event;
    const differeControl = this.pricerForm.get('differe');

    if (differeControl) {
      if (event === 'IN FINE') {
        differeControl.disable({emitEvent: false});
      } else {
        differeControl.enable({emitEvent: false});
      }
    }
  }

  onPeriodicityChange(event: string) {
    this.selectedPeriodicity = event;

  }

  onSubmit(): void {
    if (this.pricerForm.valid) {
      const formValue = this.pricerForm.getRawValue();
      const simulation: ISimulation = {
        paysEmetteurCode: formValue.paysEmetteur!.code,
        instrument: this.isBAT ? 'BAT' : 'OAT',
        titreId: this.currentlySelectedTitreWithEmission!.id!,
        codeISIN: formValue.codeISIN,
        valeurNominaleUnitaire: formValue.valeurNominaleUnitaire,
        montantAPlacer: formValue.montantAPlacer,
        montantGlobalEmission: formValue.montantGlobalEmission,
        dateValeur: formValue.dateValeur,
        dateEcheance: formValue.dateEcheance,

        // Conditionally add OAT-specific fields
        maturite: this.isOAT ? formValue.maturite : null,
        differe: this.isOAT ? formValue.differe : null,
        periodicity: this.isOAT ? formValue.periodicity : null,
        amortissement: this.isOAT ? formValue.amortissement : null,
        tauxInteretCoupon: this.isOAT ? formValue.tauxInteretCoupon : null,
        tauxRendement: this.isOAT ? formValue.tauxRendement : null,
        tauxPreCompte: this.isBAT ? formValue.tauxPreCompte : null

      }
      console.log('Form Submission value:', formValue);
      console.log('Form Submission simulation:', simulation);
      this.simulationData.emit(simulation); // Emit form value to parent

    }
  }


}
