import {Component, OnInit} from '@angular/core';
import {DEFAULT_PAGE_PARAMS, getDefaultPageable, PageParams} from "../../../../core/models/page.model";
import {FlowLawService} from "../../../../core/services/flow-law.service";
import {CustomAction, CustomTableHeader} from "../../../../core/models/custom-table.model";
import {IFlowLaw} from "../../../../core/models/flow-law.model";
import {ToolbarComponent} from "../../../../shared/components/toolbar/toolbar.component";
import {DialogService, DynamicDialogConfig} from "primeng/dynamicdialog";
import {FlowLawDetailsComponent} from "../dialogs/flow-law-details/flow-law-details.component";
import {customConfirmationDialog, customDynamicDialog} from "../../../../core/utils/utils";
import {ToastService} from "../../../../core/services/toast.service";
import {DynamicTableComponent} from "../../../../shared/components/dynamic-table/dynamic-table.component";
import {FlowLawCategoryDetailsComponent, FlowLawCategoryDialogData} from "../dialogs/flow-law-category-details/flow-law-category-details.component";
import {ConfirmationService} from "primeng/api";

@Component({
  selector: 'app-flow-law',
  standalone: true,
  imports: [
    ToolbarComponent,
    DynamicTableComponent
  ],
  templateUrl: './flow-law.component.html',
  styleUrl: './flow-law.component.scss'
})
export class FlowLawComponent implements OnInit {

  data: IFlowLaw[] = [];
  columns: CustomTableHeader[] = [
    {
      column: 'Nom',
      key: 'name',
      type: 'text',
      sortable: true,
      filterable: false
    },
    {
      column: 'Code',
      key: 'code',
      type: 'text',
      sortable: true,
      filterable: false
    },
    {
      column: 'Description',
      key: 'description',
      type: 'text',
      sortable: true,
      filterable: false
    }
  ];
  actions: CustomAction[] = [
    {
      role: 'READ_REGULATION_LAW',
      icon: 'pi pi-info-circle',
      label: 'Consulter',
      severity: 'info',
      emit: (item: IFlowLaw) => this.openLawDetail(item, true),
      visible: true
    },
    {
      role: 'UPDATE_REGULATION_LAW',
      icon: 'pi pi-pen-to-square',
      label: 'Modifier',
      severity: 'primary',
      emit: (item: IFlowLaw) => this.openLawDetail(item),
      visible: (item: IFlowLaw) => item.canUpdate
    },
    {
      role: 'UPDATE_REGULATION_LAW',
      icon: 'pi pi-link',
      label: 'Catégories',
      severity: 'primary',
      emit: (item: IFlowLaw) => this.openFlowLawCategory(item),
      visible: true
    },
    {
      role: 'DELETE_REGULATION_LAW',
      icon: 'pi pi-trash',
      label: 'Supprimer',
      severity: 'danger',
      emit: (item: IFlowLaw) => this.confirmDeletion(item),
      visible: (item: IFlowLaw) => item.canDelete
    }
  ];
  toolbarActions: CustomAction[] = [
    {
      role: 'CREATE_REGULATION_LAW',
      icon: 'pi pi-plus',
      label: 'Nouveau',
      severity: 'primary',
      emit: () => this.createFlowLaw(),
      visible: true
    }
  ];
  pageParams: PageParams = DEFAULT_PAGE_PARAMS;
  private query: string = '';

  constructor(
    private flowLawService: FlowLawService,
    private dialogService: DialogService,
    private toastService: ToastService,
    private readonly confirmationService: ConfirmationService
  ) {}

  ngOnInit() {
    this.loadFlowLaws();
  }

  loadFlowLaws() {
    this.flowLawService.searchFlowLaws(this.query, getDefaultPageable(this.pageParams.page, this.pageParams.rows)).subscribe({
      next: (page) => {
        this.data = page.content;
        this.pageParams = {
          ...this.pageParams,
          page: page.number,
          rows: page.size,
          first: page.number * page.size,
          totalRecords: page.totalElements,
        };
      },
      error: (err) => this.toastService.showToast('Chargement des lois', err.error, 'error')
    });
  }

  private openLawDetail(item: IFlowLaw, isView: boolean = false) {
    const config: DynamicDialogConfig = customDynamicDialog('Détails loi d\'écoulement', { isView, isEdit: !isView, data: item });
    const ref = this.dialogService.open(FlowLawDetailsComponent, config);
    ref.onClose.subscribe(() => this.loadFlowLaws());
  }

  search(query: string) {
    this.query = query;
    this.loadFlowLaws();
  }

  private createFlowLaw() {
    const config: DynamicDialogConfig = customDynamicDialog('Création loi d\'écoulement', { isCreate: true });
    const ref = this.dialogService.open(FlowLawDetailsComponent, config);
    ref.onClose.subscribe(() => this.loadFlowLaws());
  }

  confirmDeletion(item: IFlowLaw) {
    const message = `Êtes-vous sûr de vouloir supprimer la loi ${item.name} ?`;
    const header = 'Suppression de loi';
    const options = customConfirmationDialog(header, message, { accept: () => this.deleteLaw(item) });
    this.confirmationService.confirm(options);
  }

  deleteLaw(item: IFlowLaw) {
    this.flowLawService.deleteFlowLaw(item.id).subscribe({
      next: () => {
        this.toastService.showToast('Loi supprimée avec succès', 'La loi a été supprimée avec succès', 'success');
        this.loadFlowLaws();
      },
      error: (err) => {
        this.toastService.showToast('Erreur lors de la suppression de la loi', err.error, 'error')
      }
    });
  }

  private openFlowLawCategory(item: IFlowLaw) {
    const data = { isCreate: true, data: { flowLaw: item } } as FlowLawCategoryDialogData
    const config: DynamicDialogConfig = customDynamicDialog('Loi / Catégorie', data);
    const ref = this.dialogService.open(FlowLawCategoryDetailsComponent, config);
    ref.onClose.subscribe(() => this.loadFlowLaws());
  }
}
