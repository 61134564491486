import {Component, OnInit} from '@angular/core';
import {DialogService, DynamicDialogComponent, DynamicDialogRef} from "primeng/dynamicdialog";
import {CustomDialogData} from "../../../../core/models/custom-dialog.model";
import {ICategoryBalance, IDailyBalanceReport} from "../../../../core/models/daily-balance.model";
import {BalanceTreeTableComponent} from "../report-tree-table.component";
import {BalanceSheetType, IBalanceSheetCategory} from "../../../../core/models/balance-sheet.model";
import {CurrencyPipe, NgForOf} from "@angular/common";
import {ReportBalanceChartComponent} from "../report-chart.component";

@Component({
  selector: 'app-category-overview',
  standalone: true,
  imports: [
    BalanceTreeTableComponent,
    CurrencyPipe,
    NgForOf,
    ReportBalanceChartComponent
  ],
  templateUrl: './category-overview.component.html',
  styleUrl: './category-overview.component.scss'
})
export class CategoryOverviewComponent implements OnInit {

  data: CustomDialogData | undefined;
  instance: DynamicDialogComponent | undefined;
  reports: IDailyBalanceReport[] = [];
  assets: ICategoryBalance[] = [];
  liabilities: ICategoryBalance[] = [];
  category : IBalanceSheetCategory = {} as IBalanceSheetCategory;

  constructor(
    private dialogService: DialogService,
    private ref: DynamicDialogRef,
  )
  {
    this.instance = this.dialogService.getInstance(this.ref);
  }

  ngOnInit(): void {
    this.data = this.instance?.data;
    this.reports = this.data?.data || [];
    if (this.reports.length > 0) {
      // Get all unique categories from all reports
      const allCategories = this.reports.reduce((acc, report) => {
        report.rootCategoryBalances.forEach(category => {
          const existingCategory = acc.find(c => c.category.id === category.category.id);
          if (!existingCategory) acc.push(category);
        });
        return acc;
      }, [] as ICategoryBalance[]);
      this.category = allCategories[0].category;

      // Separate into assets and liabilities
      this.assets = allCategories.filter(cat => cat.category.type === BalanceSheetType.ASSET);
      this.liabilities = allCategories.filter(cat => cat.category.type === BalanceSheetType.LIABILITY);
    }
  }


  getCurrentCategoryBalance() {
    return this.reports[this.reports.length -1].rootCategoryBalances.filter(c => c.category.id === this.category.id)?.[0].balance;
  }
}
