<div class="flex flex-column h-full">
  <form [formGroup]="formGroup" class="flex flex-column gap-4 mt-4">

    <ng-container *ngFor="let field of staticFields">
      <div class="flex flex-column gap-2">
        <label class="font-medium" [for]="field.id">{{ field.label }}</label>
        <input [formControlName]="field.id" [id]="field.id" pInputText [type]="field.type || 'text'" [readonly]="true"/>
      </div>
    </ng-container>

    <div class="flex flex-column gap-2">
      <h3 class="font-medium mt-4">Amortissement</h3>
    </div>

    <div *ngFor="let row of amortFields | slice:0:amortFields.length; let i = index" class="flex gap-1 justify-content-between">
      <div *ngFor="let field of row" class="flex flex-column" style="width: 49%;">
        <label class="font-medium" [for]="field.id">{{ field.label }}</label>
        <input [formControlName]="field.id" [id]="field.id" pInputText type="number" [readonly]="true"/>
      </div>
    </div>
  </form>
</div>
