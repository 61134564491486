<div class="w-full flex flex-column gap-4 overflow-y-scroll">
  <div class="flex w-full gap-4">
    <div class="w-6 border-1 border-round border-black-alpha-10 p-4">
      <div class="flex justify-content-between align-items-center mb-3">
        <div class="flex gap-2 align-items-center">
          <span class="text-xl font-bold text-black-alpha-70">{{ category.name }}</span>
        </div>
        <span class="text-black-alpha-70 font-bold">
          {{ getCurrentCategoryBalance() | currency : 'XOF' : 'symbol' : '1.0-0':'fr' }}
      </span>
      </div>
      <!-- Assets Categories Table -->
      <div class="">
        <div class="flex flex-column">
          <ng-container *ngFor="let categoryBalance of reports[(reports.length -2)].rootCategoryBalances[0].childrenBalances">
            <div class="flex justify-content-between align-items-center px-2 py-1 border-round-lg">
              <div class="flex flex-column align-items-start gap-1">
                <!-- <i class="pi pi-chart-line text-green-600"></i> -->
                <span class="font-semibold text-black-alpha-70">{{ categoryBalance.category.name }}</span>
              </div>
              <div class="flex flex-column align-items-end gap-1">
                <span class="text-sm font-semibold">
                  {{ categoryBalance.balance | currency : 'XOF' : 'symbol' : '1.0-0':'fr' }}
                </span>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="w-6">
      <div class="w-full flex">
        <report-balance-chart class="w-full" [showGap]="false" [legendPos]="'top'" [reports]="reports" [assets]="assets" [liabilities]="liabilities"/>
      </div>
    </div>
  </div>
  <div class="w-full">
    <balance-tree-table [reports]="reports"></balance-tree-table>
  </div>
</div>
