import {IBalanceSheetCategory} from "./balance-sheet.model";

export interface ICategoryBalance {
  id: string;
  category: IBalanceSheetCategory;
  balance: number;
  depth: number;
  percentageInParent: number;
  childrenBalances: ICategoryBalance[];
}

export interface IDailyBalanceReport {
  id: string;
  reportDate: Date;
  institutionId: string;
  assetBalance: number;
  liabilityBalance: number;
  rootCategoryBalances: ICategoryBalance[];
}


// frequency.model.ts
export enum Frequency {
  DAILY = 'Quotidien',
  WEEKLY = 'Hebdomadaire',
  SEMI_MONTHLY = 'Bimensuel',
  MONTHLY = 'Mensuel',
  QUARTERLY = 'Trimestriel',
  SEMI_ANNUALLY = 'Semestriel',
  ANNUALLY = 'Annuel',
}

export type FrequencyOption = { label: string, value: Frequency };

export enum ReportType {
  GLOBAL = 'GLOBAL',
  CURRENCY = 'CURRENCY'
}
