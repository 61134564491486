import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import { getDefaultPageable, getPageableAsParams, Page, Pageable } from '../models/page.model';
import {Frequency, ICategoryBalance, IDailyBalanceReport, ReportType} from "../models/daily-balance.model";
import {BalanceSheetType} from "../models/balance-sheet.model";


@Injectable({
  providedIn: 'root'
})
export class DailyBalanceReportService {
  private readonly apiUrl = 'risque/v1/daily-balance';

  constructor(private readonly http: HttpClient) {}

  getDailyBalance(date: Date, currency: string = 'XOF', type: ReportType = ReportType.CURRENCY): Observable<IDailyBalanceReport> {
    const formattedDate = this.formatDate(date);
    const params = new HttpParams()
      .append('currency', currency)
      .append('type', type);
    return this.http.get<IDailyBalanceReport>(`${this.apiUrl}/${formattedDate}`, { params });
  }

  getBalancesBetween(
    startDate: Date,
    endDate: Date,
    currency: string = 'XOF',
    type: ReportType = ReportType.CURRENCY,
    pageable: Pageable = getDefaultPageable()
  ): Observable<Page<IDailyBalanceReport>> {
    const params = getPageableAsParams(pageable)
      .append('startDate', this.formatDate(startDate))
      .append('endDate', this.formatDate(endDate))
      .append('currency', currency)
      .append('type', type);

    return this.http.get<Page<IDailyBalanceReport>>(`${this.apiUrl}/between`, { params });
  }

  getBalancesByFrequency(
    frequency: Frequency,
    startDate: Date,
    endDate: Date,
    currency: string = 'XOF',
    type: ReportType = ReportType.CURRENCY
  ): Observable<IDailyBalanceReport[]> {
    const params = new HttpParams()
      .append('startDate', this.formatDate(startDate))
      .append('endDate', this.formatDate(endDate))
      .append('frequency', frequency)
      .append('currency', currency)
      .append('type', type);
    return this.http.get<IDailyBalanceReport[]>(`${this.apiUrl}/frequency`, { params });
  }

  getLatestReport(currency: string = 'XOF', type: ReportType = ReportType.CURRENCY): Observable<IDailyBalanceReport> {
    const params = new HttpParams()
      .append('currency', currency)
      .append('type', type);
    return this.http.get<IDailyBalanceReport>(`${this.apiUrl}/latest`, { params });
  }

  // Helper methods for traversing and analyzing the data
  findCategoryBalance(report: IDailyBalanceReport, categoryId: string): ICategoryBalance | null {
    return this.findCategoryBalanceRecursive(report.rootCategoryBalances, categoryId);
  }

  private findCategoryBalanceRecursive(balances: ICategoryBalance[], categoryId: string): ICategoryBalance | null {
    for (const balance of balances) {
      if (balance.category.id === categoryId) {
        return balance;
      }
      const found = this.findCategoryBalanceRecursive(balance.childrenBalances, categoryId);
      if (found) {
        return found;
      }
    }
    return null;
  }

  getBalancesByDepth(report: IDailyBalanceReport, depth: number): ICategoryBalance[] {
    return this.getBalancesByDepthRecursive(report.rootCategoryBalances, depth);
  }

  private getBalancesByDepthRecursive(balances: ICategoryBalance[], targetDepth: number): ICategoryBalance[] {
    const result: ICategoryBalance[] = [];
    for (const balance of balances) {
      if (balance.depth === targetDepth) result.push(balance);
      result.push(...this.getBalancesByDepthRecursive(balance.childrenBalances, targetDepth));
    }
    return result;
  }

  // Financial calculations
  calculateNetPosition(report: IDailyBalanceReport): number {
    if (report) return report.assetBalance - report.liabilityBalance;
    return 0;
  }

  calculateTotalByType(report: IDailyBalanceReport, type: BalanceSheetType): number {
    if (report) return type === BalanceSheetType.ASSET ? report.assetBalance : report.liabilityBalance;
    else return 0;
  }

  calculatePercentageOfTotal(balance: ICategoryBalance, report: IDailyBalanceReport): number {
    const totalByType = this.calculateTotalByType(
      report,
      BalanceSheetType[balance.category.type as keyof typeof BalanceSheetType]
    );
    return totalByType === 0 ? 0 : (balance.balance / totalByType) * 100;
  }

  private formatDate(date: Date): string {
    return date.toISOString().split('T')[0];
  }

}
